import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Leaf, QrCode, Zap, Award, Trees, Monitor, ArrowRight, 
  CalendarCheck, PieChart, Users, ShoppingBag, CreditCard, Smartphone
} from 'lucide-react';
import { 
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle 
} from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Container, Section } from "./ui/layout";
import Partners from './Partners';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const EcoDining = () => {
  const features = [
    { icon: <QrCode className="h-10 w-10 text-orange-500" />, title: "Business Impact Shop", description: "Empower customers to offset their carbon footprint instantly with our unique QR code solution." },
    { icon: <CreditCard className="h-10 w-10 text-green-500" />, title: "Cost-Free Solution", description: "Implement sustainability initiatives without any upfront costs or fees." },
    { icon: <Smartphone className="h-10 w-10 text-blue-500" />, title: "Easy Customer Access", description: "Allow customers to purchase carbon credits on-the-go with a simple scan." },
    { icon: <Leaf className="h-10 w-10 text-green-500" />, title: "Customizable Impact", description: "Let customers choose their impact—bottle removal, tree planting, or supporting humanitarian projects." },
    { icon: <Zap className="h-10 w-10 text-red-500" />, title: "Real-Time Impact Tracking", description: "Monitor and share your sustainability achievements in real-time." },
    { icon: <ShoppingBag className="h-10 w-10 text-purple-500" />, title: "Enhance Customer Experience", description: "Offer a unique, eco-friendly option that sets your business apart." }
  ];

  const howItWorks = [
    { step: 1, title: "Generate Your QR Code", description: "Create a unique QR code for your business through our simple dashboard." },
    { step: 2, title: "Display the QR Code", description: "Place the QR code in visible locations such as menus, receipts, or in-store displays." },
    { step: 3, title: "Customer Scans", description: "Customers use their smartphones to scan the QR code, accessing your Business Impact Shop." },
    { step: 4, title: "Choose and Purchase", description: "Customers select their desired impact and complete the carbon credit purchase." },
    { step: 5, title: "Track Your Impact", description: "Monitor your business's growing positive impact through our real-time dashboard." }
  ];

  const benefits = [
    { icon: <Users className="h-6 w-6 text-blue-500" />, text: "Increase customer engagement and loyalty through meaningful sustainability initiatives." },
    { icon: <Award className="h-6 w-6 text-yellow-500" />, text: "Enhance your brand's reputation as an environmentally responsible business." },
    { icon: <PieChart className="h-6 w-6 text-green-500" />, text: "Gain valuable insights into your customers' eco-conscious behaviors and preferences." },
    { icon: <Zap className="h-6 w-6 text-red-500" />, text: "Differentiate your business in a competitive market with an innovative sustainability approach." },
    { icon: <Trees className="h-6 w-6 text-green-500" />, text: "Contribute to global reforestation efforts and support important humanitarian projects." },
    { icon: <Monitor className="h-6 w-6 text-purple-500" />, text: "Showcase your impact with customizable displays and certificates for your business." }
  ];

  return (
    <Container className="bg-gray-50">
      <Section className="py-20">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <motion.div 
            className="lg:w-1/2 mb-10 lg:mb-0"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-blue-900">Business Impact Shop: 
              Cost-Free Solution</h1>
            <p className="text-xl text-gray-700 mb-8">
              Our Business Impact Shop and QR code solution allows your customers to effortlessly offset their carbon footprint with every visit.
            </p>
            <Button asChild size="lg" className="bg-blue-600 hover:bg-blue-700">
              <a href="https://tidycal.com/bakri/bayti-eco-intro-call" target="_blank" rel="noopener noreferrer">
                Schedule a Demo
              </a>
            </Button>
          </motion.div>
          <motion.div 
            className="lg:w-1/2"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <img src="/images/offsetpackage.png" alt="Business Impact Shop" className="rounded-lg shadow-xl" />
          </motion.div>
        </div>
      </Section>

      <Section className="py-20 bg-white">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {howItWorks.map((step, index) => (
            <motion.div key={index} variants={fadeInUp}>
              <Card className="h-full hover:shadow-lg transition-shadow duration-300">
                <CardHeader>
                  <Badge className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white text-lg font-bold mb-4">
                    {step.step}
                  </Badge>
                  <CardTitle>{step.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600">{step.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </Section>

      <Section className="py-20">
        <h2 className="text-3xl font-bold text-center mb-12">Features of Business Impact Shop</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div key={index} variants={fadeInUp}>
              <Card className="h-full hover:shadow-lg transition-shadow duration-300">
                <CardHeader>
                  <div className="mb-4">{feature.icon}</div>
                  <CardTitle>{feature.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600">{feature.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </Section>

      <Section className="py-20 bg-white">
        <h2 className="text-3xl font-bold text-center mb-12">Benefits for Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div key={index} variants={fadeInUp}>
              <Card className="h-full hover:shadow-lg transition-shadow duration-300">
                <CardContent className="flex items-start pt-6">
                  <div className="mr-4 mt-1">{benefit.icon}</div>
                  <p className="text-gray-600">{benefit.text}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </Section>

      <Partners />

      <Section className="py-20">
        <Card className="bg-blue-600 text-white">
          <CardHeader>
            <CardTitle className="text-3xl font-bold">Ready to make a difference?</CardTitle>
            <CardDescription className="text-blue-100">
              Start empowering your customers to reduce their carbon footprint today.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="flex flex-col items-center">
                <QrCode className="h-12 w-12 mb-4" />
                <p className="text-center">Get your unique QR code</p>
              </div>
              <div className="flex flex-col items-center">
                <ShoppingBag className="h-12 w-12 mb-4" />
                <p className="text-center">Set up your Business Impact Shop</p>
              </div>
              <div className="flex flex-col items-center">
                <Users className="h-12 w-12 mb-4" />
                <p className="text-center">Engage customers in sustainability</p>
              </div>
            </div>
            <div className="text-center">
              <Button asChild size="lg" variant="secondary">
                <a href="https://tidycal.com/bakri/bayti-eco-intro-call" target="_blank" rel="noopener noreferrer">
                  Get Started
                </a>
              </Button>
            </div>
          </CardContent>
        </Card>
      </Section>
    </Container>
  );
};

export default EcoDining;