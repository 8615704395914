import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Users, Leaf, BarChart, Settings, CheckCircle, 
  TreeDeciduous, Waves, Globe, ArrowRight, Zap
} from 'lucide-react';
import { 
  Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle 
} from "./ui/card";
import { Button } from "./ui/button";
import { Container, Section } from "./ui/layout";
import { Badge } from "./ui/badge";
import GetStarted from './GetStarted';
import Partners from './Partners';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const BaytiTeams = () => {
  const features = [
    { icon: <CheckCircle className="h-10 w-10 text-green-500" />, title: "Meet your Goals", description: "Reach your carbon reduction goals by leveraging our tools and resources." },
    { icon: <BarChart className="h-10 w-10 text-blue-500" />, title: "Track & Customize", description: "Monitor your progress and tailor your sustainability efforts." },
    { icon: <Leaf className="h-10 w-10 text-green-500" />, title: "Immediate & Regular", description: "Start making an impact right away with ongoing initiatives." },
    { icon: <Users className="h-10 w-10 text-purple-500" />, title: "Employee Perks", description: "Offer Bayti as an employee perk. Assign each team member their own Impact Profile." },
    { icon: <Settings className="h-10 w-10 text-gray-500" />, title: "Manage your Actions", description: "Use your Bayti dashboard to manage your plan and configure your members." },
    { icon: <Globe className="h-10 w-10 text-blue-500" />, title: "Showcase your Impact", description: "Download certificates and share your Impact Profile page." }
  ];

  const steps = [
    { icon: <Users className="h-12 w-12 text-blue-500" />, title: "Choose your plan", description: "Select the plan that works best for your business, no matter the size." },
    { icon: <Settings className="h-12 w-12 text-green-500" />, title: "Setup your profile", description: "Customize your public Impact Profile with images and logos." },
    { icon: <Users className="h-12 w-12 text-purple-500" />, title: "Assign your members", description: "Invite members manually or upload a CSV file in your dashboard." },
    { icon: <BarChart className="h-12 w-12 text-blue-500" />, title: "Track your impact", description: "Monitor your impact and receive monthly summaries of your team's actions." }
  ];

  return (
    <Container className="font-sans text-gray-900">
      <Section className="py-20 md:py-32">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <motion.div 
            className="lg:w-1/2 mb-12 lg:mb-0"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <Badge className="mb-4 px-3 py-1 text-sm font-semibold bg-blue-100 text-blue-800">For Businesses</Badge>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-blue-900 leading-tight">
              Climate Conscious Workplace
            </h1>
            <p className="text-xl md:text-2xl text-gray-700 mb-8 leading-relaxed">
              Empower your team to reduce their carbon footprint through tree planting, plastic removal, and global humanitarian projects.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button asChild size="lg" className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-4 px-8 transition-all duration-300 transform hover:scale-105">
                <Link to="/explore-dashboard">
                  Dashboard
                </Link>
              </Button>
              <Button asChild variant="outline" size="lg" className="border-blue-600 text-blue-600 hover:bg-blue-50 font-semibold py-4 px-8 transition-all duration-300">
                <Link to="/see-plans">Explore Plans</Link>
              </Button>
            </div>
          </motion.div>
          <motion.div 
            className="lg:w-1/2 relative"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <img src="/images/Carboncall.png" alt="Business person" className="rounded-lg shadow-2xl" />
            <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-full shadow-lg">
              <Zap className="h-12 w-12 text-yellow-500" />
            </div>
          </motion.div>
        </div>
      </Section>

      <Partners />

      <Section className="py-20 bg-gradient-to-br from-gray-50 to-white">
        <h2 className="text-4xl font-bold text-center mb-6 text-blue-900">How it Works</h2>
        <p className="text-xl text-center text-gray-700 mb-12 max-w-3xl mx-auto">
          Get your business up and running with BaytiTeams in just a few simple steps. It&apos;s quick, easy, and impactful.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <Card key={index} className="flex flex-col items-center text-center hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2">
              <CardHeader>
                <div className="w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                  {step.icon}
                </div>
                <CardTitle className="text-2xl font-bold mb-2">{step.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 text-lg">{step.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </Section>

      <Section className="py-20">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-blue-900">Visualize Your Team&apos;s Impact</h2>
          <p className="text-xl text-gray-700 max-w-3xl mx-auto">
            Our intuitive dashboard allows you to set up and manage your plan, assign profiles to team members, and track your collective impact in real-time.
          </p>
        </div>
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Card className="overflow-hidden shadow-2xl rounded-lg">
            <img src="/images/dashboardimage.jpg" alt="Bayti Dashboard" className="w-full h-auto" />
          </Card>
        </motion.div>
      </Section>

      <Section className="py-20 bg-gradient-to-br from-blue-50 to-indigo-50">
        <h2 className="text-4xl font-bold text-center mb-12 text-blue-900">Empower Your Company & Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ delay: index * 0.1 }}
            >
              <Card className="h-full hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2">
                <CardHeader>
                  <div className="mb-4 bg-blue-100 p-4 rounded-full inline-block">{feature.icon}</div>
                  <CardTitle className="text-2xl font-bold text-blue-900">{feature.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600 text-lg">{feature.description}</p>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </Section>

      <GetStarted />
    </Container>
  );
};

export default BaytiTeams;