import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Copy, Trees } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";

const TreeCounterWidget = () => {
  const [widgetType, setWidgetType] = useState('floating');
  const [position, setPosition] = useState('right');
  const [treeCount, setTreeCount] = useState(0);

  useEffect(() => {
    // Simulating API call to get tree count
    const fetchTreeCount = async () => {
      // Replace this with actual API call
      const response = await new Promise((resolve) =>
        setTimeout(() => resolve({ count: 42 }), 1000)
      );
      setTreeCount(response.count);
    };
    fetchTreeCount();
  }, []);

  const scriptCode = `<script src="https://plugin.bayti.eco/tree-count/scripts/injector.js"></script>`;
  const widgetCode = `<bayti:treecount handle="myprofileXXX" position="${position}"></bayti:treecount>`;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You might want to add a notification here to inform the user that the text has been copied
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <h1 className="text-3xl font-bold mb-2">Tree counter</h1>
      <p className="text-gray-600 mb-8">
        Show how many trees you have planted so far on any webpage.
      </p>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Choose your counter type</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue={widgetType} onValueChange={setWidgetType}>
            <TabsList className="grid w-full grid-cols-3 mb-4">
              <TabsTrigger value="floating">Floating</TabsTrigger>
              <TabsTrigger value="static">Static</TabsTrigger>
              <TabsTrigger value="inline">Inline</TabsTrigger>
            </TabsList>
            {['floating', 'static', 'inline'].map((type) => (
              <TabsContent key={type} value={type} className="mt-4">
                <div className="bg-white p-4 rounded-md border">
                  <p className="text-center">{type.charAt(0).toUpperCase() + type.slice(1)} Widget</p>
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </CardContent>
      </Card>

      {widgetType === 'floating' && (
        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Floating counter location</CardTitle>
          </CardHeader>
          <CardContent>
            <RadioGroup defaultValue={position} onValueChange={setPosition}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="left" id="left" />
                <Label htmlFor="left">Bottom left</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="right" id="right" />
                <Label htmlFor="right">Bottom right</Label>
              </div>
            </RadioGroup>
          </CardContent>
        </Card>
      )}

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Preview</CardTitle>
        </CardHeader>
        <CardContent className="h-48 bg-gray-200 relative">
          <div className={`absolute ${position === 'left' ? 'left-4' : 'right-4'} bottom-4 bg-white p-2 rounded-md shadow flex items-center space-x-2`}>
            <Trees className="text-green-500" />
            <span>{treeCount} trees</span>
            <span className="text-xs text-gray-500">Planted with Bayti Eco</span>
          </div>
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Script code</CardTitle>
          <CardDescription>Place this code before the end of the body tag</CardDescription>
        </CardHeader>
        <CardContent>
          <pre className="bg-gray-800 text-gray-200 p-4 rounded-md overflow-x-auto">
            {scriptCode}
          </pre>
          <Button 
            onClick={() => copyToClipboard(scriptCode)}
            className="mt-4"
            variant="outline"
          >
            <Copy className="mr-2 h-4 w-4" /> Copy code
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Widget code</CardTitle>
          <CardDescription>Place this code where you want your counter to show</CardDescription>
        </CardHeader>
        <CardContent>
          <pre className="bg-gray-800 text-gray-200 p-4 rounded-md overflow-x-auto">
            {widgetCode}
          </pre>
          <Button 
            onClick={() => copyToClipboard(widgetCode)}
            className="mt-4"
            variant="outline"
          >
            <Copy className="mr-2 h-4 w-4" /> Copy code
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default TreeCounterWidget;