import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAllBlogsQuery } from '../app/features/blog/blogApi';
import { Eye, Edit, Trash } from 'lucide-react';

import { setAllBlogs } from '../app/features/blog/blogSlice';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { motion } from 'framer-motion';
import HeaderDash from '../components/HeaderDash';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminBlogList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: blogsData, error: getAllError, isLoading: isFetching } = useGetAllBlogsQuery();
  const blogs = useSelector((state) => state.blog.allBlogs || []);

  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (blogsData) {
      dispatch(setAllBlogs(blogsData));
      setIsLoading(false);
    } else if (getAllError) {
      setIsLoading(false);
      toast.error('Failed to fetch blogs. Please try again.');
    }
  }, [blogsData, getAllError, dispatch]);

  const columns = useMemo(() => [
    {
      accessorKey: "serialNumber",
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "title",
      header: "Title",
      cell: info => info.getValue() || 'N/A',
      // Add fixed width for Title
      size: 100, // Set the desired width in pixels
    },
    {
      accessorKey: "excerpt",
      header: "Excerpt",
      cell: info => info.getValue() || 'N/A',
      // Add fixed width for Excerpt
      size: 200, // Set the desired width in pixels
    },
    {
      accessorKey: "author_name", // Changed to author_name
      header: "Author",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "category_name", // Changed to category_name
      header: "Category",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "tags", // New column for tags
      header: "Tags",
      cell: info => info.getValue()?.join(', ') || 'N/A', // Display tags as a comma-separated string
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => (
        <Badge variant={row.getValue("status") === 'Published' ? 'default' : 'destructive'}>
          {row.getValue("status")}
        </Badge>
      ),
    },
    {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <div className="flex space-x-2">
            <Button 
  onClick={() => navigate(`/blog/${row.original.slug}`)} 
  variant="outline" 
  size="sm" 
  className="text-blue-500 border-blue-500"
>
  <Eye className="w-4 h-4" />
</Button>
<Button 
  variant="outline" 
  size="sm" 
  className="text-yellow-500 border-yellow-500"
>
  <Edit className="w-4 h-4" />
</Button>
<Button 
  variant="outline" 
  size="sm" 
  className="text-red-500 border-red-500"
>
  <Trash className="w-4 h-4" />
</Button>
          </div>
        ),
      },
  ], [navigate]); // Ensure navigate is included in dependencies

  const table = useReactTable({
    data: blogs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllError) {
    return <div>Error: {getAllError.message}</div>;
  }

  return (
    <motion.div
      className="min-h-screen bg-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeaderDash />
      <main className="container mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>Blog List</CardTitle>
            <div className="flex items-center justify-between">
              <Input
                placeholder="Search all columns..."
                value={globalFilter ?? ''}
                onChange={e => setGlobalFilter(String(e.target.value))}
                className="max-w-sm"
              />
              <Select
                value={table.getState().pagination.pageSize}
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select rows per page" />
                </SelectTrigger>
                <SelectContent>
                  {[5, 10, 20, 30, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            {Array.isArray(blogs) && blogs.length > 0 ? (
              <>
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map(headerGroup => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <TableHead key={header.id}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableRow>
                          {row.getVisibleCells().map(cell => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
                <div className="flex items-center justify-between space-x-2 py-4">
                  <div>
                    Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, table.getFilteredRowModel().rows.length)} of {table.getFilteredRowModel().rows.length} results
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div>No blogs found.</div>
            )}
          </CardContent>
        </Card>
      </main>
    </motion.div>
  );
};

export default AdminBlogList;