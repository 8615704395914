import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    allplans:[],
    allPlanOrders: [],
    subscribedplans:{},
 };

const planSlice = createSlice({
    name: "plan",
    initialState,
    reducers: {
        setSubscribedPlan: (state, action) => {
            state.subscribedplans = action.payload;
          },
        getAllPlans:(state,action)=>{
            state.allplans = action.payload
        },
        getSubscribedPlans:(state,action)=>{
            state.subscribedplans = action.payload
        },
        getAllPlanOrders: (state, action) => {
            state.allPlanOrders = action.payload;
        },
    },
});

export const {getAllPlans,getSubscribedPlans,setSubscribedPlan,getAllPlanOrders} = planSlice.actions;
export default planSlice.reducer;