import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useCreateMemberMutation, useGetBusinessMembersQuery, useUpdateMemberPermissionsMutation, useDeleteMemberMutation } from '../app/features/profile/profileApi';
import { useUpdatePlansMutation, useUpdateAutoRenewalMutation, useUpdateSubscriptionPlanOrderMutation, useGetPlansByProfileQuery, useGetSubscriptionHistoryQuery, useGetAllPlansQuery } from '../app/features/plan/planApi';
import { Trees, Droplet, Globe, CloudSun } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import { toast } from "./ui/use-toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "./ui/dialog";
import { Checkbox } from "./ui/checkbox";
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { useFetchDefaultPaymentMethodQuery } from '../app/features/payment/paymentApi';
import { setDefaultMethod } from '../app/features/payment/paymentSlice';
import { setSubscribedPlan } from '../app/features/plan/planSlice';
import { updateProfile } from '../app/features/profile/profileSlice';
import TheBayti from './TheBayti';

const PlanItem = ({ icon: Icon, value, label }) => (
  <div className="bg-gray-100 rounded-lg p-4 text-center flex flex-col items-center">
    <Icon className="text-blue-600 w-8 h-8 mb-2" />
    <p className="text-2xl font-bold text-gray-800 mb-1">{value}</p>
    <p className="text-sm text-gray-600">{label}</p>
  </div>
);

const AddMemberDialog = ({ isOpen, onClose, onAddMember }) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('member');
  const [permissions, setPermissions] = useState({
    updateProfile: false,
    generateCertificate: false,
    purchasePackage: false,
    viewOrders: true,
    manageOrders: false,
  });

  const handlePermissionChange = (permission) => {
    setPermissions(prev => ({ ...prev, [permission]: !prev[permission] }));
  };

  const handleSubmit = () => {
    onAddMember(email, role, permissions);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Member</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Input 
            type="email" 
            placeholder="Member email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Select value={role} onValueChange={setRole}>
            <SelectTrigger>
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="member">Member</SelectItem>
              <SelectItem value="admin">Admin</SelectItem>
            </SelectContent>
          </Select>
          <div className="space-y-2">
            <Label>Permissions</Label>
            {Object.entries(permissions).map(([key, value]) => (
              <div key={key} className="flex items-center space-x-2">
                <Switch 
                  id={key} 
                  checked={value}
                  onCheckedChange={() => handlePermissionChange(key)}
                />
                <Label htmlFor={key}>{key}</Label>
              </div>
            ))}
          </div>
          <Button onClick={handleSubmit}>Add Member</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const EditPermissionsDialog = ({ isOpen, onClose, member, onUpdatePermissions }) => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (member) {
      setPermissions(member.permissions || {});
    }
  }, [member]);

  const handlePermissionChange = (permission) => {
    setPermissions(prev => ({ ...prev, [permission]: !prev[permission] }));
  };

  const handleSubmit = () => {
    onUpdatePermissions(member.id, permissions);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Permissions for {member?.email}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {Object.entries(permissions).map(([key, value]) => (
            <div key={key} className="flex items-center space-x-2">
              <Checkbox 
                id={key} 
                checked={value}
                onCheckedChange={() => handlePermissionChange(key)}
              />
              <Label htmlFor={key}>{key}</Label>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Update Permissions</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const RemoveMemberDialog = ({ isOpen, onClose, member, onRemoveMember }) => {
  const handleRemove = () => {
    onRemoveMember(member.id);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove Member</DialogTitle>
        </DialogHeader>
        <p>Are you sure you want to remove {member?.email} from the team?</p>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button variant="destructive" onClick={handleRemove}>Remove</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const plans = [
  { id: 1, title: 'Guardian', price: 25, trees: 2, bottles: 20, co2: 100 },
  { id: 2, title: 'Protector', price: 49, trees: 4, bottles: 50, co2: 200 },
  { id: 3, title: 'Warrior', price: 95, trees: 6, bottles: 30, co2: 750 },
  { id: 4, title: 'Hero', price: 160, trees: 12, bottles: 40, co2: 1515 },
  { id: 5, title: 'Legend', price: 449, trees: 100, bottles: 1000, co2: 2000 },
];

const BaytiPlan = ({ userHasPlan, planDetails: initialPlanDetails }) => {
  const [planDetails, setPlanDetails] = useState(initialPlanDetails);
  const [isChangePlanDialogOpen, setIsChangePlanDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(initialPlanDetails?.autoRenew || false);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);
  const [isEditPermissionsDialogOpen, setIsEditPermissionsDialogOpen] = useState(false);
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const [updatePlan] = useUpdatePlansMutation();
  const [updateAutoRenewal] = useUpdateAutoRenewalMutation();
  const [updateSubscriptionPlanOrder] = useUpdateSubscriptionPlanOrderMutation();
  const [createMember] = useCreateMemberMutation();
  const [updateMemberPermissions] = useUpdateMemberPermissionsMutation();
  const [deleteMember] = useDeleteMemberMutation();

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const defaultPaymentMethod = useSelector((state) => state.payment.defaultMethod);

  const { data: fetchedDefaultMethod, isLoading: isLoadingDefaultMethod } = useFetchDefaultPaymentMethodQuery(profile?.id, {
    skip: !profile?.id,
  });

 
  
  useEffect(() => {
    if (fetchedDefaultMethod) {
      dispatch(setDefaultMethod(fetchedDefaultMethod));
    }
  }, [fetchedDefaultMethod, dispatch]);

  const { data: userPlans, refetch } = useGetPlansByProfileQuery({ profile_id: profile?.id }, {
    skip: !profile?.id,
    refetchOnMountOrArgChange: true
  });
  const { data: subscriptionHistory } = useGetSubscriptionHistoryQuery(profile?.id, { skip: !profile?.id });
  const { data: businessMembers, refetch: refetchMembers } = useGetBusinessMembersQuery(profile?.id, { skip: !profile?.id });

  useEffect(() => {
    if (initialPlanDetails) {
      setPlanDetails(initialPlanDetails);
      setIsLoading(false);
    } else if (userPlans && userPlans.length > 0) {
      setPlanDetails(userPlans[0]);
      setIsLoading(false);
    }
  }, [initialPlanDetails, userPlans]);

  useEffect(() => {
    if (profile?.id) {
      refetch();
    }
  }, [profile?.id, refetch]);

  const handleAddMember = async (email, role, permissions) => {
    try {
      await createMember({ profileId: profile.id, email, role, permissions });
      refetchMembers();
      toast({
        title: "Member Added",
        description: `${email} has been added to your team.`,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to add member.",
        variant: "destructive",
      });
    }
  };

  const handleUpdateMemberPermissions = async (memberId, permissions) => {
    try {
      await updateMemberPermissions({ memberId, permissions });
      refetchMembers();
      toast({
        title: "Permissions Updated",
        description: "Member permissions have been updated.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update permissions.",
        variant: "destructive",
      });
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      await deleteMember(memberId);
      refetchMembers();
      toast({
        title: "Member Removed",
        description: "The member has been removed from your team.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to remove member.",
        variant: "destructive",
      });
    }
  };

  const handlePlanChange = (planTitle) => {
    const newPlan = plans.find(plan => plan.title === planTitle);
    setSelectedPlan(newPlan);
    console.log('New selected plan:', newPlan);
    setIsChangePlanDialogOpen(true);
  };

  const handleConfirmPlanChange = async () => {
    if (!selectedPlan || !selectedPlan.id) {
      toast({
        title: "Error",
        description: "Please select a valid plan before confirming.",
        variant: "destructive",
      });
      return;
    }

    if (!profile.stripe_payment_method_id) {
      console.error('No default payment method ID found');
      toast({
        title: "Error",
        description: "No default payment method found. Please add a payment method.",
        variant: "destructive",
      });
      return;
    }

    setIsProcessing(true);
    try {


      const result = await updateSubscriptionPlanOrder({
        profile_id: profile.id,
        plan_id: selectedPlan.id,
        plan_name: selectedPlan.title,
        price: selectedPlan.price,
        full_name: profile.full_name || "",
        tree: selectedPlan.trees,
        bottles: selectedPlan.bottles,
        co2: selectedPlan.co2,
        auto_renew: true,
        payment_method_id: profile.stripe_payment_method_id,
      }).unwrap();

      console.log('updateSubscriptionPlanOrder result:', result);

      if (result.paymentIntentData.requiresAction) {
        const { error } = await stripe.handleCardAction(result.paymentIntentData.clientSecret);
        if (error) {
          throw new Error('Payment authentication failed: ' + error.message);
        }
        // If authentication succeeded, rerun the mutation to complete the subscription update
        await updateSubscriptionPlanOrder({
          profile_id: profile.id,
          plan_id: selectedPlan.id,
          plan_name: selectedPlan.title,
          price: selectedPlan.price,
          full_name: profile.full_name,
          tree: selectedPlan.trees,
          bottles: selectedPlan.bottles,
          co2: selectedPlan.co2,
          auto_renew: true,
          payment_method_id: profile.stripe_payment_method_id,
        }).unwrap();
      }

      // Handle successful update
      setPlanDetails(selectedPlan);
      dispatch(setSubscribedPlan(selectedPlan));
      dispatch(updateProfile({ hasplan: true }));

      toast({
        title: "Plan Updated Successfully",
        description: `Your plan has been changed to ${selectedPlan.title}.`,
        variant: "success",
      });
    } catch (error) {
      console.error('Error updating plan:', error);
      let errorMessage = "There was a problem updating your plan. Please try again.";
      if (error.data && error.data.error) {
        errorMessage = error.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      console.log('Detailed error:', JSON.stringify(error, null, 2));
      toast({
        title: "Error Updating Plan",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
      setIsChangePlanDialogOpen(false);
    }
  };

  const handleAutoRenewToggle = async (autoRenew) => {
    try {
      await updateAutoRenewal({ profileId: profile.id, planId: planDetails.id, autoRenew });
      setIsAutoRenewEnabled(autoRenew);
      setPlanDetails(prev => ({ ...prev, autoRenew }));
      toast({
        title: "Auto-renew Updated",
        description: `Auto-renew has been ${autoRenew ? 'enabled' : 'disabled'}.`,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update auto-renew setting.",
        variant: "destructive",
      });
    }
  };

  if (!userHasPlan) {
    return <TheBayti />;
  }
 

  if (isLoading || isLoadingDefaultMethod) {
    return <div>Loading plan details...</div>;
  }

  if (!planDetails) {
    return <div>No plan details available.</div>;
  }

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <Tabs defaultValue="current">
        <TabsList>
          <TabsTrigger value="current">Current Plan</TabsTrigger>
          <TabsTrigger value="members">Team Members</TabsTrigger>
          <TabsTrigger value="settings">Settings</TabsTrigger>
        </TabsList>

        <TabsContent value="current">
          <Card>
            <CardHeader>
              <CardTitle>Plan with ID: {planDetails.plan_id}</CardTitle>
              <CardDescription>
                You are on the £{planDetails.price} /{planDetails.duration_type} {planDetails.name} plan
                <br />
                Countering {planDetails.members} of {planDetails.totalMembers} team members
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <PlanItem 
                  icon={Trees} 
                  value={planDetails.tree || 0} 
                  label="Planted certified trees" 
                />
                <PlanItem 
                  icon={Droplet} 
                  value={`${planDetails.bottles || 0} bottles`} 
                  label="Stopped ocean plastic" 
                />
                <PlanItem 
                  icon={Globe} 
                  value={`${planDetails.co2 || 0} kg`} 
                  label="Funded climate projects" 
                />
                <PlanItem 
                  icon={CloudSun} 
                  value={`${planDetails.carbon || 0} kg`} 
                  label="Countered carbon emissions" 
                />
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="members">
          <Card>
            <CardHeader>
              <CardTitle>Team Members</CardTitle>
              <CardDescription>Manage your team members here.</CardDescription>
            </CardHeader>
            <CardContent>
              {businessMembers && businessMembers.length > 0 ? (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Email</TableHead>
                      <TableHead>Permissions</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {businessMembers.map((member) => (
                      <TableRow key={member.id}>
                        <TableCell>{member.email}</TableCell>
                        <TableCell>
                          {Object.entries(member.permissions || {})
                            .filter(([, value]) => value)
                            .map(([key]) => key)
                            .join(', ')}
                        </TableCell>
                        <TableCell>
                          <Button 
                            variant="outline" 
                            size="sm" 
                            onClick={() => {
                              setSelectedMember(member);
                              setIsEditPermissionsDialogOpen(true);
                            }}
                          >
                            Edit
                          </Button>
                          <Button 
                            variant="outline" 
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              setSelectedMember(member);
                              setIsRemoveMemberDialogOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <p>No team members added yet.</p>
              )}
              <Button onClick={() => setIsAddMemberDialogOpen(true)} className="mt-4">Add Member</Button>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="settings">
          <Card>
            <CardHeader>
              <CardTitle>Plan Settings</CardTitle>
              <CardDescription>View your current plan and explore other options.</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">Current Plan: {planDetails.title}</h3>
                <p>Price: {planDetails.price}Dhs / month</p>
                <p>Trees: {planDetails.tree}</p>
                <p>Bottles: {planDetails.bottles}</p>
                <p>Project: {planDetails.co2} kg</p>
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="plan-select">Change Plan</Label>
                <Select onValueChange={handlePlanChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a plan" />
                  </SelectTrigger>
                  <SelectContent>
                    {plans.map(plan => (
                      <SelectItem key={plan.id} value={plan.title}>{plan.title}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
 

              {/* Subscription History */}
              <div>
                <h3 className="text-lg font-semibold mb-2">Subscription History</h3>
                {subscriptionHistory && subscriptionHistory.length > 0 ? (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Change Type</TableHead>
                        <TableHead>New Status</TableHead>
                        <TableHead>Auto-renew</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {subscriptionHistory.map((entry, index) => (
                        <TableRow key={index}>
                          <TableCell>{new Date(entry.created_at).toLocaleString()}</TableCell>
                          <TableCell>{entry.change_type}</TableCell>
                          <TableCell>{entry.new_status || 'N/A'}</TableCell>
                          <TableCell>{entry.new_auto_renew !== undefined ? (entry.new_auto_renew ? 'Enabled' : 'Disabled') : 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <p>No subscription history available.</p>
                )}
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <AddMemberDialog 
        isOpen={isAddMemberDialogOpen}
        onClose={() => setIsAddMemberDialogOpen(false)}
        onAddMember={handleAddMember}
      />

      <EditPermissionsDialog 
        isOpen={isEditPermissionsDialogOpen}
        onClose={() => setIsEditPermissionsDialogOpen(false)}
        member={selectedMember}
        onUpdatePermissions={handleUpdateMemberPermissions}
      />

      <RemoveMemberDialog 
        isOpen={isRemoveMemberDialogOpen}
        onClose={() => setIsRemoveMemberDialogOpen(false)}
        member={selectedMember}
        onRemoveMember={handleRemoveMember}
      />

      <Dialog open={isChangePlanDialogOpen} onOpenChange={setIsChangePlanDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Plan Change</DialogTitle>
          </DialogHeader>
          {selectedPlan && (
            <>
              <p>Are you sure you want to change your plan to {selectedPlan.title}?</p>
              <p>Price: {selectedPlan.price}Dhs / month</p>
              <p>Trees: {selectedPlan.trees}</p>
              <p>Bottles: {selectedPlan.bottles}</p>
              <p>Project: {selectedPlan.co2} kg</p>
            </>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsChangePlanDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmPlanChange} disabled={isProcessing}>
              {isProcessing ? "Processing..." : "Confirm"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BaytiPlan;
