import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Separator } from "./ui/separator";
import { Container } from "./ui/layout";
import { Leaf, Zap, Globe, ArrowRight, Trophy } from 'lucide-react';
import HeaderDash from './HeaderDash';
import ImpactSummary from './ImpactSummary';
import { ActionCards } from './ActionCards';
import FeatureHighlights from './FeatureHighlights';
import { SuggestedReading } from './SuggestedReading';
import GamificationSection from './GamificationSection';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const Dashboard = () => {
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedDashboard');
    if (!hasVisitedBefore) {
      const timer = setTimeout(() => {
        localStorage.setItem('hasVisitedDashboard', 'true');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100"
      initial="hidden"
      animate="visible"
      variants={fadeInUp}
    >
      <HeaderDash />
      <Container className="py-8">
        <main className="space-y-8">
          <Section title="Your Impact Summary" icon={<Globe className="w-6 h-6 text-blue-500 mr-2" />}>
            <ImpactSummary />
          </Section>
          <Section title="Your Badges" icon={<Trophy className="w-6 h-6 text-yellow-500 mr-2" />}>
            <GamificationSection />
          </Section>
          <Section title="Take Action" icon={<Zap className="w-6 h-6 text-blue-500 mr-2" />}>
            <ActionCards />
          </Section>
          <Section title="Feature Highlights" icon={<Leaf className="w-6 h-6 text-blue-500 mr-2" />}>
            <FeatureHighlights />
          </Section>
          <Section title="Suggested Reading">
            <SuggestedReading />
          </Section>
          <RecommendationsSection />
        </main>
      </Container>
    </motion.div>
  );
};

function Section({ title, icon, children }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle className="text-2xl flex items-center">
          {icon}
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

function RecommendationsSection() {
  const recommendations = [
    { text: "Plant a tree today!", link: "/impact-shop" },
    { text: "Drive electric, save the planet!" },
    { text: "Spread the word on social media to inspire others." }
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">Recommendations</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {recommendations.map((rec, index) => (
          <Recommendation key={index} link={rec.link}>
            {rec.text}
          </Recommendation>
        ))}
      </CardContent>
    </Card>
  );
}

function Recommendation({ children, link }) {
  return (
    <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg">
      <p className="flex items-center">
        <Leaf className="w-5 h-5 text-green-500 mr-2" />
        {children}
      </p>
      {link && (
        <Button asChild variant="link">
          <Link to={link} className="flex items-center">
            Go to Impact Shop
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </Button>
      )}
    </div>
  );
}

export default Dashboard;