import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Trash2, UserPlus, CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { toast } from "./ui/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { Badge } from "./ui/badge";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

// Simulate initial data
const initialCollaborators = [
  { id: 1, name: 'John Doe', email: 'john@example.com', profileImage: '', role: 'admin', status: 'active' },
  { id: 2, name: 'Jane Smith', email: 'jane@example.com', profileImage: '', role: 'member', status: 'pending' },
];

const Collaborators = () => {
  const [collaborators, setCollaborators] = useState(initialCollaborators);
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState('');
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('member');
  const user = { name: "Your Name", email: "you@example.com", profileImage: "" };

  // Simulate fetchCollaborators
  useEffect(() => {
    // Normally this would be an API call, but we're using static data
    setCollaborators(initialCollaborators);
  }, []);

  const handleInvite = (e) => {
    e.preventDefault();
    if (newCollaboratorEmail) {
      const newCollaborator = {
        id: collaborators.length + 1,
        name: newCollaboratorEmail.split('@')[0],
        email: newCollaboratorEmail,
        profileImage: '',
        role: selectedRole,
        status: 'pending',
      };
      setCollaborators([...collaborators, newCollaborator]);
      toast({
        title: "Invitation Sent",
        description: `An invitation has been sent to ${newCollaboratorEmail}`,
      });
      setNewCollaboratorEmail('');
      setIsInviteDialogOpen(false);
    }
  };

  const handleDelete = (collaboratorId) => {
    setCollaborators(collaborators.filter(collaborator => collaborator.id !== collaboratorId));
    toast({
      title: "Collaborator Removed",
      description: "The collaborator has been removed from your team.",
    });
  };

  const handleRoleChange = (collaboratorId, newRole) => {
    setCollaborators(collaborators.map(collaborator =>
      collaborator.id === collaboratorId ? { ...collaborator, role: newRole } : collaborator
    ));
    toast({
      title: "Role Updated",
      description: "The collaborator's role has been updated.",
    });
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'active':
        return <CheckCircle className="text-green-500" />;
      case 'pending':
        return <AlertCircle className="text-yellow-500" />;
      case 'inactive':
        return <XCircle className="text-red-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto py-8">
      <Card>
        <CardHeader>
          <CardTitle>Team Collaborators</CardTitle>
          <CardDescription>Manage your team members and their roles</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Role</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">
                  <div className="flex items-center space-x-2">
                    <Avatar>
                      <AvatarImage src={user.profileImage} />
                      <AvatarFallback>{user.name[0]}</AvatarFallback>
                    </Avatar>
                    <div>
                      <p>{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <Badge>Owner</Badge>
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    <CheckCircle className="text-green-500 mr-2" />
                    Active
                  </div>
                </TableCell>
                <TableCell>-</TableCell>
              </TableRow>
              <AnimatePresence>
                {collaborators.map((collaborator) => (
                  <motion.tr
                    key={collaborator.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <TableCell className="font-medium">
                      <div className="flex items-center space-x-2">
                        <Avatar>
                          <AvatarImage src={collaborator.profileImage} />
                          <AvatarFallback>{collaborator.name[0]}</AvatarFallback>
                        </Avatar>
                        <div>
                          <p>{collaborator.name}</p>
                          <p className="text-sm text-gray-500">{collaborator.email}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Select
                        value={collaborator.role}
                        onValueChange={(value) => handleRoleChange(collaborator.id, value)}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select a role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="admin">Admin</SelectItem>
                          <SelectItem value="member">Member</SelectItem>
                          <SelectItem value="viewer">Viewer</SelectItem>
                        </SelectContent>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        {getStatusIcon(collaborator.status)}
                        <span className="ml-2 capitalize">{collaborator.status}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => handleDelete(collaborator.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </TableBody>
          </Table>
        </CardContent>
        <CardFooter>
          <Dialog open={isInviteDialogOpen} onOpenChange={setIsInviteDialogOpen}>
            <DialogTrigger asChild>
              <Button className="w-full">
                <UserPlus className="mr-2 h-4 w-4" />
                Invite New Collaborator
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Invite a New Collaborator</DialogTitle>
                <DialogDescription>
                  Enter the email address of the person you&apos;d like to invite to your team.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleInvite}>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      placeholder="collaborator@example.com"
                      type="email"
                      value={newCollaboratorEmail}
                      onChange={(e) => setNewCollaboratorEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="role">Role</Label>
                    <Select value={selectedRole} onValueChange={setSelectedRole}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="admin">Admin</SelectItem>
                        <SelectItem value="member">Member</SelectItem>
                        <SelectItem value="viewer">Viewer</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <DialogFooter className="mt-6">
                  <Button type="submit">Send Invitation</Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Collaborators;
