import React from 'react'
import useAuth from '../../app/hooks/useAuth'
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({children}) => {
    const isLoggedIn = useAuth()
    return isLoggedIn ? children : <Navigate to='/auth-page' />
}

export default ProtectedRoute
