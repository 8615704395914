import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetMemberBusinessProfileQuery, useUpdateProfileMutation } from '../app/features/profile/profileApi';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { toast } from "./ui/use-toast";

const BusinessProfileManager = () => {
  const user = useSelector((state) => state.auth.user);
  const { data: memberBusinessProfile, isLoading } = useGetMemberBusinessProfileQuery(user.id);
  const [updateProfile] = useUpdateProfileMutation();

  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (memberBusinessProfile) {
      setProfileData(memberBusinessProfile.business);
    }
  }, [memberBusinessProfile]);

  if (isLoading) return <div>Loading...</div>;

  if (!memberBusinessProfile) {
    return <div>You don&apos;t have permission to manage any business profiles.</div>;
  }

  const handleInputChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(profileData).unwrap();
      toast({
        title: "Profile Updated",
        description: "The business profile has been updated successfully.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update profile.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Manage Business Profile</h1>
      <Card>
        <CardHeader>
          <CardTitle>{profileData.business_name || 'Business Profile'}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="business_name">Business Name</label>
              <Input
                id="business_name"
                name="business_name"
                value={profileData.business_name || ''}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="about">About</label>
              <Textarea
                id="about"
                name="about"
                value={profileData.about || ''}
                onChange={handleInputChange}
              />
            </div>
            {/* Add more fields as needed */}
            <Button type="submit">Update Profile</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default BusinessProfileManager;