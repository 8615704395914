import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ShoppingCart, CreditCard, ChevronLeft, Leaf, Droplet, Globe, Check, X } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Separator } from "./ui/separator";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "./ui/dialog";
import { supabase } from '../app/supabase';
import { clearCart, selectCartItems, selectBusinessInfo } from '../app/features/cart/cartSlice';
import { useCreateOrderMutation } from '../app/features/package/packageApi';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from './ui/use-toast';

// Load stripe outside of components to avoid recreating stripe object on every render
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ clientSecret, handleCheckout }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    await handleCheckout(stripe, elements);
    setIsLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <PaymentElement />
      <div className="mt-4">
        <Button 
          type="submit" 
          disabled={!stripe || isLoading} 
          className="w-full"
        >
          {isLoading ? "Processing..." : "Confirm Payment"}
        </Button>
      </div>
    </form>
  );
};

const CheckoutShop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector(selectCartItems);
  const businessInfo = useSelector(selectBusinessInfo);
  const [total, setTotal] = useState(0);
  const [impact, setImpact] = useState({
    trees: 0,
    co2: 0,
    fundedProjects: 0,
    bottles: 0,
  });
  const [paymentDetails, setPaymentDetails] = useState({
    name: '',
  });
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const profile = useSelector((state) => state.profile.profile);
  const [createOrder] = useCreateOrderMutation();
  const [clientSecret, setClientSecret] = useState('');
  const location = useLocation();

  useEffect(() => {
    let totalPrice = 0;
    let totalTrees = 0;
    let totalCO2 = 0;
    let totalProjects = new Set();

    Object.values(cart).forEach(item => {
      const itemPrice = item.product?.price || item.price || 0;
      totalPrice += item.quantity * itemPrice;
      totalTrees += item.quantity * (item.impact?.treesPlanted || 0);
      totalCO2 += item.quantity * (item.impact?.combinedEmissionsCountered || 0);
      if (item.projectId) {
        totalProjects.add(item.projectId);
      }
    });

    setTotal(totalPrice);
    setImpact({
      trees: totalTrees,
      co2: totalCO2,
      fundedProjects: totalProjects.size
    });

    // Fetch the client secret when the component mounts
    const fetchClientSecret = async () => {
      try {
        const { data, error } = await supabase.functions.invoke('create-payment-intent', {
          body: { 
            amount: total * 100,
            email: profile.email, // Ensure email is passed for customer creation
            metadata: { /* Add any necessary metadata */ }
          },
        });

        if (error) throw error;

        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Error fetching client secret:', err);
        setError('Failed to initialize payment. Please try again.');
      }
    };

    if (total > 0) {
      fetchClientSecret();
    }
  }, [cart, total, profile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckout = async (stripe, elements) => {
    setError('');
    setIsProcessing(true);

    if (!stripe || !elements) {
      setError('Payment is not ready. Please try again.');
      setIsProcessing(false);
      return;
    }

    try {
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (result.error) {
        throw result.error;
      }

      if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        // Prepare order data
        const orderData = {
          cartItems: Object.values(cart).map(item => ({
            ...item,
            product: { price: item.product?.price || item.price },
          })),
          totalItems: Object.values(cart).reduce((sum, item) => sum + item.quantity, 0),
          totalPrice: total,
          totalImpact: {
            treesPlanted: impact.trees,
            totalFundedProjects: impact.fundedProjects,
            combinedEmissionsCountered: impact.co2,
          },
          profile_id: profile.id,
          paymentIntent: result.paymentIntent.id, // Use the payment intent ID
          business_name: businessInfo.business_name || null,
          business_profile_id: businessInfo.business_profile_id || null,
        };

        // Use the createOrder mutation
        const { data: orderResult, error: orderError } = await createOrder(orderData);

        if (orderError) throw orderError;

        // Handle success
        setImpact({
          trees: orderResult.order[0].total_trees_planted,
          co2: orderResult.order[0].total_co2_offset,
          fundedProjects: orderResult.order[0].founded_projects,
          bottles: orderResult.order[0].total_bottles_removed,
        });
        setTotal(orderResult.order[0].total_price);
        dispatch(clearCart());
        setShowSuccessDialog(true);
      } else {
        throw new Error('Payment was not successful. Please try again.');
      }
    } catch (err) {
      console.error('Checkout error:', err);
      setError(err.message || 'An error occurred during checkout. Please try again.');
      toast({
        title: "Checkout Error",
        description: err.message || "There was a problem processing your payment. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate(businessInfo.is_business_type ? '/business-impact-shop' : '/impact-shop');
  };

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h1 className="text-3xl font-bold mb-6">Your Impact Cart</h1>
          {businessInfo.is_business_type && (
            <Card className="mb-4">
              <CardHeader>
                <CardTitle>Business Information</CardTitle>
              </CardHeader>
              <CardContent>
                <p><strong>Business Name:</strong> {businessInfo.business_name}</p>
                <p><strong>Business Profile ID:</strong> {businessInfo.business_profile_id}</p>
              </CardContent>
            </Card>
          )}
          {Object.values(cart).length === 0 ? (
            <Card>
              <CardContent className="pt-6">
                <p>Your cart is empty.</p>
              </CardContent>
            </Card>
          ) : (
            Object.values(cart).map((item) => (
              <Card key={item.id} className="mb-4">
                <CardHeader>
                  <CardTitle>{item.title}</CardTitle>
                  <CardDescription>Quantity: {item.quantity}</CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="font-bold">
                    AED {((item.product?.price || item.price || 0) * item.quantity).toFixed(2)}
                  </p>
                  <div className="mt-2 space-y-1 text-sm text-gray-500">
                    <div className="flex items-center">
                      <Leaf className="mr-2 h-4 w-4" /> {item.impact.treesPlanted * item.quantity} trees
                    </div>
                    {item.impact.bottlesRemoved && (
                      <div className="flex items-center">
                        <Droplet className="mr-2 h-4 w-4" /> {item.impact.bottlesRemoved * item.quantity} bottles
                      </div>
                    )}
                    <div className="flex items-center">
                      <Globe className="mr-2 h-4 w-4" /> {item.impact.combinedEmissionsCountered * item.quantity} kg CO2
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))
          )}
        </div>

        <div>
          <Card>
            <CardHeader>
              <CardTitle>Checkout</CardTitle>
              <CardDescription>Complete your impact purchase</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="name">Name on Card</Label>
                  <Input id="name" name="name" value={paymentDetails.name} onChange={handleInputChange} placeholder="John Doe" />
                </div>
                <div>
                  <Label htmlFor="payment-element">Payment Method</Label>
                  <div className="border rounded p-3">
                    {clientSecret && (
                      <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <PaymentForm clientSecret={clientSecret} handleCheckout={handleCheckout} />
                      </Elements>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex-col items-start">
              <div className="w-full">
                <Separator className="my-4" />
                <div className="flex justify-between font-bold mb-4">
                  <span>Total</span>
                  <span>AED {total.toFixed(2)}</span>
                </div>
                <div className="text-sm text-gray-500 mb-4">
                  <p>Trees to be planted: {impact.trees}</p>
                  <p>Plastic bottles to be removed: {impact.bottles}</p>
                  <p>CO2 to be offset: {impact.co2.toFixed(2)} kg</p>
                  <p className="font-bold mt-2">Total Purchase: AED {total.toFixed(2)}</p>
                </div>
              </div>
              {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </CardFooter>
          </Card>

          <Button variant="link" asChild className="mt-4">
            <Link to={businessInfo.is_business_type ? '/business-impact-shop' : '/impact-shop'}>
              <ChevronLeft className="mr-2" />
              Back to {businessInfo.is_business_type ? 'Business' : ''} Impact Shop
            </Link>
          </Button>
        </div>
      </div>

      <AnimatePresence>
        {showSuccessDialog && (
          <Dialog open={showSuccessDialog} onOpenChange={handleCloseSuccessDialog}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Purchase Successful!</DialogTitle>
                <DialogDescription>
                  Thank you for your impact purchase. Your contribution will make a real difference!
                </DialogDescription>
              </DialogHeader>
              <div className="mt-4">
                <h3 className="font-semibold mb-2">Your Impact:</h3>
                <ul className="list-disc list-inside space-y-1">
                  <li>{impact.trees} trees to be planted</li>
                  <li>{impact.bottles} plastic bottles to be removed</li>
                  <li>{impact.co2.toFixed(2)} kg of CO2 to be offset</li>
                </ul>
              </div>
              <div className="flex justify-between mt-4">
                <Button onClick={handleCloseSuccessDialog}>
                  Continue Shopping
                </Button>
                <Button asChild>
                  <Link to="/my-orders">View Orders</Link>
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CheckoutShop;