import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: {},
  businessInfo: {
    business_name: '',
    is_business_type: false,
    business_profile_id: null
  },
  cartType: null // 'business' or 'personal'
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { id, isBusinessItem } = action.payload;
      const newCartType = isBusinessItem ? 'business' : 'personal';

      // If cart type is changing, clear the cart and reset business info
      if (state.cartType !== newCartType) {
        state.items = {};
        if (!isBusinessItem) {
          state.businessInfo = initialState.businessInfo;
        }
        state.cartType = newCartType;
      }

      if (state.items[id]) {
        state.items[id].quantity += 1;
      } else {
        state.items[id] = { ...action.payload, quantity: 1 };
      }
    },
    removeFromCart: (state, action) => {
      const id = action.payload;
      if (state.items[id].quantity > 1) {
        state.items[id].quantity -= 1;
      } else {
        delete state.items[id];
      }
    },
    clearCart: (state) => {
      state.items = {};
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      if (quantity > 0) {
        state.items[id].quantity = quantity;
      } else {
        delete state.items[id];
      }
    },
    setBusinessInfo: (state, action) => {
      state.businessInfo = {
        ...state.businessInfo,
        ...action.payload
      };
      state.cartType = 'business';
    },
    clearBusinessInfo: (state) => {
      state.businessInfo = initialState.businessInfo;
      state.cartType = 'personal';
    }
  },
});

export const { 
  addToCart, 
  removeFromCart, 
  clearCart, 
  updateQuantity, 
  setBusinessInfo, 
  clearBusinessInfo 
} = cartSlice.actions;

export const selectCartItems = (state) => state.cart.items;
export const selectBusinessInfo = (state) => state.cart.businessInfo;
export const selectCartTotal = (state) => 
  Object.values(state.cart.items).reduce((total, item) => total + item.price * item.quantity, 0);
export const selectCartItemsCount = (state) => 
  Object.values(state.cart.items).reduce((count, item) => count + item.quantity, 0);

export default cartSlice.reducer;
