import React, { lazy, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ThemeProvider } from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import CarbonCalculatorPage from './components/CarbonCalculatorPage';
import ProjectDetail from './components/ProjectDetail';
import ProjectList from './components/ProjectList';
import EcoBusiness from './components/EcoBusiness';
import BaytiTeams from './components/BaytiTeams';
import SeePlans from './components/SeePlans';
import MyBayti from './components/MyBayti';
import AuthPage from './components/AuthPage';
import Dashboard from './components/Dashboard';
import DashboardAdmin from './components/DashboardAdmin';
import Header from './components/Header';
import HomeFooter from './components/HomeFooter';
import Sidebar from './components/Sidebar';
import ImpactProfile from './components/ImpactProfile';
import ImpactShop from './components/ImpactShop';
import TreeCounterWidget from './components/TreeCounterWidget';
import TheBayti from './components/TheBayti';
import PaymentMethod from './components/PaymentMethod';
import QrCodeGen from './components/QrCodeGen';
import { ContactPage } from './components/ContactPage';
import AboutUs from './components/AboutUs';
import ExploreDashboard from './components/ExploreDashboard';
import BaytiPlan from './components/BaytiPlan';
import useAuthCheck from './app/hooks/useAuthCheck';
import ProtectedRoute from './components/routechecker/ProtectedRoute';
import PublicRoute from './components/routechecker/PublicRoute';
import { Blog } from './components/Blog';
import UserImpactPage from './components/UserImpactPage';
import HandleOAuthRedirect from './app/hooks/handleAuthRedirect';
import '../src/styles/styles/allstyles/global.css'
import './index.css'
import ResetPassword from './pages/resetPassword';
import AdminPlan from './pages/AdminPlan';
import AdminPackage from './pages/AdminPackage';
import PayNow from './components/PayNow';
import { useGetAllPlansQuery } from './app/features/plan/planApi';
import { getAllPlans } from './app/features/plan/planSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllPackagesQuery } from './app/features/package/packageApi';
import { getAllPackages } from './app/features/package/packageSlice';
 import AdminOrder from './pages/AdminOrder';
import Orders from './pages/Orders';
import AdminContacts from './pages/AdminContacts';
import CheckoutPage from './components/CheckoutPage';
import BusinessImpactShop from './components/BusinessImpactShop';
import Collaborators from './components/Collaborators';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import { ScrollToTop } from './components/ScrollToTop';
import Loading from './components/Loading';
import SneakPeek from './components/SneakPeek';
import CheckoutShop from './components/CheckoutShop';
import { Toaster } from './components/ui/toaster';
import BusinessOrder from './pages/BusinessOrder';
import AdminUsers from './pages/AdminUsers';
import BusinessProfileManager from './components/BusinessProfileManager';
import BlogDetails from './pages/BlogDetails';
import AdminBlog from './pages/AdminBlog';
import AdminBlogList from './pages/AdminBlogList';

import { QueryClientProvider } from 'react-query';




// Lazy load components for better performance
const Hero = lazy(() => import('./components/Hero'));
const TripleCombo = lazy(() => import('./components/TripleCombo'));
const WhatWeDo = lazy(() => import('./components/WhatWeDo'));
const CarbonSolutions = lazy(() => import('./components/CarbonSolutions'));
const Partners = lazy(() => import('./components/Partners'));
const BaytiPersonal = lazy(() => import('./components/BaytiPersonal'));
const UAEAgenda = lazy(() => import('./components/UAEAgenda'));
const GetStarted = lazy(() => import('./components/GetStarted'));

// Define theme
const theme = {
  colors: {
    primary: 'blue 600',
    secondary: '#2196f3',
    background: '#f5f5f5',
    text: '#333333',
  },
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Poppins', sans-serif",
  },
  breakpoints: {
    mobile: '360px',
    tablet: '768px',
    desktop: '1024px',
  },
};

// Create global styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Poppins:wght@600;700&display=swap');

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${(props) => props.theme.fonts.body};
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.fonts.heading};
    margin-top: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
`;

// Loading component for Suspense fallback


// Home component that contains all the sections of your main page
const Home = () => (
  <>
    <Hero />
    <Partners />
    <WhatWeDo />
    <CarbonSolutions />
    <SneakPeek />
    <TripleCombo />
    <BaytiPersonal />
    <UAEAgenda />
    <GetStarted />
  </>
);


// DashboardLayout component for authenticated routes
const DashboardLayout = ({ children }) => (
  <div style={{ display: 'flex', height: '100vh' }}>
    <Sidebar />
    <div style={{ flex: 1, overflow: 'auto' }}>{children}</div>
  </div>
);

const App = () => {
  const stripePromise = loadStripe('pk_test_51PpvRt1JIVewXWEmAdxDryiF3gfYN23upqU1A2Ws8UChQMMjYGKgXtyM4ROTgFFP7zLKaRV7JdZ37klu6Yjdeq1U00iq4wnfuG');
  const authChecked =  useAuthCheck();
  const dispatch = useDispatch()
  const { isLoading, error } = useGetAllPlansQuery();
  const { data: packagesData, error: getPkgError, isLoading: isPkgFetching } = useGetAllPackagesQuery();

  React.useEffect(() => {
    document.title = 'Bayti Eco - Carbon Solutions for Everyone';
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Carbon solutions for businesses and individuals';
    document.head.appendChild(metaDescription);
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content =
      'width=device-width, initial-scale=1, shrink-to-fit=no';
    document.head.appendChild(metaViewport);
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaViewport);
    };
  }, []);

  return (

    !authChecked ? (
      <div>Loading...</div>
    ):(
      <Elements stripe={stripePromise}>
      <>
        <Toaster/>
        <ToastContainer/>
       <Router>
          <ScrollToTop />
           <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <>
                        <Header />
                        <Home />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route
                    path="/carbon-calculator"
                    element={
                      <>
                        <Header />
                        <CarbonCalculatorPage />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/subscribed-plans"
                    element={
                      <>
                        <DashboardLayout>
                          <AdminPlan/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/contact-list"
                    element={
                      <>
                        <DashboardLayout>
                          <AdminContacts/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/my-orders"
                    element={
                      <>
                        <DashboardLayout>
                        <Orders/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/package-orders"
                    element={
                      <>
                        <DashboardLayout>
                          <AdminOrder/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <>
                        <DashboardLayout>
                           <AdminUsers/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/admin/blogs"
                    element={
                      <>
                        <DashboardLayout>
                           <AdminBlogList/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/admin-packages"
                    element={
                      <>
                        <DashboardLayout>
                          <AdminPackage/>
                        </DashboardLayout>
                      </>
                    }
                  />
                  <Route
                    path="/carbon-marketplace"
                    element={
                      <>
                        <Header />
                        <ProjectList />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/projects/:id"
                    element={
                      <>
                        <Header />
                        <ProjectDetail />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/eco-business"
                    element={
                      <>
                        <Header />
                        <EcoBusiness />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/bayti-teams"
                    element={
                      <>
                        <Header />
                        <BaytiTeams />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/see-plans"
                    element={
                      <>
                        <Header />
                          <SeePlans />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/checkout-package"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                        <CheckoutShop/>
                        </DashboardLayout>
                      </ProtectedRoute>
                      
                    }
                  />
                  <Route
                    path="/add-blog"
                    element={
                      <ProtectedRoute>
                      <DashboardLayout>
                          <AdminBlog/>
                      </DashboardLayout>
                    </ProtectedRoute>
                    }
                  />
                  
                  
                  <Route
                    path="/checkout/:planType/:planName"
                    element={
                      <ProtectedRoute>
                      <DashboardLayout>
                          <CheckoutPage />
                      </DashboardLayout>
                    </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/my-bayti"
                    element={
                      <>
                        <Header />
                        <MyBayti />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/contact"
                    element={
                      <>
                        <Header />
                        <ContactPage />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/about-us"
                    element={
                      <>
                        <Header />
                        <AboutUs />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/blog"
                    element={
                      <>
                        <Header />
                        <Blog />
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/blog/:slug"
                    element={
                      <>
                        <Header />
                         <BlogDetails/>
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route
                    path="/explore-dashboard"
                    element={
                      <>
                        <Header />
                        <ExploreDashboard />
                        <HomeFooter />
                      </>
                    }
                  />                    
                  <Route
                    path="/get-started"
                    element={
                      <>
                        <Header />
                            <GetStarted/>
                        <HomeFooter />
                      </>
                    }
                  />
                  <Route path="/reset-password" 
                  element={
                    <PublicRoute>
                      <ResetPassword/>
                    </PublicRoute>
                  } 
                  />
                  <Route path="/auth-page" 
                  element={
                    <PublicRoute>
                      <AuthPage />
                    </PublicRoute>
                  } 
                  />
                  {/* handing google auth callback  */}
                  <Route path="/auth/callback" element={<HandleOAuthRedirect/>} />
                  <Route path="/impact/:webpage_link" 
                  element={<UserImpactPage />} />
   
                  <Route path="/impact/:username" element={<UserImpactPage />} />
                  <Route path="/auth-page" element={<AuthPage />} />
          
                  <Route path="/dashboard"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <Dashboard />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/dashboard-admin"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <DashboardAdmin />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/impact-profile"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <ImpactProfile />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/impact-shop"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <ImpactShop />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/tree-counter-widget"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <TreeCounterWidget />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/the-bayti"
                    element={
                        <>
                          <DashboardLayout>
                            <TheBayti />
                          </DashboardLayout>
                        </>
                    }
                  />
                  <Route
                    path="/bayti-plan"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <BaytiPlan />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/payment-method"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <PaymentMethod />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/subscribe-now"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <PayNow/>
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/qr-code-gen"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <QrCodeGen />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/business-orders"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <BusinessOrder/>
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaborators"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <Collaborators />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/business-impact-shop"
                    element={<BusinessImpactShop />}
                  />
                  <Route
                    path="/manage-business-profile"
                    element={
                      <ProtectedRoute>
                        <DashboardLayout>
                          <BusinessProfileManager />
                        </DashboardLayout>
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </Suspense>
          </ThemeProvider>
       </Router>
    </>
    </Elements>
    )
  
  );
};

export default App;
