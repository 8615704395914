import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userLoggedIn } from '../features/auth/authSlice';
import { supabase } from '../supabase';  
import { useCreateProfileMutation } from '../features/profile/profileApi';

const HandleOAuthRedirect = () => {
  const [createProfile,{data:createData,erro:createError,loading:errorLoading}] =  useCreateProfileMutation()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const handleOAuthRedirect = async () => {
      try {
        const url = new URL(window.location.href);
        const hash = url.hash.substring(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');
        const providerRefreshToken = params.get('provider_refresh_token');

        if (accessToken) {
           const { data, error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (error) {
            throw new Error(error.message);
          }

           if (data) {
            const { session, user } = data;
            sessionStorage.setItem(
              'auth',
              JSON.stringify({
                accessToken: session.access_token,
                user,
              })
            );

            dispatch(
              userLoggedIn({
                accessToken: session.access_token,
                user,
              })
            );
           createProfile({user_id:data.user.id,profile_type:"Personal"})   
            

            window.location.href = '/dashboard'; 
          }
        } else {
          setError('No authentication code found in URL.');
        }
      } catch (error) {
        console.error('Error during OAuth process:', error.message);
        setError('An error occurred during authentication. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    handleOAuthRedirect();
  }, [dispatch,createProfile]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};

export default HandleOAuthRedirect;
