import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Partners from './Partners';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, Section } from "./ui/layout";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "./ui/card";
import { Button } from "./ui/button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "./ui/accordion";
import { Badge } from "./ui/badge";
import { Trees, Recycle, Globe, UserCircle, GaugeCircle, ShoppingCart, ChevronRight, Award, Zap, Shield } from 'lucide-react';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const MyBayti = () => {
  const [planType, setPlanType] = useState('individual');
  
  const plans = [
    { title: 'Guardian', price: '25', trees: 2, bottles: 20, co2: 100 },
    { title: 'Protector', price: '49', trees: 4, bottles: 50, co2: 200 },
    { title: 'Warrior', price: '95', trees: 6, bottles: 30, co2: 750 },
    { title: 'Hero', price: '160', trees: 12, bottles: 40, co2: 1515 },
  ];

  const legendPlan = {
    title: 'Legend',
    price: '449',
    trees: 100,
    bottles: 1000,
    co2: 2000,
  };

  const features = [
    {
      icon: <UserCircle className="w-12 h-12 text-pink-500" />,
      title: 'Impact Profile',
      items: [
        'Real-time totals',
        'View your total impact',
        'Share all you have done',
        'Public profile to showcase',
        'Access your dashboard',
      ],
    },
    {
      icon: <GaugeCircle className="w-12 h-12 text-blue-500" />,
      title: 'Bayti Dashboard',
      items: [
        'Generate certificates',
        'Top-up in the Impact Shop',
        'Build tree count widget for websites',
        'Customise your Impact Profile',
        'Manage your team',
      ],
    },
    {
      icon: <ShoppingCart className="w-12 h-12 text-yellow-500" />,
      title: 'Access Impact Shop',
      items: [
        'Counter your activities',
        'Top-up your trees anytime',
        'Remove extra ocean plastic',
        'Reduce your travel emissions',
        'Carbon neutral commutes',
      ],
    },
  ];

  const faqs = [
    {
      question: 'How does MyBayti help me reduce my carbon footprint?',
      answer: 'MyBayti is a climate positive living platform that allows you to offset your carbon footprint by planting trees, removing plastic waste, and supporting climate projects.',
    },
    {
      question: 'When does the carbon offsetting process start after signing up?',
      answer: 'As soon as you sign up, you can start offsetting your carbon footprint',
    },
    {
      question: 'How can I track the progress and impact of my contributions?',
      answer: 'You can track your progress and impact by visiting your Impact Profile.',
    },
    {
      question: 'What types of environmental projects does MyBayti support?',
      answer: 'MyBayti supports a variety of environmental projects you can see which climate projects we are supporting in climate projects.',
    },
    {
      question: 'How can I trust that my contributions are making a difference?',
      answer: 'We use the highest standards of verification and accreditation to ensure that your contributions are making a real impact.',
    },
    {
      question: 'Can I contact customer support if I need assistance?',
      answer: 'Yes, you can contact customer support by sending us a message and we will get back to you as soon as possible.',
    },
  ];

  return (
    <Container className="py-12 bg-gradient-to-br from-blue-50 to-white">
      <Section className="text-center mb-16">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <img src="/images/Ecofamily.jpg" alt="Bayti Life" className="h-48 w-48 mx-auto mb-6 rounded-full shadow-xl" />
          <Badge className="mb-4 px-3 py-1 text-sm font-semibold bg-blue-100 text-blue-800">Personal Impact</Badge>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-blue-900">Find Your Perfect Plan</h1>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Begin your climate positive journey with MyBayti. Make a difference from day one.
          </p>
        </motion.div>
        <Tabs value={planType} onValueChange={setPlanType} className="w-full max-w-md mx-auto">
          <TabsList className="grid w-full grid-cols-2 p-1 bg-blue-100 rounded-lg">
            <TabsTrigger 
              value="individual" 
              className={`${planType === 'individual' ? 'bg-white text-blue-600 shadow-md' : 'text-blue-600'} rounded-md transition-all duration-200`}
            >
              Individual Plans
            </TabsTrigger>
            <TabsTrigger 
              value="family" 
              className="text-gray-400 cursor-not-allowed rounded-md transition-all duration-200"
              disabled
            >
              Family Plans
              <Badge className="ml-2 bg-yellow-200 text-yellow-800">Coming Soon</Badge>
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </Section>

      <Section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
        <AnimatePresence>
          {plans.map((plan, index) => (
            <motion.div
              key={plan.title}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ delay: index * 0.1 }}
            >
              <Card className={`relative overflow-hidden ${plan.title === 'Protector' ? 'border-blue-500 shadow-xl' : 'shadow-lg'} transition-all duration-300 hover:shadow-2xl hover:-translate-y-1`}>
                {plan.title === 'Protector' && (
                  <Badge className="absolute top-4 right-4 bg-blue-500 text-white px-2 py-1">Most Popular</Badge>
                )}
                <CardHeader className="bg-gradient-to-br from-blue-50 to-indigo-50 pb-10">
                  <CardTitle className="text-2xl font-bold text-blue-900">{plan.title}</CardTitle>
                  <CardDescription className="text-3xl font-bold text-blue-600 mt-2">
                    {plan.price}<span className="text-lg font-normal">Dhs</span>
                    <span className="text-base font-normal text-gray-600">/month</span>
                  </CardDescription>
                </CardHeader>
                <CardContent className="pt-8">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Trees className="w-6 h-6 mr-3 text-green-500" />
                      <span className="text-lg"><strong>{plan.trees}</strong> Trees Planted</span>
                    </li>
                    <li className="flex items-center">
                      <Recycle className="w-6 h-6 mr-3 text-blue-500" />
                      <span className="text-lg"><strong>{plan.bottles}</strong> Ocean-bound plastic bottles removed</span>
                    </li>
                    <li className="flex items-center">
                      <Globe className="w-6 h-6 mr-3 text-yellow-500" />
                      <span className="text-lg"><strong>{plan.co2}</strong> kg CO2 via Humanitarian climate projects</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button asChild className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 text-lg font-semibold transition-all duration-200">
                    <Link to={`/checkout/personal/${plan.title.toLowerCase()}`}>
                      Get Started
                    </Link>
                  </Button>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </Section>

      <Section className="mb-16 flex justify-center">
        <motion.div
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          className="w-full max-w-2xl"
        >
          <Card className="shadow-2xl overflow-hidden">
            <div className="bg-gradient-to-r from-yellow-400 to-orange-500 p-1">
              <CardHeader className="bg-white">
                <div className="flex items-center justify-between">
                  <CardTitle className="text-3xl font-bold text-blue-900">{legendPlan.title}</CardTitle>
                  <Award className="w-12 h-12 text-yellow-500" />
                </div>
                <CardDescription className="text-4xl font-bold text-blue-600 mt-2">
                  {legendPlan.price}<span className="text-xl font-normal">Dhs</span>
                  <span className="text-lg font-normal text-gray-600">/month</span>
                </CardDescription>
              </CardHeader>
            </div>
            <CardContent className="pt-8">
              <ul className="space-y-6">
                <li className="flex items-center">
                  <Trees className="w-8 h-8 mr-4 text-green-500" />
                  <span className="text-xl"><strong>{legendPlan.trees}</strong> Trees Planted</span>
                </li>
                <li className="flex items-center">
                  <Recycle className="w-8 h-8 mr-4 text-blue-500" />
                  <span className="text-xl"><strong>{legendPlan.bottles}</strong> Ocean-bound plastic bottles removed</span>
                </li>
                <li className="flex items-center">
                  <Globe className="w-8 h-8 mr-4 text-yellow-500" />
                  <span className="text-xl"><strong>{legendPlan.co2}</strong> kg CO2 via Humanitarian climate projects</span>
                </li>
              </ul>
            </CardContent>
            <CardFooter>
              <Button asChild className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-yellow-500 hover:to-orange-600 text-white py-6 text-xl font-bold transition-all duration-200">
                <Link to={`/checkout/personal/${legendPlan.title.toLowerCase()}`}>
                  Become a Legend
                </Link>
              </Button>
            </CardFooter>
          </Card>
        </motion.div>
      </Section>

      <Section className="mb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">What&apos;s Included with MyBayti</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div variants={fadeInUp} initial="hidden" animate="visible">
            <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl text-blue-900">
                  <Zap className="w-8 h-8 mr-3 text-yellow-500" />
                  Track and share your total impact
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-700">Your Impact Profile is your public webpage where you can see all the action you&apos;ve made so far. Every tree, bottle and kg of CO2 countered is accounted for. View an example of an Impact Profile.</p>
              </CardContent>
            </Card>
          </motion.div>
          <motion.div variants={fadeInUp} initial="hidden" animate="visible" transition={{ delay: 0.1 }}>
            <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl text-blue-900">
                  <Globe className="w-8 h-8 mr-3 text-green-500" />
                  Environmental & social improvements
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-700">As well as planting trees and removing plastic waste to restore eco-systems, every project you support helps vulnerable families improve their lives and the communities they live in. About the SDGs.</p>
              </CardContent>
            </Card>
          </motion.div>
          <motion.div variants={fadeInUp} initial="hidden" animate="visible" transition={{ delay: 0.2 }}>
            <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl text-blue-900">
                  <Shield className="w-8 h-8 mr-3 text-blue-500" />
                  Trusted & accredited
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-700">We plant our trees with certified organizations that specialize in forest restoration, plastic waste removal, and projects aligned with global Social Development Goals for peace of mind.</p>
              </CardContent>
            </Card>
          </motion.div>
          <motion.div variants={fadeInUp} initial="hidden" animate="visible" transition={{ delay: 0.3 }}>
            <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl text-blue-900">
                  <ChevronRight className="w-8 h-8 mr-3 text-purple-500" />
                  Regular summaries & updates
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-700">We&apos;ll regularly send you informative monthly summaries highlighting all your positive environmental actions and keep you up to date with our latest news.</p>
              </CardContent>
            </Card>
          </motion.div>
        </div>
      </Section>

      <Section className="mb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">Powerful Features at Your Fingertips</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
        <motion.div
          key={index}
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          transition={{ delay: index * 0.2 }}
        >
          <Card className="h-full hover:shadow-xl transition-all duration-300">
            <CardHeader>
              <div className="mb-4 bg-blue-100 p-4 rounded-full inline-block">{feature.icon}</div>
              <CardTitle className="text-2xl font-bold text-blue-900">{feature.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-3">
                {feature.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center">
                    <ChevronRight className="w-5 h-5 mr-3 text-blue-500 flex-shrink-0" />
                    <span className="text-gray-700">{item}</span>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </motion.div>
      ))}
    </div>
  </Section>

  <Partners />

  <Section className="text-center mb-16">
    <motion.h3 
      className="text-2xl md:text-3xl font-semibold mb-8 text-blue-900"
      variants={fadeInUp}
      initial="hidden"
      animate="visible"
    >
      Trusted and Accredited by Leading Organizations in Carbon Reduction and Sustainability
    </motion.h3>
    {/* Add logos of trusted organizations here */}
  </Section>

  <Section className="text-center mb-16">
    <motion.div
      variants={fadeInUp}
      initial="hidden"
      animate="visible"
    >
      <h3 className="text-2xl md:text-3xl font-semibold mb-6 text-blue-900">Looking for Bayti Business?</h3>
      <Button asChild variant="outline" size="lg" className="text-blue-600 border-blue-600 hover:bg-blue-50">
        <Link to="/eco-business">Go to EcoBusiness</Link>
      </Button>
    </motion.div>
  </Section>

  <Section className="mb-16">
    <motion.div
      variants={fadeInUp}
      initial="hidden"
      animate="visible"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">Frequently Asked Questions</h2>
      <Accordion type="single" collapsible className="w-full max-w-3xl mx-auto">
        {faqs.map((faq, index) => (
          <AccordionItem key={index} value={`item-${index}`} className="border-b border-gray-200">
            <AccordionTrigger className="flex justify-between items-center py-4 text-lg font-medium text-left text-blue-600 hover:text-blue-700">
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="py-4 text-gray-700 text-lg">
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </motion.div>
  </Section>

  <Section className="text-center bg-gradient-to-br from-blue-100 to-indigo-100 rounded-lg p-12">
    <motion.div
      variants={fadeInUp}
      initial="hidden"
      animate="visible"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-6 text-blue-900">Have Some Questions?</h2>
      <p className="text-xl text-gray-700 mb-8 max-w-2xl mx-auto">
        Our friendly team is here to help. Chat with us below or send us a message, and we&apos;ll get back to you as soon as possible.
      </p>
      <Button asChild size="lg" className="bg-blue-600 hover:bg-blue-700 text-white">
        <Link to="/contact">Send a Message</Link>
      </Button>
    </motion.div>
  </Section>
</Container>
  );
};

export default MyBayti;
