import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { setEmailSent } from "./mailSlice";

export const mailApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      queryFn: async ({sender, to, subject, text, html, attachments }) => {
        try {
          const response = await supabase.functions.invoke('send-email', {
            body: {sender, to, subject, text, html, attachments },
          });

           return { data: response }; // Return the result
        } catch (error) {
          console.error('Error sending email:', error); // Log the entire error
          return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setEmailSent(true));
        } catch (error) {
          console.error('Failed to send email:', error);
        }
      }
    }),
  }),
});

export const {
  useSendEmailMutation,
} = mailApi;