import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLoggedOut } from '../app/features/auth/authSlice';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "./ui/sheet";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Leaf, Users, BarChart, CloudDownload, Settings, Menu, LogOut, ChevronDown, ChevronUp } from 'lucide-react';
import { useGetPlansByProfileQuery } from '../app/features/plan/planApi';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.auth);
  const username = auth?.user ? auth.user.user_metadata.full_name : '';
  const profile = useSelector((state) => state.profile.profile);
  const userHasPlan = profile?.hasplan;
  const planDetails = profile?.plans;
  const [openSections, setOpenSections] = useState({});

  useEffect(() => {
    console.log("auth details:" + username);
  }, [username]);

  const handleLogoClick = () => {
    setMobileMenuOpen(false);
    navigate('/');
  };

  const handleSignOut = () => {
    dispatch(userLoggedOut());
    sessionStorage.clear();
  };

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const menuItems = [
    {
      title: 'Business',
      items: [
        { title: 'Carbon Calculator', icon: <Leaf className="mr-2 h-4 w-4 text-blue-600" />, description: 'Measure your carbon footprint', link: '/carbon-calculator' },
        { title: 'Dashboard', icon: <BarChart className="mr-2 h-4 w-4 text-blue-600" />, description: 'Track and showcase your impact', link: '/explore-dashboard' },
        { title: 'Bayti Teams', icon: <Users className="mr-2 h-4 w-4 text-blue-600" />, description: 'Team carbon management', link: '/bayti-teams' },
        { title: 'Eco-Business', icon: <Settings className="mr-2 h-4 w-4 text-blue-600" />, description: 'Sustainable business solutions', link: '/eco-business' },
      ],
    },
    {
      title: 'Personal',
      items: [
        { title: 'MyBayti', icon: <Leaf className="mr-2 h-4 w-4 text-blue-600" />, description: 'Personal carbon management', link: userHasPlan ? '/the-bayti' : '/my-bayti' },
        { title: 'Dashboard', icon: <BarChart className="mr-2 h-4 w-4 text-blue-600" />, description: 'Track and showcase your impact', link: '/explore-dashboard' },
      ],
    },
    {
      title: 'Resources',
      items: [
        { title: 'Blog', icon: <Leaf className="mr-2 h-4 w-4 text-blue-600" />, description: 'Latest insights and tips', link: '/blog' },
        { title: 'Case Studies', icon: <CloudDownload className="mr-2 h-4 w-4 text-blue-600" />, description: 'Success stories and examples', link: '/case-studies' },
      ],
    },
    {
      title: 'Company',
      items: [
        { title: 'About Us', icon: <Users className="mr-2 h-4 w-4 text-blue-600" />, description: 'Our mission and vision', link: '/about-us' },
        { title: 'Contact', icon: <Settings className="mr-2 h-4 w-4 text-blue-600" />, description: 'Get in touch with us', link: '/contact' },
      ],
    },
  ];

  return (
    <header className="sticky top-0 z-50 w-full border-b2 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex items-center justify-between px-4">
        {/* Hamburger menu on the far left on mobile */}
        <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" className="px-0 text-base hover:bg-transparent focus:ring-0 md:hidden">
              <Menu className="h-6 w-6 text-blue-600" />
              <span className="sr-only">Toggle Menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="pr-0 overflow-y-auto">
            <SheetHeader>
              <SheetTitle className="text-blue-600">Welcome!</SheetTitle>
            </SheetHeader>
            <div className="flex flex-col space-y-4 mt-4">
              {menuItems.map((menuItem, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <button
                    onClick={() => toggleSection(menuItem.title)}
                    className="flex items-center justify-between font-semibold text-lg text-blue-600"
                  >
                    {menuItem.title}
                    {openSections[menuItem.title] ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                  {openSections[menuItem.title] && (
                    <div className="ml-4 flex flex-col space-y-2">
                      {menuItem.items.map((item, itemIndex) => (
                        <Link
                          key={itemIndex}
                          to={item.link}
                          className="flex items-center py-2 px-4 rounded-md hover:bg-blue-100"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {item.icon}
                          <span className="ml-2 text-blue-600">{item.title}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <Button asChild className="mt-4 bg-blue-600 text-white hover:bg-blue-700">
                <Link to="/get-started">Get Started</Link>
              </Button>
              {/* Arabic button moved into the hamburger menu */}
              <Button
                variant="outline"
                size="sm"
                className="mt-4 border-blue-600 text-blue-600 hover:bg-blue-50"
              >
                <span className="text-sm">العربية</span>
              </Button>
            </div>
          </SheetContent>
        </Sheet>

        {/* Updated logo container */}
        <div className="flex-1 flex justify-center md:justify-start md:pl-2 lg:pl-10">
          <Link to="/" className="flex items-center space-x-2 ml-12 md:ml-0" onClick={handleLogoClick}>
            <img src={`${process.env.PUBLIC_URL}/images/BaytiEcoLogo.png`} alt="Bayti Logo" className="h-auto w-24 md:w-28 lg:w-32" />
          </Link>
        </div>

        {/* Login button on mobile, outside the hamburger menu, on far right */}
        <div className="md:hidden flex items-center">
          {username ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full ml-4">
                  <Avatar className="h-8 w-8">
                    <AvatarImage src="/avatar.png" alt={username} />
                    <AvatarFallback className="bg-blue-600 text-white">{username.charAt(0)}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuItem asChild>
                  <Link to="/dashboard" className="text-blue-600">Dashboard</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleSignOut} className="text-blue-600">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Button asChild variant="ghost" className="ml-4 text-blue-600">
              <Link to="/auth-page">Log In</Link>
            </Button>
          )}
        </div>

        {/* Container for navigation and Arabic text on larger screens */}
        <div className="hidden md:flex flex-grow items-center justify-center">
          <NavigationMenu>
            <NavigationMenuList>
              {menuItems.map((menuItem, index) => (
                <NavigationMenuItem key={index}>
                  <NavigationMenuTrigger className="text-blue-600 text-base md:text-lg lg:text-xl">{menuItem.title}</NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-transparent backdrop-blur-md">
                    <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                      {menuItem.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <NavigationMenuLink asChild>
                            <Link
                              className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-blue-100/50 hover:text-blue-700 focus:bg-blue-100/50 focus:text-blue-700"
                              to={item.link}
                            >
                              <div className="flex items-center text-sm md:text-base lg:text-lg font-medium leading-none">
                                {item.icon}
                                {item.title}
                              </div>
                              <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                                {item.description}
                              </p>
                            </Link>
                          </NavigationMenuLink>
                        </li>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
          {/* Arabic button remains on larger screens */}
          <Button
            variant="outline"
            size="sm"
            className="ml-4 border-blue-600 text-blue-600 hover:bg-blue-50"
          >
            <span className="text-sm md:text-base lg:text-lg">العربية</span>
          </Button>
        </div>

        {/* Login and Get Started buttons, visible only on larger screens */}
        <div className="hidden md:flex items-center space-x-2">
          {username ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 rounded-full">
                  <Avatar className="h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12">
                    <AvatarImage src="/avatar.png" alt={username} />
                    <AvatarFallback className="bg-blue-600 text-white">{username.charAt(0)}</AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuItem asChild>
                  <Link to="/dashboard" className="text-blue-600">Dashboard</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleSignOut} className="text-blue-600">
                  <LogOut className="mr-2 h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Button asChild variant="ghost">
              <Link to="/auth-page" className="text-blue-600">Log In</Link>
            </Button>
          )}
          <Button asChild className="bg-blue-600 text-white hover:bg-blue-700 md:h-10 lg:h-12">
            <Link to="/get-started">Get Started</Link>
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
