import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Mail, Lock, User, LogIn, UserPlus, ArrowLeft } from 'lucide-react';
import { useForgetPasswordMutation, useSignInMutation, useSignInWithGoogleMutation, useSignUpMutation } from '../app/features/auth/authApi';
import { useCreateProfileMutation } from '../app/features/profile/profileApi';
import { useDispatch } from 'react-redux';
import { userLoggedIn, userSignUp } from '../app/features/auth/authSlice';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

const AuthPage = () => {
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [verificationSent, setVerificationSent] = useState(false);
  const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
  const navigate = useNavigate();
  
  const [signIn, { data: signInData, isLoading: signInLoading, error: signInError }] = useSignInMutation();
  const [signUp, { data: signUpData, isLoading: signUpLoading, error: signUpError }] = useSignUpMutation();
  const [signInWithGoogle, { data: signGoogleData, isLoading: signGoogleLoading, error: signGoogleError }] = useSignInWithGoogleMutation();
  const [forgetPassword, { data: forgetPassData, isLoading: forgetPassLoading, error: forgetPassError }] = useForgetPasswordMutation();
  const [createProfile, { data: createProfileData, isLoading: createProfileLoading, error: createProfileError }] = useCreateProfileMutation();

  useEffect(() => {
    if (activeTab === 'forgot') {
      if (forgetPassError) {
        setError(forgetPassError.message);
      }
      if (forgetPassData) {
        setForgotPasswordSent(true);
      }
    } else if (activeTab === 'login') {
      if (signInError) {
        setError(signInError.message);
      }
      if (signInData?.user && signInData?.session) {
        navigate('/dashboard');
      }
    } else if (activeTab === 'register') {
      if (signUpError) {
        setError(signUpError.message);
      }
      if (signUpData) {
        setVerificationSent(true);
        // createProfile({ user_id: signUpData.user.id, profile_type: "Personal" });
      }
    }
  }, [activeTab, signInData, signInError, signUpData, signUpError, forgetPassData, forgetPassError, navigate]);

  useEffect(() => {
    if (signGoogleError) {
      setError(signGoogleError.message);
    } else if (signGoogleData?.user && signGoogleData?.session) {
      navigate('/dashboard');
    }
  }, [signGoogleError, signGoogleData, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (activeTab === 'forgot') {
        await forgetPassword({ email });
      } else if (activeTab === 'login') {
        await signIn({ email, password });
      } else if (activeTab === 'register') {
        await signUp({ name, email, password });
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleSocialLogin = async () => {
    setError('');
    try {
      await signInWithGoogle();
    } catch (error) {
      setError('An error occurred during social login. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 to-indigo-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card>
          <CardHeader className="space-y-1">
            <div className="flex items-center justify-between">
              <CardTitle className="text-2xl font-bold">
                {activeTab === 'login' ? 'Sign In' : activeTab === 'register' ? 'Create Account' : 'Reset Password'}
              </CardTitle>
              <Button variant="ghost" size="icon" asChild>
                <Link to="/">
                  <ArrowLeft className="h-4 w-4" />
                </Link>
              </Button>
            </div>
            <CardDescription>
              Enter your details to {activeTab === 'login' ? 'sign in to' : activeTab === 'register' ? 'create' : 'reset your password for'} your account
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            {error && (
              <Alert variant="destructive">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {forgotPasswordSent && (
              <Alert>
                <AlertTitle>Password Reset Email Sent</AlertTitle>
                <AlertDescription>
                  Please check your email for instructions to reset your password.
                </AlertDescription>
              </Alert>
            )}
            {verificationSent && (
              <Alert>
                <AlertTitle>Verification Email Sent</AlertTitle>
                <AlertDescription>
                  A verification email has been sent. Please verify your email to complete the sign-up process.
                </AlertDescription>
              </Alert>
            )}
            {!forgotPasswordSent && !verificationSent && (
              <form onSubmit={handleSubmit} className="space-y-4">
                <Tabs value={activeTab} onValueChange={setActiveTab}>
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="login">Login</TabsTrigger>
                    <TabsTrigger value="register">Register</TabsTrigger>
                    <TabsTrigger value="forgot">Forgot</TabsTrigger>
                  </TabsList>
                  <TabsContent value="login">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input id="email" type="email" icon={<Mail className="h-4 w-4" />} value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="password">Password</Label>
                      <Input id="password" type="password" icon={<Lock className="h-4 w-4" />} value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                  </TabsContent>
                  <TabsContent value="register">
                    <div className="space-y-2">
                      <Label htmlFor="name">Full Name</Label>
                      <Input id="name" type="text" icon={<User className="h-4 w-4" />} value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input id="email" type="email" icon={<Mail className="h-4 w-4" />} value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="password">Password</Label>
                      <Input id="password" type="password" icon={<Lock className="h-4 w-4" />} value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                  </TabsContent>
                  <TabsContent value="forgot">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input id="email" type="email" icon={<Mail className="h-4 w-4" />} value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                  </TabsContent>
                </Tabs>
                <Button className="w-full" type="submit">
                  {activeTab === 'login' ? <LogIn className="mr-2 h-4 w-4" /> : activeTab === 'register' ? <UserPlus className="mr-2 h-4 w-4" /> : <Mail className="mr-2 h-4 w-4" />}
                  {activeTab === 'login' ? 'Sign In' : activeTab === 'register' ? 'Sign Up' : 'Send Reset Link'}
                </Button>
              </form>
            )}
          </CardContent>
          <CardFooter>
            <Button variant="outline" className="w-full" onClick={handleSocialLogin}>
              Sign in with Google
            </Button>
          </CardFooter>
        </Card>
      </motion.div>
    </div>
  );
};

export default AuthPage;