import React from 'react';
import { motion } from 'framer-motion';
import { Container, Section } from "./ui/layout";
import { Card } from "./ui/card";

function Partners() {
  const partners = [
    { name: 'Gold Standard', logo: `${process.env.PUBLIC_URL}/images/goldstandard.png` },
    { name: 'Emirates Nature', logo: `${process.env.PUBLIC_URL}/images/emirtaes nature.jpg` },
    { name: 'Plastic Bank', logo: `${process.env.PUBLIC_URL}/images/plastic.png` },
    { name: 'Veritree', logo: `${process.env.PUBLIC_URL}/images/Veritreelogo.png` },
    { name: 'United Nations', logo: `${process.env.PUBLIC_URL}/images/Unlogo.png` },
    { name: 'Masdar', logo: `${process.env.PUBLIC_URL}/images/masdar.png` },
  ];

  return (
    <Section className="bg-gray-50 py-12 md:py-16 lg:py-20">
      <Container>
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-gray-800">
          In Collaboration With Our Trusted Partners
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {partners.map((partner, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card className="flex items-center justify-center p-4 h-24 md:h-28 transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
                <img
                  src={partner.logo}
                  alt={partner.name}
                  className="max-w-full max-h-full object-contain"
                />
              </Card>
            </motion.div>
          ))}
        </div>
      </Container>
    </Section>
  );
}

export default Partners;