import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { Trees, Home, Droplet, Globe } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { useNavigate } from 'react-router-dom';  // Assuming you're using react-router for navigation
import { useGenerateCertificateMutation } from '../app/features/profile/profileApi';
 
 
const ImpactSummary = () => {
  const profile = useSelector((state) => state.profile.profile);
  const navigate = useNavigate();
  const [generateCertificate] = useGenerateCertificateMutation();
  const [loading, setLoading] = useState(false);
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const summaryItems = [
    { icon: Trees, color: "text-green-600", value: profile?.tree || 0, label: "Trees Planted" },
    { icon: Home, color: "text-pink-600", value: profile?.carbon || 0, label: "Climate Projects" },
    { icon: Droplet, color: "text-blue-600", value: `${profile?.plastic || 0} bottles`, label: "Ocean Plastic Removed" },
    { icon: Globe, color: "text-brown-600", value: `${profile?.co2 || 0} t CO2e`, label: "Total CO2 Sequestered" },
  ];

  const handleGenerateCertificate = async () => {
    if (profile?.co2 >= 1000) {
      // Construct the JSON object
      const certificateData = {
        companyName: profile.business_name || profile.profile_name,  
        tonnesCO2: profile.co2,
        trees: profile.tree || 0,  
        plasticBottles: profile.plastic || 0,  
        currentDate: new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }),
        certificateId: profile.id // You can generate or fetch this dynamically if needed
      };
      setLoading(true);

      try {
        const result = await generateCertificate(certificateData).unwrap();
          // Redirect to the PDF URL or download it
          window.open(result, '_blank');
        setLoading(false);
        
    } catch (error) {
        console.error("Failed to generate certificate:", error);
        alert("An error occurred while generating the certificate.");
    }
    finally {
      setLoading(false); // Ensure loading is set to false in both success and error cases
    }
    } else {
      alert("To generate a certificate, you need to sequester at least 1,000 tons of CO2. In the meantime, explore your Impact Profile to see how you're contributing to a sustainable future!");
      navigate('/impact-profile');  // Redirect to the Impact Profile page
    }
  };
 
  return (
    <>
     
    <div className="space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {summaryItems.map((item, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: index * 0.1 }}
          >
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  {item.label}
                </CardTitle>
                <item.icon className={`h-4 w-4 ${item.color}`} />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{item.value}</div>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="flex justify-center"
      >
        <Button
          size="lg"
          className="w-full sm:w-auto"
          onClick={handleGenerateCertificate}
        >
          Generate Impact Certificate
        </Button>
      </motion.div>
    </div>
    {loading &&  (<>
        <div className="loader-container">
            <div className="spinner"></div>
            <p className="loading-text">Generating... Please wait.</p>
        </div>
      </>)}
    </>
  );
};

export default ImpactSummary;
