import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Home, User, ShoppingCart, QrCode, Trees, Package, FileDown, Newspaper,
  Globe, Mail, CreditCard, Users, BarChart
} from 'lucide-react';
import Partners from './Partners';
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Container, Section } from "./ui/layout";
import { Badge } from "./ui/badge";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

function ExploreDashboard() {
  const dashboardFeatures = [
    { icon: BarChart, title: "Impact Summary", description: "View your overall environmental impact at a glance" },
    { icon: Home, title: "Dashboard Overview", description: "Get a comprehensive view of your sustainability efforts" },
    { icon: User, title: "Impact Profile", description: "Showcase your environmental contributions and achievements" },
    { icon: ShoppingCart, title: "Impact Shop", description: "Purchase carbon credits to increase your positive impact" },
    { icon: QrCode, title: "QR Code", description: "Create QR codes for your business impact shop" },
    { icon: Trees, title: "Tree Counter Widget", description: "Display your tree-planting contributions on your website" },
    { icon: Package, title: "Order Management", description: "Track and manage your eco-friendly purchases" },
    { icon: FileDown, title: "Download Assets", description: "Access sustainability reports and certificates" },
    { icon: Newspaper, title: "Articles", description: "Stay informed with the latest sustainability blogs" },
    { icon: Globe, title: "Climate Projects", description: "Explore and support global climate initiatives" },
    { icon: Mail, title: "Contact Support", description: "Get in touch with our support team" },
    { icon: Users, title: "Collaborators", description: "Manage team members and their roles" },
  ];

  return (
    <Container className="bg-white">
      {/* Hero Section */}
      <Section className="py-20">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-12">
          <motion.div 
            className="lg:w-1/2"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
          >
            <Badge className="mb-4 px-3 py-1 text-sm font-semibold bg-blue-100 text-blue-800">Explore</Badge>
            <h1 className="text-4xl lg:text-5xl font-bold mb-6 text-gray-900">
              The Bayti Dashboard
            </h1>
            <p className="text-xl text-gray-700 mb-8">
              FREE TO USE: Track, manage, and amplify your environmental impact with our comprehensive sustainability platform.
            </p>
            <div className="flex gap-4">
              <Button size="lg" className="bg-blue-600 hover:bg-blue-700 text-white">
                <Link to="/auth-page">Get Started</Link>
              </Button>
              <Button size="lg" variant="outline" className="text-blue-600 border-blue-600 hover:bg-blue-50">
                <a href="https://tidycal.com/bakri/bayti-eco-intro-call" target="_blank" rel="noopener noreferrer">
                  Book a Demo
                </a>
              </Button>
            </div>
          </motion.div>
          <motion.div
            className="lg:w-1/2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src="/images/dashboardimage.jpg"
              alt="Bayti Dashboard"
              className="rounded-lg shadow-lg w-full"
            />
          </motion.div>
        </div>
      </Section>

      <Partners />

      {/* Enhanced Features Section */}
      <Section className="py-20 bg-gray-50 relative overflow-hidden">
        {/* Subtle background pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-indigo-500 transform -skew-y-12"></div>
        </div>
        <div className="relative z-10">
          <h2 className="text-4xl font-bold text-center mb-16 text-gray-900">
            Powerful Dashboard Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {dashboardFeatures.map((feature, index) => (
              <motion.div 
                key={index} 
                variants={fadeInUp} 
                initial="hidden" 
                animate="visible"
                transition={{ delay: index * 0.1 }}
              >
                <Card className="h-full border-none shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                  <CardHeader className="pb-4">
                    <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                      <feature.icon className="w-8 h-8 text-blue-600" />
                    </div>
                    <CardTitle className="text-xl font-bold text-gray-900">{feature.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600 leading-relaxed">{feature.description}</p>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </Section>

      {/* CTA Section */}
      <Section className="py-20 bg-blue-600 text-white text-center">
        <h2 className="text-3xl font-bold mb-6">Ready to Make a Difference?</h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto">
          Join thousands of individuals and businesses already using Bayti to create a positive impact on our planet.
        </p>
        <Button size="lg" variant="secondary" className="text-blue-600 hover:bg-blue-50">
          <Link to="/auth-page">Start Your Journey Now</Link>
        </Button>
      </Section>
    </Container>
  );
}

export default ExploreDashboard;