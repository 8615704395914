import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { 
  TreePine, Waves, Globe, CloudSun, Facebook, Linkedin, Twitter, 
  Download, MapPin, ChevronLeft, Share2, AlertTriangle, Award, Info
} from 'lucide-react';
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { Skeleton } from "./ui/skeleton";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Progress } from "./ui/progress";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const ImpactDisplay = ({ icon: Icon, value, label, sublabel, color, particleColor }) => {
  return (
    <Card className={`relative overflow-hidden hover:shadow-lg transition-shadow duration-300`}>
      <CardContent className="p-6">
        <Icon className={`h-8 w-8 mb-4 ${color}`} />
        <CountUp end={value} duration={2.5} separator="," className="text-4xl font-bold" />
        <h3 className="text-lg font-semibold mt-2">{label}</h3>
        <p className="text-sm opacity-70">{sublabel}</p>
      </CardContent>
    </Card>
  );
};

const EcoScoreDisplay = ({ score }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Card className="relative overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <CardContent className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Eco Score</h3>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="sm" onClick={() => setShowInfo(!showInfo)}>
                  <Info className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Your Eco Score is calculated based on your overall environmental impact.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex items-center justify-center">
          <Award className="text-yellow-500 h-16 w-16 mr-4" />
          <div>
            <p className="text-4xl font-bold">{score}</p>
            <Progress value={score} className="w-full mt-2" />
          </div>
        </div>
        <AnimatePresence>
          {showInfo && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="mt-4 text-sm text-gray-600"
            >
              <p>Your Eco Score is calculated based on your tree planting, plastic removal, and carbon offsetting activities. Keep up the good work to increase your score!</p>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
};

const UserImpactProfileComponent = ({ user }) => {
  const auth = useSelector((store) => store.auth);
  const loading = useSelector((state) => state.profile.loading);
  const error = useSelector((state) => state.profile.error);
  const profileRef = useRef(null);

  const shareUrl = encodeURIComponent(window.location.href);
  const shareText = encodeURIComponent(`Check out my environmental impact with Bayti Eco!`);

  const shareToFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
  };

  const shareToLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}`, '_blank');
  };

  const shareToTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}`, '_blank');
  };

  const downloadImage = () => {
    if (profileRef.current) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const element = profileRef.current;

      // Set canvas size to match the element
      canvas.width = element.offsetWidth;
      canvas.height = element.offsetHeight;

      // Draw the element content onto the canvas
      ctx.drawImage(element, 0, 0);

      // Convert canvas to image and trigger download
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'my-eco-impact.png';
        link.href = url;
        link.click();
        URL.revokeObjectURL(url);
      });
    }
  };

  if (loading) {
    return (
      <div className="max-w-6xl mx-auto p-6">
        <Skeleton className="h-64 w-full mb-8" />
        <div className="flex flex-wrap gap-8">
          <Skeleton className="h-36 w-36 rounded-full" />
          <div className="flex-1">
            <Skeleton className="h-8 w-3/4 mb-4" />
            <Skeleton className="h-4 w-1/2 mb-2" />
            <Skeleton className="h-4 w-1/3 mb-4" />
            <div className="grid grid-cols-2 gap-4">
              {[...Array(4)].map((_, i) => (
                <Skeleton key={i} className="h-32 w-full" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="max-w-6xl mx-auto mt-8">
        <AlertTriangle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          Failed to load user impact data. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  if (!user) {
    return (
      <Alert className="max-w-6xl mx-auto mt-8">
        <AlertTriangle className="h-4 w-4" />
        <AlertTitle>No User Data</AlertTitle>
        <AlertDescription>
          No user data is available. Please ensure you&apos;re logged in and have set up your profile.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div 
      ref={profileRef}
      className="max-w-6xl mx-auto p-6 bg-gradient-to-br from-gray-50 to-blue-50 rounded-2xl shadow-lg"
    >
      <header className="flex justify-between items-center mb-8">
        <img src="/images/BaytiEcoLogo.png" alt="Bayti Logo" className="h-20 rounded-lg transition-transform duration-300 hover:scale-105" />
        <Button asChild variant="outline">
          <Link to={auth.user ? "/dashboard" : "/"}>
            <ChevronLeft className="mr-2 h-4 w-4" /> 
            {auth.user ? "Back to Dashboard" : "Back to Home"}
          </Link>
        </Button>
      </header>

      <div 
        className="w-full h-64 bg-cover bg-center rounded-2xl mb-8 relative"
        style={{ backgroundImage: `url(${user.coverImage || '/default-cover.jpg'})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-40 rounded-2xl"></div>
      </div>

      <div className="flex flex-wrap gap-8">
        <aside className="w-full md:w-1/3 lg:w-1/4">
          <Avatar className="w-36 h-36 mb-4 border-4 border-white shadow-lg">
            <AvatarImage src={user.profileImage} alt={user.name} />
            <AvatarFallback>{user.name ? user.name[0] : 'U'}</AvatarFallback>
          </Avatar>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">{user.name || 'Anonymous User'}</h2>
          <p className="text-gray-600 mb-4">{user.bio || 'No bio available'}</p>
          {user.location && (
            <div className="mb-4">
              <p className="flex items-center text-gray-600 mb-1">
                <MapPin className="mr-2 h-4 w-4" /> {user.location}
              </p>
            </div>
          )}
          {user.website && (
            <a href={user.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              {user.website}
            </a>
          )}
          <div className="mt-6">
            <h3 className="font-semibold mb-2">Share Your Impact</h3>
            <div className="flex space-x-2">
              <Button onClick={shareToFacebook} variant="outline" size="icon" className="hover:bg-blue-100 transition-colors duration-200">
                <Facebook className="h-4 w-4" />
              </Button>
              <Button onClick={shareToLinkedIn} variant="outline" size="icon" className="hover:bg-blue-100 transition-colors duration-200">
                <Linkedin className="h-4 w-4" />
              </Button>
              <Button onClick={shareToTwitter} variant="outline" size="icon" className="hover:bg-blue-100 transition-colors duration-200">
                <Twitter className="h-4 w-4" />
              </Button>
              <Button onClick={downloadImage} variant="outline" size="icon" className="hover:bg-blue-100 transition-colors duration-200">
                <Download className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </aside>

        <main className="w-full md:w-2/3 lg:w-3/4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
            <ImpactDisplay
              icon={TreePine}
              value={user.treesPlanted}
              label="Planted certified trees"
              sublabel={`${user.forestAcres} acres of forest`}
              color="text-green-500"
              particleColor="#4caf50"
            />
            <ImpactDisplay
              icon={Waves}
              value={user.bottlesRemoved}
              label="Removed ocean plastic"
              sublabel={`${user.plasticWeight} kg`}
              color="text-blue-500"
              particleColor="#0496C7"
            />
            <ImpactDisplay
              icon={Globe}
              value={user.ProjFunded}
              label="Funded climate projects"
              sublabel="Tons of CO2 offset"
              color="text-red-500"
              particleColor="#BA3324"
            />
            <ImpactDisplay
              icon={CloudSun}
              value={user.co2Countered}
              label="Countered carbon emissions"
              sublabel={`${user.co2Balloons} hot-air balloons of CO2`}
              color="text-yellow-500"
              particleColor="#DAA520"
            />
          </div>

          <EcoScoreDisplay score={user.ecoScore || 75} />

          <Button onClick={downloadImage} size="lg" className="w-full mt-8 bg-green-500 hover:bg-green-600 text-white">
            <Share2 className="mr-2 h-5 w-5" /> Share Your Impact
          </Button>
        </main>
      </div>
    </div>
  );
};

export default UserImpactProfileComponent;