import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetOrdersByBusinessIdQuery, useUpdateOrderStatusMutation } from '../app/features/package/packageApi';
import { getAllOrdersByBusiness } from '../app/features/package/packageSlice';

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import { Badge } from "../components/ui/badge";
import { motion } from 'framer-motion';
import { ExternalLink, Upload, ChevronDown, ChevronUp } from 'lucide-react';
import HeaderDash from '../components/HeaderDash';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { toast } from 'react-toastify';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../components/ui/dropdown-menu";

const BusinessOwnerBadge = ({ name }) => {
  const colors = [
    'bg-blue-100 text-blue-800 hover:bg-blue-200',
    'bg-green-100 text-green-800 hover:bg-green-200',
    'bg-purple-100 text-purple-800 hover:bg-purple-200',
    'bg-pink-100 text-pink-800 hover:bg-pink-200',
    'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
    'bg-indigo-100 text-indigo-800 hover:bg-indigo-200',
    'bg-red-100 text-red-800 hover:bg-red-200',
    'bg-teal-100 text-teal-800 hover:bg-teal-200'
  ];

  const getColorForName = (name) => {
    if (!name) return colors[0];
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  };

  const colorClass = getColorForName(name);

  return (
    <Badge className={`${colorClass} font-semibold px-3 py-1 rounded-full transition-colors duration-200`}>
      {name || 'Bayti Eco'}
    </Badge>
  );
};

const StatusBadge = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Confirmed':
        return "bg-green-100 text-green-800";
      case 'Cancelled':
        return "bg-red-100 text-red-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <Badge className={`${getStatusStyle(status)} font-semibold px-3 py-1 rounded-full`}>
      {status}
    </Badge>
  );
};

const BusinessOrder = () => {
  const dispatch = useDispatch();
  const businessProfileId = useSelector((state) => state.profile.profile?.id);
  const { data, error: getOrdersError, isLoading: isFetching } = useGetOrdersByBusinessIdQuery(businessProfileId);
  const orders = useSelector((state) => state.package.businessOrders);

  useEffect(() => {
    if (data) {
      dispatch(getAllOrdersByBusiness(data));
    }
  }, [data, dispatch]);

  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const { refetch } = useGetOrdersByBusinessIdQuery(businessProfileId);
  const [expandedRows, setExpandedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  console.log(data);

  const handleUpdateStatus = useCallback(async (newStatus, orderId) => {
    try {
      await updateOrderStatus({ orderId, newStatus }).unwrap();
      toast.success('Order status updated successfully!');
      refetch(); // Refetch the orders after successful update
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error(`Failed to update order status: ${error.message || 'Unknown error'}`);
    }
  }, [updateOrderStatus, refetch]);

  const columns = useMemo(() => [
    {
      accessorKey: "id",
      header: "Order ID",
      cell: info => info.getValue(),
    },
    {
      accessorKey: "profiles.profile_name",
      header: "Customer Name",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "total_items",
      header: "Total Items",
      cell: ({ row }) => <BusinessOwnerBadge name={row.getValue("total_items")} />,
    },
    {
      accessorKey: "total_price",
      header: "Total",
      cell: ({ row }) => `$${row.getValue("total_price").toFixed(2)}`,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <StatusBadge status={row.getValue("status")} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {["Confirmed", "Completed", "Cancelled"].map((status) => (
              <DropdownMenuItem
                key={status}
                onClick={() => handleUpdateStatus(status, row.original.id)}
                disabled={updateOrderStatus.isLoading}
              >
                {status}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
    {
      accessorKey: "created_at",
      header: "Date Created",
      cell: ({ row }) => new Date(row.getValue("created_at")).toLocaleString(),
    },
    {
      accessorKey: "total_trees_planted",
      header: "Trees Planted",
    },
    {
      accessorKey: "total_co2_offset",
      header: "CO2 Offset",
      cell: ({ row }) => `${row.getValue("total_co2_offset")} kg`,
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => toggleRowExpansion(row.original.id)}
        >
          {expandedRows[row.original.id] ? <ChevronUp /> : <ChevronDown />}
        </Button>
      ),
    },
  ], [updateOrderStatus.isLoading, expandedRows, handleUpdateStatus]);

  const table = useReactTable({
    data: orders || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const toggleRowExpansion = (orderId) => {
    setExpandedRows(prev => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const renderOrderItems = (items) => (
    <div className="bg-blue-50 p-4 rounded-lg shadow-inner">
      <Table>
        <TableHeader>
          <TableRow className="bg-blue-200">
            <TableHead className="font-semibold text-blue-800">Item</TableHead>
            <TableHead className="font-semibold text-blue-800">Quantity</TableHead>
            <TableHead className="font-semibold text-blue-800">Price</TableHead>
            <TableHead className="font-semibold text-blue-800">Total</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} className="bg-blue-100 hover:bg-blue-200 transition-colors">
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>${item.price.toFixed(2)}</TableCell>
              <TableCell>${(item.quantity * item.price).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <motion.div
      className="min-h-screen bg-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeaderDash />
      <main className="container mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>Business Order List</CardTitle>
            <div className="flex items-center justify-between">
              <Input
                placeholder="Search all columns..."
                value={globalFilter ?? ''}
                onChange={e => setGlobalFilter(String(e.target.value))}
                className="max-w-sm"
              />
              <Select
                value={table.getState().pagination.pageSize}
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select rows per page" />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            {isFetching ? (
              <div>Loading orders...</div>
            ) : getOrdersError ? (
              <div>Error loading orders: {getOrdersError.message}</div>
            ) : (
              <>
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map(headerGroup => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <TableHead key={header.id}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableRow>
                          {row.getVisibleCells().map(cell => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        {expandedRows[row.original.id] && (
                          <TableRow>
                            <TableCell colSpan={columns.length} className="p-0">
                              <div className="py-4 px-2">
                                {renderOrderItems(row.original.order_items)}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
                <div className="flex items-center justify-between space-x-2 py-4">
                  <div>
                    Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, table.getFilteredRowModel().rows.length)} of {table.getFilteredRowModel().rows.length} results
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </main>
    </motion.div>
  );
};

export default BusinessOrder;