import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Facebook, Twitter, Linkedin, Instagram, Mail, Phone, ArrowRight, ExternalLink, Music4 } from 'lucide-react';
// Add TikTok icon import
import { Container } from "./ui/layout";
import { Button } from "./ui/button";
import { Separator } from "./ui/separator";
import { Input } from "./ui/input";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const FooterLink = ({ to, children }) => (
  <Link to={to} className="text-gray-600 hover:text-blue-600 transition-all duration-300 flex items-center group">
    <ArrowRight className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-all duration-300" />
    <span className="text-sm">{children}</span>
  </Link>
);

const SocialLink = ({ href, icon: Icon }) => (
  <motion.a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer"
    aria-label={href} 
    className="text-gray-600 hover:text-blue-600 transition-all duration-300"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon size={20} />
  </motion.a>
);

function HomeFooter() {
  return (
    <footer className="bg-gray-50 text-gray-800">
      <Container className="py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8">
          <motion.div 
            className="lg:col-span-2"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <img src="/images/BaytiEcoLogo.png" alt="Bayti Eco Logo" className="w-32 h-auto mb-4" />
            <p className="text-sm text-gray-600 mb-4">Empowering sustainable NOW through innovative solutions.</p>
            <div className="flex items-center mb-4">
              <Mail size={16} className="mr-2 text-blue-600" />
              <span className="text-sm">info@bayti.eco</span>
            </div>
            <div className="flex items-center mb-6">
              <Phone size={16} className="mr-2 text-blue-600" />
              <span className="text-sm">+971 (52) 244-4896</span>
            </div>
            <div className="flex space-x-4">
              <SocialLink href="https://twitter.com/Baytieco" icon={Twitter} />
              <SocialLink href="https://www.linkedin.com/company/Baytieco" icon={Linkedin} />
              <SocialLink href="https://www.instagram.com/Baytieco" icon={Instagram} />
              <SocialLink href="https://www.tiktok.com/@Baytieco" icon={Music4} />
            </div>
          </motion.div>
          
          <motion.div variants={fadeInUp} initial="hidden" animate="visible">
            <h3 className="text-lg font-semibold text-blue-600 mb-4">Business</h3>
            <div className="space-y-2">
              <FooterLink to="/bayti-teams">BaytiTeams</FooterLink>
              <FooterLink to="/eco-business">Eco-Business</FooterLink>
              <FooterLink to="/carbon-calculator">Carbon Calculator</FooterLink>
              <FooterLink to="/explore-dashboard">Dashboard</FooterLink>
            </div>
          </motion.div>
          
          <motion.div variants={fadeInUp} initial="hidden" animate="visible">
            <h3 className="text-lg font-semibold text-blue-600 mb-4">Personal</h3>
            <div className="space-y-2">
              <FooterLink to="/my-bayti">MyBayti</FooterLink>
              <FooterLink to="/explore-dashboard">Dashboard</FooterLink>
            </div>
          </motion.div>
          
          <motion.div variants={fadeInUp} initial="hidden" animate="visible">
            <h3 className="text-lg font-semibold text-blue-600 mb-4">Resources</h3>
            <div className="space-y-2">
              <FooterLink to="/blog">Blog</FooterLink>
              <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
              <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
            </div>
          </motion.div>
          
          <motion.div variants={fadeInUp} initial="hidden" animate="visible">
            <h3 className="text-lg font-semibold text-blue-600 mb-4">Company</h3>
            <div className="space-y-2">
              <FooterLink to="/about-us">About Us</FooterLink>
              <FooterLink to="/careers">Careers</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
            </div>
          </motion.div>
        </div>
        
        <Separator className="my-8" />
        
        <div className="flex flex-col md:flex-row justify-between items-center">
          <motion.div variants={fadeInUp} initial="hidden" animate="visible" className="mb-4 md:mb-0">
            <a href="https://sdgs.un.org/goals" target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-blue-600 transition-all duration-300">
              <img src="/images/sdgoals.png" alt="UN SDG Goals" className="w-24 h-auto mr-2" />
              <span className="text-sm">UN Sustainable Development Goals</span>
              <ExternalLink size={14} className="ml-1" />
            </a>
          </motion.div>
          <motion.div variants={fadeInUp} initial="hidden" animate="visible" className="flex items-center">
            <span className="text-sm mr-2">Stay updated:</span>
            <div className="flex">
              <Input type="email" placeholder="Enter your email" className="rounded-r-none" />
              <Button type="submit" className="rounded-l-none">Subscribe</Button>
            </div>
          </motion.div>
        </div>
      </Container>
      <div className="bg-gray-100 py-4">
        <Container>
          <p className="text-center text-sm text-gray-600">
            © {new Date().getFullYear()} Bayti. All rights reserved. | Sustainable Solutions for a Greener NOW
          </p>
        </Container>
      </div>
    </footer>
  );
}

export default HomeFooter;