import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import TextEditor from '../components/TextEditor'; // Ensure this path is correct
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { slugify } from '../lib/utils/slugify';
import { toast } from '../components/ui/use-toast';
import HeaderDash from '../components/HeaderDash';
import { useCreateBlogMutation,useGetAllCategoriesQuery, useCreateCategoryMutation, useGetAllAuthorsQuery, useCreateAuthorMutation } from '../app/features/blog/blogApi';
import { Container } from '../components/ui/layout';
import { useForm } from "react-hook-form";
import { Button } from '../components/ui/button';
import { supabase } from '../app/supabase';
import { useNavigate } from 'react-router-dom';

const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

const AdminBlog = () => {
    const navigate = useNavigate(); 
    const [title, setTitle] = useState('');
    const [content, setContent] = useState();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedAuthor, setSelectedAuthor] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [newAuthor, setNewAuthor] = useState('');
    const [newTag, setNewTag] = useState('');
    const [tags, setTags] = useState([]);
    const [slug, setSlug] = useState('');
    const [featuredImage, setFeaturedImage] = useState(null);
    const { data: categories } = useGetAllCategoriesQuery();
    const { data: authors } = useGetAllAuthorsQuery();
    const { register, handleSubmit, reset,setValue, formState: { errors } } = useForm();
    const [createBlog] = useCreateBlogMutation();
    const [createCategory] = useCreateCategoryMutation();
    const [createAuthor] = useCreateAuthorMutation();
    const handleContentChange = (value) => {
        setContent(value);
        setValue("content", value);
    };

    const checkSlugExists = async (slug) => {
        const { data, error } = await supabase
            .from('blogs')
            .select('slug')
            .eq('slug', slug)
            .single();
            
        if (error) {
            return false; 
        }
        return !!data; 
    };
 
     
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFeaturedImage(file);
        }
    };

    const onSubmit = async (data) => {
        const slugExists = await checkSlugExists(slug);
        if (slugExists) {
            toast({
                title: "Slug Exists",
                description: "This slug is already taken. Please modify the title or slug.",
                variant: "destructive",
                duration: 3000,
            });
            return;
        }
        try {
            let featuredImageUrl = null;

            // Upload the featured image if it exists
            if (featuredImage) {
                const { data: uploadData, error: uploadError } = await supabase.storage
                    .from('images') // Replace with your bucket name
                    .upload(`blogs/featured/${featuredImage.name}`, featuredImage);

                if (uploadError) {
                    throw new Error(uploadError.message || 'Error uploading image');
                }

                // Construct the public URL for the uploaded image
                featuredImageUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/images/blogs/featured/${featuredImage.name}`;
            }

            // Prepare the blog data
            const blogData = {
                title: data.title,
                excerpt: data.excerpt,
                content,
                category_id: selectedCategory,
                author_id: selectedAuthor,
                slug,
                tags: tags, // Assuming tags are part of the form
                featured_image: featuredImageUrl, // Add the image URL to the blog data
            };
            await createBlog(blogData).unwrap();
            toast({
                title: "Blog Created",
                description: "Blog post created successfully.",
                duration: 3000,
              });
 
            setContent('')
            setSelectedAuthor('')
            setSelectedCategory('')
            setNewCategory('')
            setNewAuthor('')
            setNewTag('')
            setSlug([])
            navigate('/admin/blogs');
            reset();
            setFeaturedImage(null);
        } catch (error) {
            toast({
                title: "Blog Create Failed",
                description: "There was an error creating blog. Please try again.",
                variant: "destructive",
                duration: 3000,
              });
        }

    };

    const handleCategorySubmit = async () => {
        const categorySlug = slugify(newCategory);
        await createCategory({ category_name: newCategory, category_slug: categorySlug });
        setNewCategory('');
    };

    const handleAuthorSubmit = async () => {
        const authorSlug = slugify(newAuthor);
        await createAuthor({ author_name: newAuthor, author_slug: authorSlug });
        setNewAuthor('');
    };

    const handleTagSubmit = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        setSlug(slugify(newTitle)); 
    };

    return (
        <motion.div
            className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100"
            initial="hidden"
            animate="visible"
            variants={fadeInUp}
            >
            <HeaderDash />
            <Container className="py-8">
                <main className="space-y-8 flex">
                    <div className="w-4/5 pr-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label>Title</label>
                                <Input {...register("title", { required: "Title is required" })} onChange={handleTitleChange} />
                                {errors.title && <p className="text-sm text-red-500">{errors.title.message}</p>}
                            </div>
                            <div className="mb-4">
                                <label>Slug (Permalink)</label>
                                <Input value={slug} readOnly />
                            </div>
                            <div className="mb-4">
                                <label>Content</label>
                                <TextEditor data={content} onTextChange={handleContentChange} />
                            </div>
                            <div className="mb-4">
                                <label>Excerpt</label>
                                <Input {...register("excerpt", { required: "Excerpt is required" })} />
                                {errors.excerpt && <p className="text-sm text-red-500">{errors.excerpt.message}</p>}
                            </div>
                            <Button 
                                type="submit" 
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200" // Updated button styles
                            >
                                Publish
                            </Button>
                        </form>
                    </div>
                    <div className="w-1/5 pl-4"> {/* Sidebar */}
                        <Card className="mb-1 px-2 py-1"> {/* Decreased margin and padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Create Category</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                                <Input
                                    placeholder="New Category Name"
                                    value={newCategory}
                                    onChange={e => setNewCategory(e.target.value)}
                                    className="mb-1 text-sm" // Decreased margin
                                />
                                <button 
                                    onClick={handleCategorySubmit} 
                                    className="border border-blue-600 text-blue-600 py-1 px-2 rounded hover:bg-blue-600 hover:text-white transition duration-200" // Updated button styles
                                >
                                    Add Category
                                </button>
                            </CardContent>
                        </Card>
                        <Card className="mb-1 px-2 py-1"> {/* Decreased margin and padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Select Category</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                                <Select value={selectedCategory} onValueChange={setSelectedCategory}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Category" className="text-sm" /> {/* Decreased font size */}
                                    </SelectTrigger>
                                    <SelectContent>
                                        {categories?.map(category => (
                                            <SelectItem key={category.id} value={category.id} className="text-sm"> {/* Decreased font size */}
                                                {category.category_name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </CardContent>
                        </Card>
                        <Card className="mb-2 px-2"> {/* Adjusted padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Create Author</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                                <Input
                                    placeholder="New Author Name"
                                    value={newAuthor}
                                    onChange={e => setNewAuthor(e.target.value)}
                                    className="mb-2 text-sm" // Decreased font size
                                />
                                <button 
                                    onClick={handleAuthorSubmit} 
                                    className="border border-blue-600 text-blue-600 py-1 px-2 rounded hover:bg-blue-600 hover:text-white transition duration-200" // Updated button styles
                                >
                                    Add Author
                                </button>
                            </CardContent>
                        </Card>
                        <Card className="mb-2 px-2"> {/* Adjusted padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Select Author</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                                <Select value={selectedAuthor} onValueChange={setSelectedAuthor}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Author" className="text-sm" /> {/* Decreased font size */}
                                    </SelectTrigger>
                                    <SelectContent>
                                        {authors?.map(author => (
                                            <SelectItem key={author.id} value={author.id} className="text-sm"> {/* Decreased font size */}
                                                {author.author_name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </CardContent>
                        </Card>
                        <Card className="mb-2 px-2"> {/* Adjusted padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Tags</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                                <div className="flex mb-2">
                                    <Input
                                        value={newTag}
                                        onChange={e => setNewTag(e.target.value)}
                                        placeholder="Add a tag"
                                        className="flex-grow text-sm" // Decreased font size
                                    />
                                    <button 
                                        type="button" 
                                        onClick={handleTagSubmit} 
                                        className="border border-blue-600 text-blue-600 py-1 px-2 rounded hover:bg-blue-600 hover:text-white transition duration-200 ml-2" // Updated button styles
                                    >
                                        Add
                                    </button>
                                </div>
                                <div className="flex flex-wrap">
                                    {tags.map((tag, index) => (
                                        <span key={index} className="bg-gray-200 rounded-full px-2 py-1 mr-2 mb-2 flex items-center">
                                            {tag}
                                            <button 
                                                onClick={() => setTags(tags.filter((t) => t !== tag))} 
                                                className="ml-1 text-red-500 hover:text-red-700" // Remove button styles
                                            >
                                                &times; {/* Remove icon */}
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="mb-2 px-2"> {/* Adjusted padding */}
                            <CardHeader>
                                <CardTitle className="text-sm">Featured Image</CardTitle> {/* Decreased font size */}
                            </CardHeader>
                            <CardContent>
                            <Input type="file" onChange={handleImageUpload} />
                            {featuredImage && (
                                <img src={URL.createObjectURL(featuredImage)} alt="Preview" className="mt-2 w-32 h-32 object-cover rounded" />
                            )}
                            </CardContent>
                        </Card>
                    </div>
                </main>
            </Container>
            
        </motion.div>
    );
};

export default AdminBlog;