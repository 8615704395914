import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailSent: false,
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setEmailSent: (state, action) => {
      state.emailSent = action.payload;
    },
    resetEmailState: (state) => {
      state.emailSent = false;
    },
  },
});

export const { setEmailSent, resetEmailState } = mailSlice.actions;
export default mailSlice.reducer;