import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Info, Download, ShoppingBag, Users, Leaf, Eye, EyeOff } from 'lucide-react';
import QRCode from 'qrcode.react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Avatar, AvatarImage, AvatarFallback } from "./ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { useDispatch, useSelector } from 'react-redux';
import { useGetProfileQuery } from '../app/features/profile/profileApi';
import { useNavigate } from 'react-router-dom';

const QrCodeGen = ({ businessName = "Your Business", profileImage, webpageLink = "#" }) => {
  const user_id = useSelector((store) => store.auth.user.id);
  const navigate = useNavigate();
  const [qrColor, setQrColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [showCustomization, setShowCustomization] = useState(false);
  const { data: profileData, isLoading: isProfileLoading, refetch } = useGetProfileQuery({ user_id });
  const profile = useSelector((state) => state.profile.profile);
  if (profile.profile_type !== 'Business') {
    return (
      <div className="p-6 bg-gray-50">
        <Card>
          <CardHeader>
            <CardTitle>Business Profile Required</CardTitle>
            <CardDescription>
              You need a business profile to generate a QR code for your impact shop.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="mb-4">Your current profile type is not set to business. To create a QR code for your impact shop, you need to change your profile type to business.</p>
            <Button onClick={() => navigate('/impact-profile')}>
              Update Profile Type
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
  const generateQRCodeUrl = () => {
    return `https://www.bayti.eco/business-impact-shop?name=${encodeURIComponent(profile.business_name)}&profile=${encodeURIComponent(profile.webpage_link)}`;
  };
  
  const downloadQRCode = () => {
    const canvas = document.getElementById('qr-code');
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${businessName}-qr-code.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className="p-6 bg-gray-50">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Your Business QR Code</CardTitle>
          <CardDescription>
            This QR code allows customers to easily access your carbon offset purchasing page.
          </CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col items-center space-y-4">
        <Avatar className="w-24 h-24">
            <AvatarImage src={profile.business_logo || '/path/to/default/image.png'} alt={profile.business_name} />
            <AvatarFallback>{profile.business_name.charAt(0)}</AvatarFallback>
          </Avatar>
          <h3 className="text-xl font-semibold">{profile.business_name}</h3>
          <div className="bg-white p-4 rounded-lg shadow">
            <QRCode
              id="qr-code"
              value={generateQRCodeUrl()}
              size={250}
              level={"H"}
              includeMargin={true}
              fgColor={qrColor}
              bgColor={bgColor}
              imageSettings={{
                src: profileImage || '/path/to/default/image.png',
                x: undefined,
                y: undefined,
                height: 40,
                width: 40,
                excavate: true,
              }}
            />
          </div>
          <div className="flex space-x-2">
            <Button onClick={downloadQRCode} className="w-full sm:w-auto">
              <Download className="mr-2 h-4 w-4" /> Download QR Code
            </Button>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    onClick={() => setShowCustomization(!showCustomization)}
                  >
                    {showCustomization ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{showCustomization ? 'Hide' : 'Show'} Customization Options</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          {showCustomization && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="w-full space-y-2"
            >
              <div className="flex items-center space-x-2">
                <label htmlFor="qrColor" className="text-sm font-medium">QR Color:</label>
                <input
                  type="color"
                  id="qrColor"
                  value={qrColor}
                  onChange={(e) => setQrColor(e.target.value)}
                  className="w-8 h-8 border-none"
                />
              </div>
              <div className="flex items-center space-x-2">
                <label htmlFor="bgColor" className="text-sm font-medium">Background Color:</label>
                <input
                  type="color"
                  id="bgColor"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  className="w-8 h-8 border-none"
                />
              </div>
            </motion.div>
          )}
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>About Your Business Impact Shop QR Code</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="overview">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="usage">Usage</TabsTrigger>
              <TabsTrigger value="benefits">Benefits</TabsTrigger>
            </TabsList>
            <TabsContent value="overview">
              <p className="text-gray-700 mb-4">
                Your Business Impact Shop QR Code is a powerful tool that connects your customers directly to your personalized carbon offset purchasing page. It&apos;s designed to make sustainability accessible and engaging for your audience.
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li className="flex items-start">
                  <ShoppingBag className="mr-2 h-5 w-5 text-blue-500 flex-shrink-0 mt-1" />
                  <span>
                    <strong>Instant Access:</strong> Customers can scan and immediately start offsetting their carbon footprint.
                  </span>
                </li>
                <li className="flex items-start">
                  <Users className="mr-2 h-5 w-5 text-green-500 flex-shrink-0 mt-1" />
                  <span>
                    <strong>On-the-Go Offsetting:</strong> Perfect for in-store, events, or any customer interaction point.
                  </span>
                </li>
                <li className="flex items-start">
                  <Leaf className="mr-2 h-5 w-5 text-yellow-500 flex-shrink-0 mt-1" />
                  <span>
                    <strong>Showcase Commitment:</strong> Demonstrate your business&apos;s dedication to sustainability.
                  </span>
                </li>
              </ul>
            </TabsContent>
            <TabsContent value="usage">
              <p className="text-gray-700 mb-4">
                Maximize the impact of your Business Impact Shop QR Code by strategically placing it where your customers can easily access it:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Display prominently in physical store locations</li>
                <li>Include on product packaging or tags</li>
                <li>Feature at events and conferences</li>
                <li>Add to digital communications like emails or newsletters</li>
                <li>Incorporate into marketing materials and advertisements</li>
              </ul>
            </TabsContent>
            <TabsContent value="benefits">
              <p className="text-gray-700 mb-4">
                By implementing the Business Impact Shop QR Code, your business can:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Increase customer engagement in sustainability efforts</li>
                <li>Boost your brand&apos;s eco-friendly reputation</li>
                <li>Provide a seamless way for customers to offset their carbon footprint</li>
                <li>Track and showcase your collective impact on the environment</li>
                <li>Differentiate your business in a competitive market</li>
              </ul>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Alert>
        <Info className="h-4 w-4" />
        <AlertTitle>Tip</AlertTitle>
        <AlertDescription>
          Share this QR code with your customers to let them access your personalized impact shop.
          <a href="#" className="text-blue-600 hover:underline ml-1">
            Learn more about QR code usage and best practices
          </a>
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default QrCodeGen;