import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLoggedOut } from '../app/features/auth/authSlice';
import {
  Home, User, ShoppingCart, QrCode, Trees, Users, FileDown, Newspaper,
  Globe, Mail, CreditCard, LogOut, Menu, Package, X, ChevronLeft, ChevronRight
} from 'lucide-react';
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { 
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { List } from '@radix-ui/react-tabs';

const AppTour = ({ steps, isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updateModalPosition = () => {
      if (isOpen && steps[currentStep]?.target) {
        const targetElement = document.querySelector(steps[currentStep].target);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const scrollY = window.scrollY;
          const scrollX = window.scrollX;

          let top = rect.bottom + scrollY + 10;
          let left = rect.left + scrollX;

          if (window.innerWidth < 768) {
            top = rect.bottom + scrollY + 10;
            left = 10;
          } else {
            if (left + 300 > window.innerWidth) {
              left = window.innerWidth - 310;
            }
            if (top + 200 > window.innerHeight) {
              top = rect.top + scrollY - 210;
            }
          }

          setModalPosition({ top: Math.max(top, 10), left: Math.max(left, 10) });
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };

    updateModalPosition();

    const handleResize = () => {
      updateModalPosition();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, currentStep, steps]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-[100] overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="absolute w-full sm:w-auto"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: 1,
              scale: 1,
              top: modalPosition.top,
              left: modalPosition.left,
            }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <Card className="w-full sm:w-[300px] max-w-[90vw]">
              <CardHeader className="p-4">
                <CardTitle className="flex justify-between items-center text-lg">
                  Step {currentStep + 1} of {steps.length}
                  <Button variant="ghost" size="icon" onClick={onClose} className="h-8 w-8">
                    <X className="h-4 w-4" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent className="p-4">
                <p className="text-sm">{steps[currentStep].content}</p>
              </CardContent>
              <CardFooter className="flex justify-between p-4">
                <Button 
                  onClick={handlePrev} 
                  disabled={currentStep === 0} 
                  variant="outline"
                  className="h-8 px-2 text-xs"
                >
                  <ChevronLeft className="h-4 w-4 mr-1" />
                  Previous
                </Button>
                <Button 
                  onClick={handleNext}
                  className="h-8 px-2 text-xs"
                >
                  {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
                  {currentStep !== steps.length - 1 && <ChevronRight className="h-4 w-4 ml-1" />}
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Sidebar = ({ children }) => {
  const user = useSelector((store) => store.auth.user);
  const profileData = useSelector((store) => store.profile.profile);
  
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    console.log('Profile data from state:', profileData);
  }, [profileData]);

  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLinkClick = (path) => {
    navigate(path);
    if (isMobile) setIsMobile(false);
  };

  const handleSignOut = () => {
    dispatch(userLoggedOut());
    sessionStorage.clear();
    navigate('/auth-page');
  };

  const NavLink = ({ to, icon: Icon, children, className }) => (
    <Button
      variant={location.pathname === to ? "secondary" : "ghost"}
      className={`w-full justify-start ${className}`}
      onClick={() => handleLinkClick(to)}
    >
      <Icon className="mr-2 h-4 w-4" />
      {children}
    </Button>
  );

  const NavSection = ({ title, children }) => (
    <div className="mb-4">
      {title && <h3 className="mb-2 px-4 text-lg font-semibold tracking-tight">{title}</h3>}
      <div className="space-y-1">{children}</div>
    </div>
  );

  const SidebarContent = () => (
    <ScrollArea className="h-full">
      <div className="p-4">
        <NavSection>
          <NavLink to="/dashboard" icon={Home} className="dashboard-link">Home</NavLink>
          {!profileData?.is_super_admin && (
            <NavLink to="/impact-profile" icon={User} className="impact-profile">Impact Profile</NavLink>
          )}
        </NavSection>

        {profileData?.is_super_admin && (
          <NavSection title="Admin">
            {/* <NavLink to="/admin-plans" icon={Users}>Plan List</NavLink> */}
            <NavLink to="/add-blog" icon={Users}>Add Blog</NavLink>
            <NavLink to="/admin/blogs" icon={Users}>Blog List</NavLink>
            <NavLink to="/admin/users" icon={Users}>Users List</NavLink>
            <NavLink to="/subscribed-plans" icon={Users}>Subscribed Plans</NavLink>
            {/* <NavLink to="/admin-packages" icon={Package}>Package List</NavLink> */}
            <NavLink to="/package-orders" icon={Package}>Package Orders</NavLink>
            <NavLink to="/contact-list" icon={Mail}>Contact List</NavLink>
          </NavSection>
        )}

        <NavSection title="Solutions">
          <NavLink to="/impact-shop" icon={ShoppingCart} className="impact-shop">Impact Shop</NavLink>
          <NavLink to="/qr-code-gen" icon={QrCode} className="qr-code-gen">QR Code Generator</NavLink>
          <NavLink to="/tree-counter-widget" icon={Trees} className="tree-counter">Tree Count Widget</NavLink>
          {!profileData?.is_super_admin && (
            <>
              <NavLink to="/my-orders" icon={Package}>My Orders</NavLink>
              <NavLink to="/the-bayti" icon={Users} className="bayti-subscription">Bayti Subscription</NavLink>
              {profileData?.profile_type === 'Business' && (
                <NavLink to="/business-orders" icon={Package}>Business Orders</NavLink>
              )}
            </>
          )}
        </NavSection>

        <NavSection title="Resources">
          <NavLink to="/download-assets" icon={FileDown}>Download Assets</NavLink>
          <NavLink to="/blog" icon={Newspaper}>Articles</NavLink>
          <NavLink to="/carbon-marketplace" icon={Globe}>Climate Projects</NavLink>
          <NavLink to="/contact" icon={Mail}>Contact</NavLink>
        </NavSection>

        <NavSection title="Settings">
          <NavLink to="/payment-method" icon={CreditCard}>Payment Method</NavLink>
          <NavLink to="/collaborators" icon={Users}>Collaborators</NavLink>
        </NavSection>

        <Button variant="destructive" className="w-full mt-4" onClick={handleSignOut}>
          <LogOut className="mr-2 h-4 w-4" /> Sign Out
        </Button>
      </div>
    </ScrollArea>
  );

  const tourSteps = [
    {
      target: '.dashboard-link',
      content: 'This is your home dashboard. Get an overview of your impact here.',
    },
    {
      target: '.impact-profile',
      content: 'View and manage your Impact Profile. See your contributions and achievements.',
    },
    {
      target: '.impact-shop',
      content: 'Visit our Impact Shop to make eco-friendly purchases and increase your positive impact.',
    },
    {
      target: '.qr-code-gen',
      content: 'Generate QR codes to easily share your impact and initiatives.',
    },
    {
      target: '.tree-counter',
      content: 'Use our Tree Count Widget to display your tree-planting contributions.',
    },
    {
      target: '.bayti-subscription',
      content: 'Manage your Bayti Subscription here.',
    },
  ];

  return (
    <div className="flex">
      <Sheet open={isMobile} onOpenChange={setIsMobile}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="fixed top-4 left-4 z-50 md:hidden">
            <Menu className="h-4 w-4" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-[250px] sm:w-[300px] p-0">
          <SheetHeader>
            <SheetTitle className="p-4">Menu</SheetTitle>
          </SheetHeader>
          <SidebarContent />
        </SheetContent>
      </Sheet>

      {!isMobile && (
        <aside className="hidden md:flex md:w-[250px] md:flex-col md:fixed md:inset-y-0">
          <SidebarContent />
        </aside>
      )}

      <main className="flex-1 p-0 md:p-6 md:ml-[250px]">
        {children}
      </main>

      <AppTour
        steps={tourSteps}
        isOpen={isTourOpen}
        onClose={() => setIsTourOpen(false)}
      />
    </div>
  );
};

export default Sidebar;