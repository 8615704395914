import React from 'react';
import { cn } from "../../lib/utils";

export function Container({ className, ...props }) {
  return (
    <div
      className={cn(
        "mx-auto w-full max-w-[1600px] px-4 sm:px-6 lg:px-8 py-3 sm:py-4 border border-gray-200 shadow-lg rounded-md sm:rounded-lg", // Adjusted padding and border for mobile
        className
      )}
      {...props}
    />
  );
}

export function Section({ className, ...props }) {
  return (
    <section
      className={cn(
        "py-12 md:py-16 lg:py-20 px-4 sm:px-6 lg:px-8 border-t border-gray-300 shadow-sm rounded-md sm:rounded-lg", // Adjusted padding, border, and rounding for mobile
        className
      )}
      {...props}
    />
  );
}
