import { configureStore } from "@reduxjs/toolkit";
import {apiSlice} from "./apiSlice";
import authSliceReducer from "./features/auth/authSlice";
import profileSliceReducer from "./features/profile/profileSlice";
import planSliceReducer from "./features/plan/planSlice";
import packageSliceReducer from "./features/package/packageSlice";
import clearOnLogout from "./middleware/clearOnLogout";
import cartSliceReducer from "./features/cart/cartSlice";
import paymentSliceReducer from "./features/payment/paymentSlice";
import mailSliceReducer from "./features/mail/mailSlice";
import blogSliceReducer from "./features/blog/blogSlice";
  
export const store = configureStore({
    reducer:{
         [apiSlice.reducerPath] : apiSlice.reducer,
         auth:authSliceReducer,
         profile:profileSliceReducer,
         plan:planSliceReducer,
         package:packageSliceReducer,
         cart:cartSliceReducer,
         payment:paymentSliceReducer,
         mail:mailSliceReducer,
         blog:blogSliceReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddlewares) =>
        getDefaultMiddlewares()
        .concat(apiSlice.middleware)
        .concat(clearOnLogout),
})