import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { profileApi } from "../profile/profileApi";
import { getAllPlans, getSubscribedPlans, getAllPlanOrders } from "./planSlice";
import { paymentApi } from '../payment/paymentApi';

export const planApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlans: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('plans')
            .select("*");

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          try {
              const { data } = await queryFulfilled;
              dispatch(getAllPlans(data));
          } catch (error) {
              console.error('Failed to fetch plans:', error);
          }
      }
    }),
    createPlan: builder.mutation({
        queryFn: async (formData) => {
          try {
            const { data, error } = await supabase
              .from('plans')
              .insert(formData)
              .select("*");
      
            if (error) {
              return { error: { status: 'CREATE_ERROR', message: error.message || 'An error occurred' } };
            }
      
            return { data };
          } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
          }
        },
        async onQueryStarted(formData, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled; // Wait for the create operation to complete
            // Directly trigger refetch after creation
            dispatch(planApi.endpoints.getAllPlans.initiate()); // Explicitly call getAllPlans here
          } catch (err) {
            console.error('Error handling query result:', err);
          }
        },
      }),
    updatePlans: builder.mutation({
        queryFn: async (formData) => {
          try {
            const { data, error } = await supabase
              .from('plans')
              .update(formData)
              .eq('id', formData.id)
              .select("*");
      
            if (error) {
              return { error: { status: 'UPDATE_ERROR', message: error.message || 'An error occurred' } };
            }
      
            return { data };
          } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
          }
        },
        async onQueryStarted(formData, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            // Directly trigger refetch after update
            dispatch(planApi.endpoints.getAllPlans.initiate()); // Explicitly call getAllPlans here
          } catch (err) {
            console.error('Error handling query result:', err);
          }
        },
      }),    
    deletePlan: builder.mutation({
    queryFn: async (id) => {
        try {
        const { data, error } = await supabase
            .from('plans')
            .delete()
            .eq('id', id)
            .select("*");
    
        if (error) {
            return { error: { status: 'DELETE_ERROR', message: error.message || 'An error occurred' } };
        }
    
        return { data };
        } catch (err) {
        return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
    },
    async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
        await queryFulfilled; // Wait for the delete operation to complete
        // Directly trigger refetch after deletion
        dispatch(planApi.endpoints.getAllPlans.initiate()); // Explicitly call getAllPlans here
        } catch (err) {
        console.error('Error handling query result:', err);
        }
    },
    }),
    addPlanOrder: builder.mutation({
      queryFn: async (formData) => {
        const { profile_id, profile_name, plan_id, plan_name, price, full_name, tree, bottles, co2, active_plan, auto_renew, email, paymentIntentId } = formData;
        try {
          // Fetch the stripe_customer_id from the profile
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('stripe_customer_id')
            .eq('id', profile_id)
            .single();

          if (profileError) throw new Error('Failed to fetch profile data');

          let customerId = profileData?.stripe_customer_id;

          // Create a PaymentIntent using the Edge Function
          const { data: paymentIntentData, error: paymentIntentError } = await supabase.functions.invoke('create-payment-intent', {
            body: { 
              amount: price * 100,
              customer_id: customerId, // This might be undefined for new customers
              email: email, // Pass the email for new customer creation
              metadata: { profile_id, is_update: 'false' }
            },
          });

          if (paymentIntentError) throw new Error(paymentIntentError.message || 'Failed to create payment intent');

          // If a new customer was created, update the profile with the new customer ID
          if (!customerId && paymentIntentData.customerId) {
            await supabase
              .from('profiles')
              .update({ stripe_customer_id: paymentIntentData.customerId })
              .eq('id', profile_id);
          }

          // Insert the new plan order into the planorders table
          const { data: planOrderData, error: planOrderError } = await supabase
            .from('planorders')
            .insert({ 
              plan_id, 
              profile_id, 
              profile_name, 
              plan_name, 
              price, 
              full_name, 
              tree, 
              bottles, 
              co2,
            })
            .select();

          if (planOrderError) {
            throw new Error(planOrderError.message || 'An error occurred while creating the plan order');
          }

          if (!planOrderData || planOrderData.length === 0) {
            throw new Error('No plan order data received');
          }

          const newPlanOrder = planOrderData[0];
          const nextRenewalDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); 
          // Step 2: Create or update subscription
          let subscriptionData;
          const { error: subscriptionError } = await supabase
            .from('subscriptions')
            .upsert({
              profile_id,
              plan_id,
              auto_renew,
              current_order_id: newPlanOrder.id,
              status: 'active',
              start_date: new Date().toISOString(),
              end_date: nextRenewalDate.toISOString(),
            }, {
              onConflict: 'profile_id,plan_id',
            });

          if (subscriptionError) {
            throw new Error(subscriptionError.message || 'An error occurred while updating the subscription');
          }

          // Fetch the subscription data after upsert
          const { data: fetchedData, error: fetchError } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('profile_id', profile_id)
            .eq('plan_id', plan_id)
            .single();

          if (fetchError) {
            throw new Error(fetchError.message || 'An error occurred while fetching the subscription');
          }

          if (!fetchedData) {
            throw new Error('No subscription data found after upsert operation');
          }

          subscriptionData = fetchedData;

          // Step 3: Log subscription history
          const { error: historyError } = await supabase
            .from('subscription_history')
            .insert({
              subscription_id: subscriptionData.id,
              profile_id,
              plan_id,
              order_id: newPlanOrder.id,
              change_type: 'created', // or 'renewed' based on your logic
              new_status: 'active',
              new_auto_renew: auto_renew,
            });

          if (historyError) {
            console.error('Error logging subscription history:', historyError);
          }

          // Step 4: Update profile fields
          const { error: profileUpdateError } = await supabase
            .rpc('increment_fields', {
              profile_id,
              tree_increment: parseInt(tree, 10),
              plastic_increment: parseInt(bottles, 10),
              carbon_increment: 0,
              co2_increment: parseInt(co2, 10),
              pid: parseInt(active_plan, 10),
            });

          if (profileUpdateError) throw new Error(profileUpdateError.message || 'Failed to update profile');

          // Step 5: Add payment method
          const { data: paymentData, error: paymentError } = await supabase.functions.invoke('get-payment-details', {
            body: { paymentIntentId: paymentIntentId }
          });

          if (paymentError) {
            console.error('Error getting payment details:', paymentError);
            throw new Error(paymentError.message || 'Failed to get payment details');
          }

          if (paymentData.error === "Payment not completed" || paymentData.error === "Payment method not available") {
            console.log('Payment status:', paymentData.status);
            throw new Error(`Payment is not complete. Current status: ${paymentData.status}`);
          }

          // Only proceed with adding the payment method if we have the details
          if (paymentData.paymentMethodId) {
            // Add payment method to database
            const { data: paymentMethodData, error: paymentMethodError } = await supabase
              .from('payment_methods')
              .insert({
                stripe_payment_intent_id: paymentIntentId,
                stripe_payment_method_id: paymentData.paymentMethodId,
                card_last4: paymentData.last4,
                profile_id,
              })
              .select()
              .single();

            if (paymentMethodError) throw new Error(paymentMethodError.message || 'Failed to add payment method');

            // Set as default payment method
            const { error: profileUpdateError2 } = await supabase
              .from('profiles')
              .update({ 
                default_payment_method_id: paymentMethodData.id,
                stripe_payment_method_id: paymentData.paymentMethodId,
               })
              .eq('id', profile_id);

            if (profileUpdateError2) throw new Error(profileUpdateError2.message || 'Failed to update default payment method');
          }

          return { 
            data: { 
              planOrderData: newPlanOrder, 
              subscriptionData, 
              paymentIntentData
            } 
          };
        } catch (err) {
          console.error('Error in addPlanOrder:', err);
          return { 
            error: { 
              status: 'FETCH_ERROR', 
              message: err.message || 'An error occurred',
              details: err.stack
            } 
          };
        }
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.planOrderData) {
            const user_id = formData.profile_id;
            dispatch(profileApi.endpoints.getProfile.initiate({ user_id }));
            dispatch(getSubscribedPlans(data.planOrderData[0]));
            
            // Invalidate the getPlansByProfile query
            dispatch(planApi.util.invalidateTags(['PlansByProfile']));
          } else {
            console.error('No plan order data received');
          }
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    updateSubscriptionPlanOrder: builder.mutation({
      queryFn: async (formData) => {
        const { profile_id, plan_id, plan_name, price, full_name, tree, bottles, co2, auto_renew, payment_method_id } = formData;
        try {
          console.log('Starting updateSubscriptionPlanOrder with formData:', formData);

          // Fetch the stripe_customer_id and email from the profile
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('stripe_customer_id, email')
            .eq('id', profile_id)
            .single();

          if (profileError) {
            console.error('Failed to fetch profile data:', profileError);
            throw new Error('Failed to fetch profile data');
          }

          const customerId = profileData.stripe_customer_id;
          const email = profileData.email;
          console.log('Fetched customer ID:', customerId);

          // Create a PaymentIntent using the Edge Function
          const { data: paymentIntentData, error: paymentIntentError } = await supabase.functions.invoke('create-payment-intent', {
            body: { 
              amount: price * 100,
              customer_id: customerId,
              email: email,
              metadata: { profile_id, is_update: 'true' }
            },
          });

          if (paymentIntentError) {
            console.error('Failed to create payment intent:', paymentIntentError);
            throw new Error(paymentIntentError.message || 'Failed to create payment intent');
          }

          console.log('Created payment intent:', paymentIntentData);

          // Insert the new plan order into the planorders table
          const { data: planOrderData, error: planOrderError } = await supabase
            .from('planorders')
            .insert({ 
              plan_id, 
              profile_id, 
              plan_name, 
              price, 
              full_name, 
              tree, 
              bottles, 
              co2,
            })
            .select();

          if (planOrderError) {
            throw new Error(planOrderError.message || 'An error occurred while creating the plan order');
          }

          if (!planOrderData || planOrderData.length === 0) {
            throw new Error('No plan order data received');
          }

          const newPlanOrder = planOrderData[0];
          const nextRenewalDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); 
          // Step 2: Create or update subscription
          let subscriptionData;
          const { error: subscriptionError } = await supabase
            .from('subscriptions')
            .upsert({
              profile_id,
              plan_id,
              auto_renew,
              current_order_id: newPlanOrder.id,
              status: 'active',
              start_date: new Date().toISOString(),
              end_date: nextRenewalDate.toISOString(),
            }, {
              onConflict: 'profile_id,plan_id',
            });

          if (subscriptionError) {
            throw new Error(subscriptionError.message || 'An error occurred while updating the subscription');
          }

          // Fetch the subscription data after upsert
          const { data: fetchedData, error: fetchError } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('profile_id', profile_id)
            .eq('plan_id', plan_id)
            .single();

          if (fetchError) {
            throw new Error(fetchError.message || 'An error occurred while fetching the subscription');
          }

          if (!fetchedData) {
            throw new Error('No subscription data found after upsert operation');
          }

          subscriptionData = fetchedData;

          // Step 3: Log subscription history
          const { error: historyError } = await supabase
            .from('subscription_history')
            .insert({
              subscription_id: subscriptionData.id,
              profile_id,
              plan_id,
              order_id: newPlanOrder.id,
              change_type: 'updated', // or 'renewed' based on your logic
              new_status: 'active',
              new_auto_renew: auto_renew,
            });

          if (historyError) {
            console.error('Error logging subscription history:', historyError);
          }

          // Update profile fields
          const { error: profileUpdateError } = await supabase
            .rpc('increment_fields', {
              profile_id,
              tree_increment: parseInt(tree, 10),
              plastic_increment: parseInt(bottles, 10),
              carbon_increment: 0,
              co2_increment: parseInt(co2, 10),
              pid: parseInt(plan_id, 10),
            });

          if (profileUpdateError) throw new Error(profileUpdateError.message || 'Failed to update profile');

          return { 
            data: { 
              planOrderData: newPlanOrder, 
              subscriptionData, 
              paymentIntentData
            } 
          };
        } catch (err) {
          console.error('Error in updateSubscriptionPlanOrder:', err);
          return { 
            error: { 
              status: 'FETCH_ERROR', 
              message: err.message || 'An error occurred',
              details: err.stack
            } 
          };
        }
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.planOrderData) {
            const user_id = formData.profile_id;
            dispatch(profileApi.endpoints.getProfile.initiate({ user_id }));
            dispatch(getSubscribedPlans(data.planOrderData));
            
            // Refresh payment methods
            dispatch(paymentApi.endpoints.fetchPaymentMethods.initiate(user_id));
          } else {
            console.error('No plan order data received');
          }
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    getPlansByProfile: builder.query({
      queryFn: async ({ profile_id }) => {
        try {
          // Fetch plans by profile_id from the plans table
          const { data, error } = await supabase
            .from('planorders') // assuming 'planorders' table stores user plans
            .select('*')
            .eq('profile_id', profile_id);

          if (error) {
            return { error: { status: 'FETCH_ERROR', message: error.message || 'An error occurred' } };
          }

          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      providesTags: ['PlansByProfile'],
      async onQueryStarted({ profile_id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            const user_id = profile_id;
            dispatch(profileApi.endpoints.getProfile.initiate({ user_id }));
            dispatch(getSubscribedPlans(data[0])); 
          }
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    getAllPlansOrders: builder.query({
        queryFn: async () => {
          try {
            const { data, error } = await supabase
              .from('planorders')
              .select('*');
      
            if (error) {
              return { error: { status: 'FETCH_ERROR', message: error.message || 'An error occurred' } };
            }
      
            return { data };
          } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
          }
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(getAllPlanOrders(data)); // Dispatch the new action here
          } catch (err) {
            console.error('Error handling query result:', err);
          }
        },
    }),
    updatePlan: builder.mutation({
      queryFn: async ({ id, ...updateData }) => {
        try {
          const { data, error } = await supabase
            .from('plans')
            .update(updateData)
            .eq('id', id)
            .select();

          if (error) throw error;
          return { data };
        } catch (err) {
          return { error: { status: 'UPDATE_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(planApi.endpoints.getAllPlans.initiate());
        } catch (err) {
          console.error('Error updating plan:', err);
        }
      },
    }),
    updateAutoRenewal: builder.mutation({
      queryFn: async ({ profileId, planId, autoRenew }) => {
        try {
          const { data, error } = await supabase
            .from('subscriptions')
            .update({ auto_renew: autoRenew })
            .eq('profile_id', profileId)
            .eq('plan_id', planId)
            .select();

          if (error) throw error;

          // Log the change in subscription history
          await supabase
            .from('subscription_history')
            .insert({
              subscription_id: data[0].id,
              profile_id: profileId,
              plan_id: planId,
              order_id: data[0].current_order_id,
              change_type: 'auto_renew_updated',
              new_auto_renew: autoRenew,
            });

          return { data };
        } catch (err) {
          return { error: { status: 'UPDATE_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),
    getSubscriptionHistory: builder.query({
      queryFn: async (profileId) => {
        try {
          const { data, error } = await supabase
            .from('subscription_history')
            .select('*')
            .eq('profile_id', profileId)
            .order('created_at', { ascending: false });

          if (error) throw error;
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),
  }),
});

export const {
  useGetAllPlansQuery,
  useCreatePlanMutation,
  useUpdatePlansMutation,
  useDeletePlanMutation,
  useAddPlanOrderMutation,
  useGetPlansByProfileQuery,
  useGetAllPlansOrdersQuery,
  useUpdatePlanMutation,
  useUpdateSubscriptionPlanOrderMutation,
  useUpdateAutoRenewalMutation,
  useGetSubscriptionHistoryQuery,
} = planApi;
