import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Badge } from "./ui/badge";
import { Skeleton } from "./ui/skeleton";
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
  PieChart, Pie, Cell, LineChart, Line, AreaChart, Area
} from 'recharts';
import { 
  Users, ShoppingCart, CreditCard, Trees, Droplet, Globe, TrendingUp, 
  TrendingDown, Search, RefreshCcw, Download, Calendar, Package, UserCheck, HeartHandshake
} from 'lucide-react';
import HeaderDash from './HeaderDash';

const DashboardAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('month');
  const [userStats, setUserStats] = useState({ total: 0, active: 0, newUsers: 0 });
  const [productSales, setProductSales] = useState([]);
  const [subscriptionStats, setSubscriptionStats] = useState([]);
  const [environmentalImpact, setEnvironmentalImpact] = useState({
    treesPlanted: 0,
    bottlesRemoved: 0,
    co2Sequestered: 0,
    humanitarianProjects: 0
  });
  const [revenueData, setRevenueData] = useState([]);
  const [userGrowthData, setUserGrowthData] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const products = [
    { id: 1, title: 'Plant a certified tree', price: 10 },
    { id: 2, title: 'Recover 20 plastic bottles', price: 18 },
    { id: 3, title: 'Domestic flight', price: 57 },
    { id: 4, title: 'Conference attendee', price: 60 },
    { id: 5, title: 'Year of train commutes', price: 54 },
    { id: 6, title: 'Short-haul flight', price: 88 },
    { id: 7, title: 'Long-haul flight', price: 125 },
    { id: 8, title: 'Hotel stay', price: 28 },
    { id: 9, title: 'Year of public transport commutes', price: 33 },
    { id: 10, title: 'Year of car commutes', price: 44 },
    { id: 11, title: 'Dual screen usage', price: 83 },
  ];

  const plans = [
    { id: 1, title: 'Guardian', price: 25, trees: 2, bottles: 20, co2: 100 },
    { id: 2, title: 'Protector', price: 49, trees: 4, bottles: 50, co2: 200 },
    { id: 3, title: 'Warrior', price: 95, trees: 6, bottles: 30, co2: 750 },
    { id: 4, title: 'Hero', price: 160, trees: 12, bottles: 40, co2: 1515 },
    { id: 5, title: 'Legend', price: 449, trees: 100, bottles: 1000, co2: 2000 },
  ];

  useEffect(() => {
    fetchDashboardData();
  }, [timeRange]);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      // Simulated API calls - replace with actual API endpoints
      setUserStats({ total: 5000, active: 4200, newUsers: 300 });
      setProductSales(products.map(product => ({
        ...product,
        salesCount: Math.floor(Math.random() * 1000),
        trend: Math.random() > 0.5 ? 'up' : 'down',
        trendValue: Math.floor(Math.random() * 20)
      })));
      setSubscriptionStats(plans.map(plan => ({
        ...plan,
        subscriberCount: Math.floor(Math.random() * 1000)
      })));
      setEnvironmentalImpact({
        treesPlanted: 50000,
        bottlesRemoved: 1000000,
        co2Sequestered: 500000,
        humanitarianProjects: 25,
        timeSeriesData: [
          { date: '2023-01', treesPlanted: 4000, bottlesRemoved: 80000, co2Sequestered: 40000, humanitarianProjects: 2 },
          { date: '2023-02', treesPlanted: 4500, bottlesRemoved: 90000, co2Sequestered: 45000, humanitarianProjects: 3 },
          { date: '2023-03', treesPlanted: 5000, bottlesRemoved: 100000, co2Sequestered: 50000, humanitarianProjects: 4 },
        ]
      });
      setRevenueData([
        { name: 'Jan', value: 40000 },
        { name: 'Feb', value: 45000 },
        { name: 'Mar', value: 55000 },
        { name: 'Apr', value: 60000 },
        { name: 'May', value: 65000 },
        { name: 'Jun', value: 70000 },
      ]);
      setUserGrowthData([
        { date: '2023-01', users: 4000 },
        { date: '2023-02', users: 4500 },
        { date: '2023-03', users: 5000 },
      ]);
      setTopProducts(products.slice(0, 5).map(product => ({
        name: product.title,
        revenue: product.price * Math.floor(Math.random() * 1000),
        salesCount: Math.floor(Math.random() * 1000)
      })));
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const SkeletonLoader = () => (
    <div className="space-y-4">
      <Skeleton className="h-12 w-full" />
      <Skeleton className="h-64 w-full" />
    </div>
  );

  const DataCard = ({ title, value, icon: Icon, trend, trendValue, color }) => (
    <Card className={`bg-${color}-50 border-${color}-200`}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        {Icon && <Icon className={`h-4 w-4 text-${color}-500`} />}
      </CardHeader>
      <CardContent>
        <div className={`text-2xl font-bold text-${color}-700`}>{value.toLocaleString()}</div>
        {trend && (
          <div className={`flex items-center text-xs ${trend === 'up' ? 'text-green-500' : 'text-red-500'}`}>
            {trend === 'up' ? <TrendingUp className="mr-1 h-4 w-4" /> : <TrendingDown className="mr-1 h-4 w-4" />}
            {trendValue}% from last {timeRange}
          </div>
        )}
      </CardContent>
    </Card>
  );

  return (
    <div className="space-y-6 p-8 bg-gray-50">
      <div className="flex justify-between items-center mb-8">
        <motion.h1 
          className="text-4xl font-bold text-gray-800"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          Admin Dashboard
        </motion.h1>
        <div className="flex items-center space-x-4">
          <Select value={timeRange} onValueChange={setTimeRange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select time range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">Last 24 hours</SelectItem>
              <SelectItem value="week">Last 7 days</SelectItem>
              <SelectItem value="month">Last 30 days</SelectItem>
              <SelectItem value="year">Last 12 months</SelectItem>
            </SelectContent>
          </Select>
          <Button onClick={fetchDashboardData}>
            <RefreshCcw className="mr-2 h-4 w-4" />
            Refresh Data
          </Button>
          <Button variant="outline">
            <Download className="mr-2 h-4 w-4" />
            Export Report
          </Button>
        </div>
      </div>

      <AnimatePresence>
        {loading ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SkeletonLoader />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={fadeInUp}
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
              <Card className="col-span-1 lg:col-span-2">
                <CardHeader>
                  <CardTitle>Revenue Overview</CardTitle>
                </CardHeader>
                <CardContent>
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={revenueData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
              <DataCard 
                title="Total Revenue" 
                value={revenueData.reduce((sum, { value }) => sum + value, 0)} 
                icon={CreditCard} 
                trend="up" 
                trendValue={7.8}
                color="blue"
              />
              <DataCard 
                title="Active Products" 
                value={productSales.length} 
                icon={Package} 
                color="green"
              />
              <DataCard 
                title="Total Subscriptions" 
                value={subscriptionStats.reduce((sum, { subscriberCount }) => sum + subscriberCount, 0)} 
                icon={UserCheck} 
                trend="up" 
                trendValue={5.2}
                color="purple"
              />
              <DataCard 
                title="Average Order Value" 
                value={(revenueData.reduce((sum, { value }) => sum + value, 0) / productSales.reduce((sum, { salesCount }) => sum + salesCount, 0)).toFixed(2)} 
                icon={ShoppingCart} 
                color="yellow"
              />
            </div>

            <Tabs defaultValue="products" className="space-y-4">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="products">Products</TabsTrigger>
                <TabsTrigger value="subscriptions">Subscriptions</TabsTrigger>
              </TabsList>
              
              <TabsContent value="products">
                <Card>
                  <CardHeader>
                    <div className="flex justify-between items-center">
                      <CardTitle>Product Performance</CardTitle>
                      <div className="flex items-center space-x-2">
                        <Input
                          placeholder="Search products..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="max-w-sm"
                        />
                        <Button variant="ghost" size="icon">
                          <Search className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Product</TableHead>
                          <TableHead>Price (AED)</TableHead>
                          <TableHead>Sales Count</TableHead>
                          <TableHead>Total Revenue (AED)</TableHead>
                          <TableHead>Trend</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {productSales
                          .filter(product => 
                            product.title.toLowerCase().includes(searchTerm.toLowerCase())
                          )
                          .map((product) => (
                          <TableRow key={product.id}>
                            <TableCell className="font-medium">{product.title}</TableCell>
                            <TableCell>{product.price.toFixed(2)}</TableCell>
                            <TableCell>{product.salesCount.toLocaleString()}</TableCell>
                            <TableCell>{(product.price * product.salesCount).toLocaleString()}</TableCell>
                            <TableCell>
                              <Badge variant={product.trend === 'up' ? 'success' : 'destructive'}>
                                {product.trend === 'up' ? <TrendingUp className="mr-1 h-4 w-4" /> : <TrendingDown className="mr-1 h-4 w-4" />}
                                {product.trendValue}%
                              </Badge>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="subscriptions">
                <Card>
                  <CardHeader>
                    <CardTitle>Subscription Overview</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      <div>
                      <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={subscriptionStats}
                              dataKey="subscriberCount"
                              nameKey="title"
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              label
                            >
                              {subscriptionStats.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Plan</TableHead>
                            <TableHead>Price (AED)</TableHead>
                            <TableHead>Subscribers</TableHead>
                            <TableHead>Trees</TableHead>
                            <TableHead>Bottles</TableHead>
                            <TableHead>CO2 (kg)</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {subscriptionStats.map((plan) => (
                            <TableRow key={plan.id}>
                              <TableCell className="font-medium">{plan.title}</TableCell>
                              <TableCell>{plan.price}</TableCell>
                              <TableCell>{plan.subscriberCount.toLocaleString()}</TableCell>
                              <TableCell>{plan.trees}</TableCell>
                              <TableCell>{plan.bottles}</TableCell>
                              <TableCell>{plan.co2}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>

            <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Card>
                <CardHeader>
                  <CardTitle>User Statistics</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <DataCard 
                      title="Total Users" 
                      value={userStats.total} 
                      icon={Users} 
                      color="indigo"
                    />
                    <DataCard 
                      title="Active Users" 
                      value={userStats.active} 
                      icon={Users} 
                      trend="up" 
                      trendValue={3.1}
                      color="green"
                    />
                    <DataCard 
                      title="New Users" 
                      value={userStats.newUsers} 
                      icon={Users} 
                      trend="up" 
                      trendValue={1.5}
                      color="blue"
                    />
                  </div>
                  <div className="mt-6">
                    <ResponsiveContainer width="100%" height={200}>
                      <LineChart data={userGrowthData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="users" stroke="#8884d8" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
              
              <Card>
                <CardHeader>
                  <CardTitle>Environmental Impact</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                    <DataCard 
                      title="Trees Planted" 
                      value={environmentalImpact.treesPlanted} 
                      icon={Trees} 
                      color="green"
                    />
                    <DataCard 
                      title="Bottles Removed" 
                      value={environmentalImpact.bottlesRemoved} 
                      icon={Droplet} 
                      color="blue"
                    />
                    <DataCard 
                      title="CO₂ Sequestered (kg)" 
                      value={environmentalImpact.co2Sequestered} 
                      icon={Globe} 
                      color="purple"
                    />
                    <DataCard 
                      title="Humanitarian Projects" 
                      value={environmentalImpact.humanitarianProjects} 
                      icon={HeartHandshake} 
                      color="red"
                    />
                  </div>
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={environmentalImpact.timeSeriesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="treesPlanted" stroke="#82ca9d" />
                      <Line type="monotone" dataKey="bottlesRemoved" stroke="#8884d8" />
                      <Line type="monotone" dataKey="co2Sequestered" stroke="#ffc658" />
                      <Line type="monotone" dataKey="humanitarianProjects" stroke="#ff7300" />
                    </LineChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </div>

            <div className="mt-8">
              <Card>
                <CardHeader>
                  <CardTitle>Top Performing Products</CardTitle>
                  <CardDescription>Products with the highest revenue in the selected time range</CardDescription>
                </CardHeader>
                <CardContent>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={topProducts}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                      <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                      <Tooltip />
                      <Legend />
                      <Bar yAxisId="left" dataKey="revenue" fill="#8884d8" name="Revenue (AED)" />
                      <Bar yAxisId="right" dataKey="salesCount" fill="#82ca9d" name="Sales Count" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </div>

            <div className="mt-8">
              <Card>
                <CardHeader>
                  <CardTitle>Recent Activity</CardTitle>
                  <CardDescription>Latest actions and events</CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Badge variant="outline" className="mr-2">New User</Badge>
                      <span>John Doe signed up for a Hero plan</span>
                      <span className="ml-auto text-sm text-gray-500">2 hours ago</span>
                    </li>
                    <li className="flex items-center">
                      <Badge variant="outline" className="mr-2">Purchase</Badge>
                      <span>500 trees planted by Eco Corp</span>
                      <span className="ml-auto text-sm text-gray-500">5 hours ago</span>
                    </li>
                    <li className="flex items-center">
                      <Badge variant="outline" className="mr-2">Milestone</Badge>
                      <span>1 million plastic bottles removed!</span>
                      <span className="ml-auto text-sm text-gray-500">1 day ago</span>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DashboardAdmin;