import { createSlice } from "@reduxjs/toolkit";
import { supabase } from "../../supabase";

const initialState = {
    accessToken:undefined,
    provider_token:undefined,
    user:undefined,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // userSignUp:(state,action)=>{
        //     sessionStorage.setItem(
        //         'auth',
        //         JSON.stringify({
        //           accessToken: action.user.access_token,
        //           user: action.user,
        //         })
        //       );
        //       state.accessToken = action.payload.accessToken;
        //       state.user = action.payload.user;

        // },
        userLoggedIn:(state,action) =>{
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
            state.provider_token = action.payload.providerToken;
        },
        userLoggedOut:(state,action) =>{
            state.accessToken = undefined;
            state.user = undefined;
        },
 
    },
});

export const {userLoggedIn,userLoggedOut} = authSlice.actions;
export default authSlice.reducer;