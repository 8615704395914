import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCreateContactMutation, useGetProfileQuery } from '../app/features/profile/profileApi';
import { useSelector } from 'react-redux';
import { Mail, MessageSquare, User, Send, ArrowRight, Phone, MapPin, Globe, Linkedin, Twitter, Instagram, Facebook, Music4 } from 'lucide-react';
import { Container, Section } from "./ui/layout";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Checkbox } from "./ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { useSendEmailMutation } from '../app/features/mail/mailApi';
import {toast} from './ui/use-toast'
const ContactPage = () => {
  const [loading, setLoading] = useState(false);
  const sessionAuth = sessionStorage?.getItem('auth');
  const profileId = useSelector((state) => state.profile.profile?.id);
  const [sendEmail] = useSendEmailMutation(); 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    agreePrivacy: false,
  });
  
  const [createContact, { isLoading }] = useCreateContactMutation();
  const [activeTab, setActiveTab] = useState('general');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSubmit = sessionAuth ? { ...formData, profile_id: profileId } : formData;
    setLoading(true);
    try {
      await createContact(formDataToSubmit).unwrap();
      const emailText = `
      You have received a new contact form submission from ${formData.name} (${formData.email}).
      
      Message:
      ${formData.message}
      
      Best regards,
      Bayti Eco Team
      `;

     const emailHtml = `
          <!DOCTYPE html>
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Bayti Eco Email</title>
                  <style>
                      body {
                          font-family: Arial, sans-serif;
                          line-height: 1.6;
                          color: #333333;
                          margin: 0;
                          padding: 0;
                          background-color: #f4f4f4;
                      }
                      .container {
                          max-width: 600px;
                          margin: 0 auto;
                          padding: 20px;
                          background-color: #ffffff;
                      }
                      .header {
                          background-color: #4a4af4;
                          color: #ffffff;
                          padding: 20px;
                          text-align: center;
                      }
                      .logo {
                          max-width: 150px;
                          height: auto;
                      }
                      .content {
                          padding: 20px;
                      }
                      .footer {
                          background-color: #f0f0f0;
                          color: #666666;
                          text-align: center;
                          padding: 10px;
                          font-size: 12px;
                      }
                      .button {
                          display: inline-block;
                          padding: 10px 20px;
                          background-color: #4a4af4;
                          color: #ffffff;
                          text-decoration: none;
                          border-radius: 5px;
                          margin-top: 15px;
                      }
                      .social-icons {
                          margin-top: 15px;
                      }
                      .social-icons a {
                          display: inline-block;
                          margin: 0 10px;
                      }
                      .social-icons img {
                          width: 24px;
                          height: 24px;
                      }
                  </style>
              </head>
              <body>
              <div class="container">
                <div class="header">
                    <img src="https://bayti.eco/images/BaytiEcoLogo.png" alt="Bayti Eco Logo" class="logo">
                    <h1>Bayti Eco</h1>
                </div>
                 <div class="content">
                    <h2>Hello Bayti Eco,</h2>
                    <p>You have received a new contact form submission from <span class="highlight">${formData.name}</span> (<span class="highlight">${formData.email}</span>):</p>
                    <p>[${formData.message}]</p>
                    <p>Stay sustainable!</p>
                    <a href="https://bayti.eco" class="button">Visit Our Website</a>
                </div>
         
              <div class="footer">
                  <p>© 2024 Bayti Eco. All rights reserved.</p>
                  <p>You're receiving this email because you subscribed to our newsletter. If you no longer wish to receive these emails, you can <a href="#">unsubscribe here</a>.</p>
                  <div class="social-icons">
                      <a href="#"><img src="https://bayti.eco/images/facebook-icon.png" alt="Facebook"></a>
                      <a href="#"><img src="https://bayti.eco/images/twitter-icon.png" alt="Twitter"></a>
                      <a href="#"><img src="https://bayti.eco/images/instagram-icon.png" alt="Instagram"></a>
                      <a href="#"><img src="https://bayti.eco/images/linkedin-icon.png" alt="LinkedIn"></a>
                  </div>
              </div>
          </div>
      </body>
      </html>
          `;
          const response = await sendEmail({
            sender:'info@bayti.eco',
            to: 'contact@bayti.eco',
            subject: 'Bayti Eco Contact Form Query',
            text: emailText,
            html: emailHtml,
          }).unwrap();
          setFormData({ name: '', email: '', message: '', agreePrivacy: false });
     // Check the response for success
     toast({
      title: "Message Send Successfully",
      description: "Thanks for the query, BaytiEco Team will contact soon!.",
      duration: 5000,
    });
  } catch (error) {
    console.error('Failed to send message:', error); // Log the error
    const errorMessage = error?.data?.message || error?.message || 'An unknown error occurred';
    toast({
      title: "Message Sending Failed",
      description: "There was an error sending message. Please try again.",
      variant: "destructive",
      duration: 3000,
    });
  }finally{
    setLoading(false);
  }
};

  const teamMembers = [
    { name: 'Bakri Albrek', role: 'CEO', image: 'https://ui-avatars.com/api/?name=Bakri+Albrek&background=0D8ABC&color=fff&size=128' },
  ];

  const contactMethods = [
    { icon: <Mail className="h-6 w-6" />, title: 'Email', content: 'info@bayti.eco', action: 'mailto:info@bayti.eco' },
    { icon: <Phone className="h-6 w-6" />, title: 'Phone', content: '+971 52 244 4896', action: 'tel:+971522444896' },
    { icon: <MapPin className="h-6 w-6" />, title: 'Office', content: 'Business Incubator, Masdar City, Abu Dhabi, UAE', action: 'https://maps.google.com' },
  ];

  const socialLinks = [
    { icon: <Linkedin className="h-6 w-6" />, url: 'https://www.linkedin.com/company/Baytieco' },
    { icon: <Twitter className="h-6 w-6" />, url: 'https://twitter.com/Baytieco' },
    { icon: <Instagram className="h-6 w-6" />, url: 'https://www.instagram.com/Baytieco' },
    { icon: <Music4 className="h-6 w-6" />, url: 'https://www.tiktok.com/@Baytieco' },
  ];

  return (
    <Container className="py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Section className="mb-12">
          <Card className="bg-gradient-to-br from-blue-50 to-indigo-50">
            <CardContent className="p-6 sm:p-10">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div>
                  <CardTitle className="text-4xl sm:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                    Let&apos;s Connect
                  </CardTitle>
                  <CardDescription className="text-xl mb-8">We&apos;re here to help and answer any question you might have. We look forward to hearing from you!</CardDescription>
                  
                  <div className="space-y-6 mb-8">
                    {contactMethods.map((method, index) => (
                      <motion.a
                        key={index}
                        href={method.action}
                        className="flex items-center space-x-4 text-blue-600 hover:text-blue-800 transition-colors duration-200"
                        whileHover={{ x: 5 }}
                      >
                        <div className="bg-blue-100 p-3 rounded-full">{method.icon}</div>
                        <div>
                          <h3 className="font-semibold">{method.title}</h3>
                          <p>{method.content}</p>
                        </div>
                      </motion.a>
                    ))}
                  </div>

                  <div className="flex space-x-4">
                    {socialLinks.map((link, index) => (
                      <motion.a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {link.icon}
                      </motion.a>
                    ))}
                  </div>
                </div>
                <div>
                      {loading &&  (<>
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                    <p className="loading-text">Generating... Please wait.</p>
                                </div>
                          </>)}
                  <Card>
                    <CardHeader>

                      <CardTitle className="text-2xl font-semibold">Send us a message</CardTitle>
                      <CardDescription>
                        Fill out the form below and we&apos;ll get back to you as soon as possible.
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="relative">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Your name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            className="pl-10"
                          />
                          <User className="absolute top-3 left-3 text-gray-400" size={18} />
                        </div>
                        <div className="relative">
                          <Input
                            type="email"
                            name="email"
                            placeholder="your@email.com"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            className="pl-10"
                          />
                          <Mail className="absolute top-3 left-3 text-gray-400" size={18} />
                        </div>
                        <div className="relative">
                          <Textarea
                            name="message"
                            placeholder="Type your message..."
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                            className="pl-10 pt-10"
                            rows={5}
                          />
                          <MessageSquare className="absolute top-3 left-3 text-gray-400" size={18} />
                        </div>
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            id="agreePrivacy"
                            name="agreePrivacy"
                            checked={formData.agreePrivacy}
                            onCheckedChange={(checked) => setFormData(prev => ({ ...prev, agreePrivacy: checked }))}
                          />
                          <label htmlFor="agreePrivacy" className="text-sm text-gray-600">
                            You agree to our friendly <a href="/privacy-policy" className="text-blue-600 hover:underline">privacy policy</a>.
                          </label>
                        </div>
                        <Button disabled={loading} type="submit" className="w-full flex justify-center items-center">
                          Submit Message <Send className="ml-2" size={18} />
                        </Button>
                        
                      </form>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </Section>
      </motion.div>

      <Section className="mb-12">
        <Card>
          <CardHeader>
            <CardTitle className="text-3xl font-bold mb-2">Frequently Asked Questions</CardTitle>
            <CardDescription>Find quick answers to common questions about our services and sustainability efforts.</CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="general" className="w-full">
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="general">General</TabsTrigger>
                <TabsTrigger value="services">Our Services</TabsTrigger>
                <TabsTrigger value="sustainability">Sustainability</TabsTrigger>
              </TabsList>
              <TabsContent value="general">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold">What is Bayti?</h3>
                    <p>Bayti is a platform dedicated to helping businesses and individuals reduce their carbon footprint and contribute to global sustainability efforts.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">How can I get started with Bayti?</h3>
                    <p>You can get started by signing up for an account on our website and exploring our various sustainability solutions tailored to your needs.</p>
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="services">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold">What services does Bayti offer?</h3>
                    <p>We offer a range of services including carbon footprint calculation, offset programs, sustainability consulting, and eco-friendly business solutions.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Are Bayti&apos;s services suitable for small businesses?</h3>
                    <p>Absolutely! We have solutions tailored for businesses of all sizes, from small startups to large corporations.</p>
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="sustainability">
                <div className="space-y-4">
                  <div>
                    <h3 className="font-semibold">How does Bayti contribute to global sustainability?</h3>
                    <p>Through our offset programs, we support various environmental projects worldwide, including reforestation, renewable energy, and community development initiatives.</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Can individuals use Bayti to offset their personal carbon footprint?</h3>
                    <p>Yes, we offer personal carbon footprint calculators and offset options for individuals who want to reduce their environmental impact.</p>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </Section>

      <Section>
        <Card className="bg-gradient-to-br from-green-50 to-emerald-50">
          <CardContent className="p-6 sm:p-10">
            <div className="text-center mb-8">
              <CardTitle className="text-3xl sm:text-4xl font-bold mb-4 bg-gradient-to-r from-green-600 to-emerald-600 bg-clip-text text-transparent">
                Meet Our Team
              </CardTitle>
              <CardDescription className="text-lg">
                Get to know the friendly faces behind Bayti&apos;s customer support.
              </CardDescription>
            </div>
            <div className="flex justify-center">
              {teamMembers.map((member, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col items-center"
                  whileHover={{ y: -5 }}
                >
                  <Avatar className="w-24 h-24 mb-4">
                    <AvatarImage src={member.image} alt={member.name} />
                    <AvatarFallback>{member.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
                  </Avatar>
                  <h3 className="font-semibold text-lg">{member.name}</h3>
                  <p className="text-gray-600">{member.role}</p>
                </motion.div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Section>

      <Section className="mt-12">
        <Card className="bg-gradient-to-br from-purple-50 to-pink-50">
          <CardContent className="p-6 sm:p-10 text-center">
            <CardTitle className="text-3xl sm:text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
              JOIN OUR MISSION
            </CardTitle>
            <CardDescription className="text-lg mb-6">
              We&apos;re always looking for passionate individuals to join our team and help make a difference.
            </CardDescription>
            <Button variant="outline" className="group flex items-center mx-auto">
              View Open Positions
              <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" size={18} />
            </Button>
          </CardContent>
        </Card>
      </Section>
    </Container>
  );
};

export { ContactPage };