import React, { useEffect, useState } from 'react';
import { Leaf, Badge } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Progress } from "./ui/progress";
import { useSelector } from 'react-redux';

const GamificationSection = () => {
   
  const profile = useSelector((state) => state.profile.profile);
  const userCO2 =profile?.co2;
  const [level, setLevel] = useState(0);
  const [nextLevel, setNextLevel] = useState(1000); // Each level is 1000 CO2
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculatedLevel = Math.floor(userCO2 / 1000);
    setLevel(calculatedLevel);
    setProgress((userCO2 % 1000) / 10); // Calculate progress percentage
  }, [userCO2]);

  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle className="flex items-center">
          <Leaf className="mr-2 h-5 w-5 text-green-500" />
          Eco Level: {level}
        </CardTitle>
        <CardDescription>Keep sequestering CO2 to level up and earn badges!</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">Progress to Next Level</span>
          <span className="text-sm font-medium">{userCO2 % 1000} / {nextLevel} CO2</span>
        </div>
        <Progress value={progress} className="w-full" />
        <div className="grid grid-cols-1 gap-4 mt-4">
          {level > 0 ? (
            <Card>
              <CardHeader className="p-4">
                <CardTitle className="text-lg flex items-center">
                  <Badge className="mr-2 h-4 w-4 text-yellow-500" />
                  {level} Badge{level !== 1 && "s"} Earned
                </CardTitle>
                <CardDescription>Each badge represents 1000 tons of CO2 sequestered.</CardDescription>
              </CardHeader>
            </Card>
          ) : (
            <Card>
              <CardHeader className="p-4">
                <CardTitle className="text-lg flex items-center">
                  <Badge className="mr-2 h-4 w-4 text-gray-400" />
                  No Badges Yet
                </CardTitle>
                <CardDescription>Sequester 1000 tons of CO2 to earn your first badge!</CardDescription>
              </CardHeader>
            </Card>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default GamificationSection;
