import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProfilePublicQuery } from '../app/features/profile/profileApi';
import { getProfile } from '../app/features/profile/profileSlice';
import UserImpactProfileComponent from './UserImpactProfileComponent';
import { Skeleton } from "./ui/skeleton";
import { Alert, AlertTitle, AlertDescription } from "./ui/alert";
import { AlertCircle } from 'lucide-react';

const UserImpactPage = () => {
  const { webpage_link } = useParams();
  const dispatch = useDispatch();
  
  const { 
    data: profileQuery, 
    error: getProfileError, 
    isLoading: isProfileLoading 
  } = useGetProfilePublicQuery({ webpage_link });

  const profileData = useSelector((state) => state.profile.profile);

  useEffect(() => {
    if (profileQuery) {
      dispatch(getProfile(profileQuery[0]));
    }
  }, [profileQuery, dispatch]);

  if (isProfileLoading) {
    return (
      <div className="max-w-6xl mx-auto p-6">
        <Skeleton className="w-full h-64 rounded-2xl mb-8" />
        <div className="flex flex-wrap gap-8">
          <Skeleton className="w-36 h-36 rounded-full" />
          <div className="flex-1">
            <Skeleton className="w-3/4 h-8 mb-4" />
            <Skeleton className="w-full h-32 mb-4" />
            <div className="grid grid-cols-2 gap-4">
              {[...Array(4)].map((_, index) => (
                <Skeleton key={index} className="h-40" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (getProfileError) {
    return (
      <Alert variant="destructive" className="max-w-6xl mx-auto mt-8">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          There was an error loading the profile. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  if (!profileData) {
    return (
      <Alert className="max-w-6xl mx-auto mt-8">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Profile Not Found</AlertTitle>
        <AlertDescription>
          The requested profile could not be found. Please check the URL and try again.
        </AlertDescription>
      </Alert>
    );
  }

  const userData = {
    name: profileData.profile_name,
    profileImage: profileData.image,
    coverImage: profileData.cover,
    location: profileData.location,
    website: profileData.website,
    treesPlanted: profileData.tree || 0,
    forestAcres: 0.54, // This seems to be a static value, consider fetching it or calculating it
    bottlesRemoved: profileData.plastic || 0,
    plasticWeight: profileData.plastic || 0,
    ProjFunded: 1523.5, // This seems to be a static value, consider fetching it or calculating it
    co2Countered: profileData.carbon || 0,
    co2Balloons: profileData.co2 || 0,
    bio: profileData.about,
  };

  return <UserImpactProfileComponent user={userData} />;
};

export default UserImpactPage;