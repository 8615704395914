import React, { useState, useRef } from 'react';
import {
  MonitorCog,
  Factory,
  Plane,
  Car,
  Building,
  Droplet,
  Trash2,
  Mail,
  CheckCircle,
  ChevronRight,
  ChevronLeft,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import GetStarted from './GetStarted';
import { Container } from "./ui/layout";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./ui/select";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import { ChartContainer, ChartTooltip, ChartTooltipContent, ChartLegend, ChartLegendContent } from "./ui/chart";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useSendEmailMutation } from '../app/features/mail/mailApi';
import { useSelector } from 'react-redux';

const CarbonCalculatorPage = () => {
  const [step, setStep] = useState(1);
  const initialFormData = {
    companyName: '',
    industry: '',
    employeeCount: '',
    annualRevenue: '',
    electricityUsage: '',
    renewableEnergy: '',
    naturalGasUsage: '',
    waterConsumption: '',
    wasteGenerated: '',
    vehicleFleet: '',
    businessTravel: '',
    airConditioning: '',
    email: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [results, setResults] = useState(null);
  const emailSent = useSelector((state) => state.mail.emailSent);

  const industries = [
    'Oil and Gas',
    'Manufacturing',
    'Construction',
    'Real Estate',
    'Financial Services',
    'Hospitality and Tourism',
    'Healthcare',
    'Retail and E-commerce',
    'Information Technology',
    'Other',
  ];

  const emissionFactors = {
    electricity: 0.42,
    naturalGas: 1.88,
    water: 0.344,
    waste: 50,
    vehicle: 0.21,
    businessTravel: {
      shortHaulFlight: 0.156,
      longHaulFlight: 0.109,
      train: 0.041,
    },
    airConditioning: 1.5,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step < 5) {
      setStep(step + 1);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const [sendEmail] = useSendEmailMutation();
  const resultsRef = useRef(null);

  const generatePDF = async () => {
    if (!resultsRef.current) return null;

    const canvas = await html2canvas(resultsRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const imgX = (pdfWidth - imgWidth * ratio) / 2;
    const imgY = 30;

    pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    return pdf;
  };

  const sendResultsEmail = async (email, results) => {
    const pdf = await generatePDF();
    if (!pdf) {
      console.error('Failed to generate PDF');
      return;
    }

    const pdfAttachment = pdf.output('datauristring');

    const emailText = `
       Your Carbon Footprint Results.
      Please find attached a detailed PDF report of your carbon footprint results.
    `;
  
    const emailBody = `
    <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Bayti Eco Email</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        line-height: 1.6;
                        color: #333333;
                        margin: 0;
                        padding: 0;
                        background-color: #f4f4f4;
                    }
                    .container {
                        max-width: 600px;
                        margin: 0 auto;
                        padding: 20px;
                        background-color: #ffffff;
                    }
                    .header {
                        background-color: #4a4af4;
                        color: #ffffff;
                        padding: 20px;
                        text-align: center;
                    }
                    .logo {
                        max-width: 150px;
                        height: auto;
                    }
                    .content {
                        padding: 20px;
                    }
                    .footer {
                        background-color: #f0f0f0;
                        color: #666666;
                        text-align: center;
                        padding: 10px;
                        font-size: 12px;
                    }
                    .button {
                        display: inline-block;
                        padding: 10px 20px;
                        background-color: #4a4af4;
                        color: #ffffff;
                        text-decoration: none;
                        border-radius: 5px;
                        margin-top: 15px;
                    }
                    .social-icons {
                        margin-top: 15px;
                    }
                    .social-icons a {
                        display: inline-block;
                        margin: 0 10px;
                    }
                    .social-icons img {
                        width: 24px;
                        height: 24px;
                    }
                </style>
            </head>
            <body>
            <div class="container">
              <div class="header">
                  <img src="https://bayti.eco/images/BaytiEcoLogo.png" alt="Bayti Eco Logo" class="logo">
                  <h1>Bayti Eco</h1>
              </div>
              <div class="content">
                  <h2>Hello ,</h2>
                  <p>[Your Carbon Footprint Results,
                  Please find attached a detailed PDF report of your carbon footprint results.]</p>

                  <p>Stay sustainable!</p>
                  <a href="https://bayti.eco" class="button">Visit Our Website</a>
              </div>
      
            <div class="footer">
                <p>© 2024 Bayti Eco. All rights reserved.</p>
                <p>You're receiving this email because you subscribed to our newsletter. If you no longer wish to receive these emails, you can <a href="#">unsubscribe here</a>.</p>
                <div class="social-icons">
                    <a href="#"><img src="https://bayti.eco/images/facebook-icon.png" alt="Facebook"></a>
                    <a href="#"><img src="https://bayti.eco/images/twitter-icon.png" alt="Twitter"></a>
                    <a href="#"><img src="https://bayti.eco/images/instagram-icon.png" alt="Instagram"></a>
                    <a href="#"><img src="https://bayti.eco/images/linkedin-icon.png" alt="LinkedIn"></a>
                </div>
            </div>
        </div>
    </body>
    </html>
    `;
    console.log('Sending email with data:', { to: email, subject: 'Your Carbon Footprint Results', html: emailBody, attachments: [{ filename: 'carbon_footprint_results.pdf', content: pdfAttachment.split(',')[1], encoding: 'base64' }] });

    try {

      const response = await sendEmail({
        sender:'results@bayti.eco',
        to: email,
        text: emailText,
        subject: 'Your Carbon Footprint Results',
        html: emailBody,
  
        attachments: [
          {
            filename: 'carbon_footprint_results.pdf',
            content: pdfAttachment.split(',')[1],
          
          },
        ],

      }).unwrap();
    } catch (error) {
      console.error('Failed to send email:', error);
      throw error;
    }
  };

  const handleCalculate = async (e) => {
    e.preventDefault();
    const calculatedResults = calculateEmissions();
    setResults(calculatedResults);
    setStep(6);
    
    if (formData.email) {
      try {
        // Wait for the next render cycle to ensure the results are displayed
        setTimeout(async () => {
          await sendResultsEmail(formData.email, calculatedResults);
        }, 0);
      } catch (error) {
        console.error('Failed to send email:', error);
      }
    }
  };

  const calculateEmissions = () => {
    const {
      electricityUsage,
      renewableEnergy,
      naturalGasUsage,
      waterConsumption,
      wasteGenerated,
      vehicleFleet,
      businessTravel,
      airConditioning,
    } = formData;

    const electricityEmissions =
      (parseFloat(electricityUsage) || 0) *
      emissionFactors.electricity *
      (1 - (parseFloat(renewableEnergy) || 0) / 100);
    const naturalGasEmissions =
      (parseFloat(naturalGasUsage) || 0) * emissionFactors.naturalGas;
    const waterEmissions =
      (parseFloat(waterConsumption) || 0) * emissionFactors.water;
    const wasteEmissions =
      (parseFloat(wasteGenerated) || 0) * emissionFactors.waste;
    const vehicleEmissions =
      (parseFloat(vehicleFleet) || 0) * emissionFactors.vehicle;
    const travelEmissions =
      (parseFloat(businessTravel) || 0) * emissionFactors.businessTravel.shortHaulFlight;
    const acEmissions =
      (parseFloat(airConditioning) || 0) * emissionFactors.airConditioning;

    const totalEmissions =
      (electricityEmissions +
        naturalGasEmissions +
        waterEmissions +
        wasteEmissions +
        vehicleEmissions +
        travelEmissions +
        acEmissions) /
      1000;

    console.log('Total Emissions:', totalEmissions);
    console.log('Breakdown:', {
      electricity: electricityEmissions / 1000,
      naturalGas: naturalGasEmissions / 1000,
      water: waterEmissions / 1000,
      waste: wasteEmissions / 1000,
      vehicles: vehicleEmissions / 1000,
      travel: travelEmissions / 1000,
      airConditioning: acEmissions / 1000,
    });

    return {
      totalEmissions: totalEmissions.toFixed(2),
      breakdown: {
        electricity: electricityEmissions / 1000,
        naturalGas: naturalGasEmissions / 1000,
        water: waterEmissions / 1000,
        waste: wasteEmissions / 1000,
        vehicles: vehicleEmissions / 1000,
        travel: travelEmissions / 1000,
        airConditioning: acEmissions / 1000,
      },
    };
  };

  const handleStartOver = () => {
    setStep(1);
    setFormData(initialFormData);
    setResults(null);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="companyName">Company Name</Label>
              <Input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Enter your company name"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email Address</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email address"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="industry">Industry</Label>
              <Select name="industry" value={formData.industry} onValueChange={(value) => handleInputChange({ target: { name: 'industry', value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Industry" />
                </SelectTrigger>
                <SelectContent>
                  {industries.map((industry) => (
                    <SelectItem key={industry} value={industry}>
                      {industry}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="employeeCount">Number of Employees</Label>
              <Input
                type="number"
                id="employeeCount"
                name="employeeCount"
                value={formData.employeeCount}
                onChange={handleInputChange}
                placeholder="Enter the number of employees"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="annualRevenue">Annual Revenue (in AED)</Label>
              <Input
                type="number"
                id="annualRevenue"
                name="annualRevenue"
                value={formData.annualRevenue}
                onChange={handleInputChange}
                placeholder="Enter your annual revenue"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="electricityUsage">
                Annual Electricity Usage (kWh)
              </Label>
              <Input
                type="number"
                id="electricityUsage"
                name="electricityUsage"
                value={formData.electricityUsage}
                onChange={handleInputChange}
                placeholder="Enter your annual electricity usage"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="renewableEnergy">Renewable Energy Percentage</Label>
              <Input
                type="number"
                id="renewableEnergy"
                name="renewableEnergy"
                value={formData.renewableEnergy}
                onChange={handleInputChange}
                placeholder="Enter the percentage of renewable energy used"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="naturalGasUsage">
                Annual Natural Gas Usage (m³)
              </Label>
              <Input
                type="number"
                id="naturalGasUsage"
                name="naturalGasUsage"
                value={formData.naturalGasUsage}
                onChange={handleInputChange}
                placeholder="Enter your annual natural gas usage"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="waterConsumption">
                Annual Water Consumption (m³)
              </Label>
              <Input
                type="number"
                id="waterConsumption"
                name="waterConsumption"
                value={formData.waterConsumption}
                onChange={handleInputChange}
                placeholder="Enter your annual water consumption"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="wasteGenerated">
                Annual Waste Generated (tonnes)
              </Label>
              <Input
                type="number"
                id="wasteGenerated"
                name="wasteGenerated"
                value={formData.wasteGenerated}
                onChange={handleInputChange}
                placeholder="Enter the amount of waste generated annually"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="vehicleFleet">
                Annual Vehicle Fleet Mileage (km)
              </Label>
              <Input
                type="number"
                id="vehicleFleet"
                name="vehicleFleet"
                value={formData.vehicleFleet}
                onChange={handleInputChange}
                placeholder="Enter your fleet's annual mileage"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="businessTravel">Annual Business Travel (km)</Label>
              <Input
                type="number"
                id="businessTravel"
                name="businessTravel"
                value={formData.businessTravel}
                onChange={handleInputChange}
                placeholder="Enter your annual business travel distance"
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="airConditioning">
                Number of Air Conditioning Units
              </Label>
              <Input
                type="number"
                id="airConditioning"
                name="airConditioning"
                value={formData.airConditioning}
                onChange={handleInputChange}
                placeholder="Enter the number of air conditioning units"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderResults = () => {
    if (!results) return null;

    const breakdownData = Object.entries(results.breakdown)
      .map(([key, value]) => ({
        name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim(),
        value: parseFloat(value.toFixed(2)),
      }))
      .filter((item) => item.value > 0)
      .sort((a, b) => b.value - a.value);

    const totalEmissions = breakdownData.reduce((sum, item) => sum + item.value, 0);

    const COLORS = [
      '#3B82F6',
      '#10B981',
      '#F59E0B',
      '#EF4444',
      '#8B5CF6',
      '#EC4899',
      '#14B8A6',
      '#6366F1',
    ];

    const recommendations = [
      {
        icon: MonitorCog,
        title: 'Increase Renewable Energy',
        description: 'Consider increasing your renewable energy usage to reduce electricity-related emissions.',
        color: '#F59E0B',
      },
      {
        icon: Factory,
        title: 'Optimize Energy Efficiency',
        description: 'Implement energy-efficient practices to lower natural gas consumption.',
        color: '#6366F1',
      },
      {
        icon: Droplet,
        title: 'Conserve Water',
        description: 'Explore water conservation methods to decrease water-related emissions.',
        color: '#3B82F6',
      },
      {
        icon: Trash2,
        title: 'Improve Waste Management',
        description: 'Enhance waste management practices to minimize waste generation and increase recycling.',
        color: '#10B981',
      },
      {
        icon: Car,
        title: 'Optimize Vehicle Fleet',
        description: 'Optimize your vehicle fleet and encourage eco-friendly transportation options.',
        color: '#EF4444',
      },
      {
        icon: Plane,
        title: 'Reduce Business Travel',
        description: 'Utilize video conferencing to reduce business travel when possible.',
        color: '#8B5CF6',
      },
      {
        icon: Building,
        title: 'Upgrade Air Conditioning',
        description: 'Upgrade to more energy-efficient air conditioning units and properly maintain them.',
        color: '#EC4899',
      },
    ];

    return (
      <div ref={resultsRef} className="space-y-8">
        <Card>
          <CardHeader>
            <CardTitle>Carbon Footprint Results</CardTitle>
            <CardDescription>
              Total Emissions: {totalEmissions.toFixed(2)} tonnes CO2e per year
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-semibold mb-4">Emissions Breakdown</h3>
                <ChartContainer
                  config={breakdownData.reduce((acc, item) => ({
                    ...acc,
                    [item.name]: {
                      label: item.name,
                      color: COLORS[breakdownData.indexOf(item) % COLORS.length],
                    },
                  }), {})}
                >
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={breakdownData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip content={<ChartTooltipContent />} />
                      <Legend />
                      <Bar dataKey="value" fill="#3B82F6">
                        {breakdownData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        <LabelList dataKey="value" position="top" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">Emissions Distribution</h3>
                <ChartContainer
                  config={breakdownData.reduce((acc, item) => ({
                    ...acc,
                    [item.name]: {
                      label: item.name,
                      color: COLORS[breakdownData.indexOf(item) % COLORS.length],
                    },
                  }), {})}
                >
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={breakdownData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                      >
                        {breakdownData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip content={<ChartTooltipContent />} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </ChartContainer>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recommendations</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {recommendations.map((rec, index) => (
                <Card key={index}>
                  <CardContent className="p-6 flex flex-col items-center text-center">
                    <div className="w-12 h-12 rounded-full flex items-center justify-center mb-4" style={{ backgroundColor: rec.color }}>
                      <rec.icon className="text-white" size={24} />
                    </div>
                    <h4 className="text-lg font-semibold mb-2">{rec.title}</h4>
                    <p className="text-sm text-gray-600">{rec.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>

        <AnimatePresence>
          {emailSent && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Card>
                <CardContent className="p-6 flex items-center">
                  <div className="mr-4 bg-green-100 rounded-full p-2">
                    <Mail className="text-green-600" size={24} />
                  </div>
                  <div className="flex-grow">
                    <h4 className="text-lg font-semibold">Results Sent!</h4>
                    <p className="text-sm text-gray-600">Your carbon footprint results have been sent to your email address.</p>
                  </div>
                  <CheckCircle className="text-green-600" size={24} />
                </CardContent>
              </Card>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <GetStarted />
        </motion.div>
      </div>
    );
  };

  return (
    <Container className="py-8">
      <Card className="max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle>Business Carbon Calculator</CardTitle>
          <CardDescription>
            Measure your company&apos;s carbon footprint in just 5 minutes and
            take steps towards sustainability.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Progress value={(step / 5) * 100} className="mb-8" />

          <form onSubmit={step === 5 ? handleCalculate : handleNext}>
            {step <= 5 ? (
              <>
                {renderStep()}
                <div className="flex justify-between mt-8">
                  {step > 1 && (
                    <Button type="button" variant="outline" onClick={handleBack}>
                      <ChevronLeft className="mr-2 h-4 w-4" /> Back
                    </Button>
                  )}
                  <Button type="submit">
                    {step < 5 ? (
                      <>
                        Next <ChevronRight className="ml-2 h-4 w-4" />
                      </>
                    ) : (
                      'Calculate Emissions'
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {renderResults()}
                <div className="mt-8">
                  <Button onClick={handleStartOver}>Start Over</Button>
                </div>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CarbonCalculatorPage;