import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Container } from '../components/ui/layout';
import { Button } from '../components/ui/button';
import { useGetBlogByIdQuery } from '../app/features/blog/blogApi';


const BlogDetails = () => {
  const { slug } = useParams();

  const { data: blogData, error } = useGetBlogByIdQuery(slug);


  // Check if blogData is undefined and handle it
  if (!blogData) {
    return (
      <Container className="py-8">
        <h1 className="text-2xl font-bold">Blog Not Found</h1>
        <p>The blog you are looking for does not exist.</p>
        <Button asChild variant="link" className="mt-4">
          <Link to="/blog">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Blogs
          </Link>
        </Button>
      </Container>
    );
  }

  return (
    <Container className="py-8">
      <Button asChild variant="link" className="mb-4">
        <Link to="/blog">
          <ArrowLeft className="mr-2 h-4 w-4" /> All Blogs
        </Link>
      </Button>
      <motion.h1
        className="text-4xl font-bold mb-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {blogData.title}
      </motion.h1>
      <motion.img
        src={blogData.featured_image}
        alt={blogData.title}
        className="w-full h-auto rounded-lg mb-8 shadow-lg"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.3 }}
      />
      <motion.p
      className="text-lg mb-8"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.4 }}
      dangerouslySetInnerHTML={{ __html: blogData.content }} // Use dangerouslySetInnerHTML here
      />
    </Container>
  );
};

export default BlogDetails;