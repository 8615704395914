import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Lightbulb, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";

const FeatureHighlights = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const highlights = [
    {
      icon: Globe,
      color: 'text-brown-600',
      title: 'Climate Conscious Business',
      description: 'Your customers to reduce carbon footprints with every visit.',
      buttonText: 'Learn More',
      link: '/eco-business',
    },
    {
      icon: Lightbulb,
      color: 'text-yellow-500',
      title: 'Have a great idea?',
      description: "Our enemy is carbon, and if there are features that you think would help you, we'd love hear what they are.",
      buttonText: 'Get in touch',
      link: '/contact',
    },
    {
      icon: Users,
      color: 'text-purple-600',
      title: "Bayti Teams - Counter your team's carbon footprint",
      description: 'Make an impact and counter your footprint.',
      buttonText: 'Learn more',
      link: '/bayti-teams',
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {highlights.map((highlight, index) => (
        <motion.div
          key={index}
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: index * 0.1 }}
        >
          <Card className="h-full flex flex-col">
            <CardHeader>
              <CardTitle className="flex items-center">
                <highlight.icon className={`mr-2 h-5 w-5 ${highlight.color}`} />
                {highlight.title}
              </CardTitle>
              <CardDescription>{highlight.description}</CardDescription>
            </CardHeader>
            <CardFooter className="mt-auto">
              <Button asChild className="w-full">
                <Link to={highlight.link}>
                  {highlight.buttonText}
                </Link>
              </Button>
            </CardFooter>
          </Card>
        </motion.div>
      ))}
    </div>
  );
};

export default FeatureHighlights;