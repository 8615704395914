import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profileApi } from '../app/features/profile/profileApi';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { motion } from 'framer-motion';
import HeaderDash from '../components/HeaderDash';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { toast } from 'react-toastify';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator
} from "../components/ui/dropdown-menu";
import { Eye, Edit, Trash2, MoreHorizontal } from "lucide-react";


const StatusBadge = ({ status }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Active':
        return "bg-green-100 text-green-800";
      case 'Inactive':
        return "bg-red-100 text-red-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <Badge className={`${getStatusStyle(status)} font-semibold px-3 py-1 rounded-full`}>
      {status}
    </Badge>
  );
};

const AdminUsers = () => {
  const dispatch = useDispatch();
  const [getAllProfiles] = profileApi.endpoints.getAllProfiles.useLazyQuery();
  const [updateProfile] = profileApi.endpoints.updateProfile.useMutation();
  const profiles = useSelector((state) => state.profile.allProfiles);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    getAllProfiles();
  }, [getAllProfiles]);

  const handleUpdateStatus = async (newStatus, profileId) => {
    try {
      const formData = new FormData();
      formData.append('status', newStatus);
      formData.append('user_id', profileId);
      await updateProfile(formData);
      toast.success('User status updated successfully!');
      getAllProfiles();
    } catch (error) {
      toast.error('Failed to update user status.');
      console.error('Error updating user status:', error);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "profile_name",
      header: "Name",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "business_name",
      header: "Business Name",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "webpage_link",
      header: "Webpage",
      cell: info => info.getValue() ? (
        <a href={info.getValue()} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
          Link
        </a>
      ) : 'N/A',
    },
    {
      accessorKey: "carbon",
      header: "Carbon",
      cell: info => info.getValue() || 'N/A',
    },
    {
        accessorKey: "about",
        header: "About",
        cell: info => info.getValue() || 'N/A',
      },
    {
      accessorKey: "plastic",
      header: "Plastic",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "co2",
      header: "CO2",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "active_plan",
      header: "Active Plan",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-24 p-0">
              {row.getValue("status")}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {["Active", "Inactive"].map((status) => (
              <DropdownMenuItem
                key={status}
                onClick={() => handleUpdateStatus(status, row.original.id)}
              >
                {status}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleView(row.original)}>
              <Eye className="mr-2 h-4 w-4" />
              View
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleEdit(row.original)}>
              <Edit className="mr-2 h-4 w-4" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              <Trash2 className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ], [dispatch]); // Remove handleUpdateStatus from dependencies

  const table = useReactTable({
    data: profiles,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const handleView = (profile) => {
    // Implement view functionality
    console.log("View profile:", profile);
  };

  const handleEdit = (profile) => {
    // Implement edit functionality
    console.log("Edit profile:", profile);
  };

  const handleDelete = (profileId) => {
    // Implement delete functionality
    console.log("Delete profile:", profileId);
  };

  return (
    <motion.div
      className="min-h-screen bg-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeaderDash />
      <main className="container mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>User List</CardTitle>
            <div className="flex items-center justify-between">
              <Input
                placeholder="Search all columns..."
                value={globalFilter ?? ''}
                onChange={e => setGlobalFilter(String(e.target.value))}
                className="max-w-sm"
              />
              <Select
                value={table.getState().pagination.pageSize}
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select rows per page" />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows.map(row => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="flex items-center justify-between space-x-2 py-4">
              <div>
                Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, table.getFilteredRowModel().rows.length)} of {table.getFilteredRowModel().rows.length} results
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  Previous
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  Next
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </main>
    </motion.div>
  );
};

export default AdminUsers;