import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAllPlansOrdersQuery } from '../app/features/plan/planApi';
import { getAllPlanOrders } from '../app/features/plan/planSlice';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Badge } from "../components/ui/badge";
import { motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import HeaderDash from '../components/HeaderDash';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { toast } from 'react-toastify';

const BusinessOwnerBadge = ({ name }) => {
  const colors = [
    'bg-blue-100 text-blue-800 hover:bg-blue-200',
    'bg-green-100 text-green-800 hover:bg-green-200',
    'bg-purple-100 text-purple-800 hover:bg-purple-200',
    'bg-pink-100 text-pink-800 hover:bg-pink-200',
    'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
    'bg-indigo-100 text-indigo-800 hover:bg-indigo-200',
    'bg-red-100 text-red-800 hover:bg-red-200',
    'bg-teal-100 text-teal-800 hover:bg-teal-200'
  ];

  const getColorForName = (name) => {
    if (!name) return colors[0];
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  };

  const colorClass = getColorForName(name);

  return (
    <Badge className={`${colorClass} font-semibold px-3 py-1 rounded-full transition-colors duration-200`}>
      {name || 'Bayti Eco'}
    </Badge>
  );
};

const AdminPlan = () => {
  const dispatch = useDispatch();
  const { data: planOrdersData, error: getAllError, isLoading: isFetching, refetch } = useGetAllPlansOrdersQuery();
  const planOrders = useSelector((state) => state.plan.allPlanOrders || []);

  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (planOrdersData) {
      dispatch(getAllPlanOrders(planOrdersData));
      setIsLoading(false);
    } else if (getAllError) {
      setIsLoading(false);
      toast.error('Failed to fetch plan orders. Please try again.');
    }
  }, [planOrdersData, getAllError, dispatch]);

  const columns = useMemo(() => [
    {
      accessorKey: "id",
      header: "Order ID",
      cell: info => info.getValue(),
    },
    {
      accessorKey: "profile_name",
      header: "Customer Name",
      cell: info => info.getValue() || 'N/A',
    },
    {
      accessorKey: "business_name",
      header: "Business Owner",
      cell: ({ row }) => <BusinessOwnerBadge name={row.getValue("business_name")} />,
    },
    {
      accessorKey: "plan_name",
      header: "Plan Name",
      cell: info => info.getValue(),
    },
    {
      accessorKey: "price",
      header: "Price",
      cell: ({ row }) => `$${parseFloat(row.getValue("price")).toFixed(2)}`,
    },
    {
      accessorKey: "tree",
      header: "Trees",
    },
    {
      accessorKey: "bottles",
      header: "Bottles",
    },
    {
      accessorKey: "co2",
      header: "CO2 Offset",
      cell: ({ row }) => `${row.getValue("co2")} kg`,
    },
    {
      accessorKey: "created_at",
      header: "Date Created",
      cell: ({ row }) => new Date(row.getValue("created_at")).toLocaleString(),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => toggleRowExpansion(row.original.id)}
        >
          {expandedRows[row.original.id] ? <ChevronUp /> : <ChevronDown />}
        </Button>
      ),
    },
  ], [expandedRows]);

  const table = useReactTable({
    data: planOrders,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const toggleRowExpansion = (orderId) => {
    setExpandedRows(prev => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const renderExpandedRow = (row) => (
    <div className="bg-blue-50 p-4 rounded-lg shadow-inner">
      <Table>
        <TableBody>
          <TableRow className="bg-blue-100 hover:bg-blue-200 transition-colors">
            <TableCell className="font-semibold">Full Name</TableCell>
            <TableCell>{row.full_name}</TableCell>
          </TableRow>
          <TableRow className="bg-blue-100 hover:bg-blue-200 transition-colors">
            <TableCell className="font-semibold">Carbon</TableCell>
            <TableCell>{row.carbon} kg</TableCell>
          </TableRow>
          <TableRow className="bg-blue-100 hover:bg-blue-200 transition-colors">
            <TableCell className="font-semibold">Team Members</TableCell>
            <TableCell>{row.teamMembers}</TableCell>
          </TableRow>
          <TableRow className="bg-blue-100 hover:bg-blue-200 transition-colors">
            <TableCell className="font-semibold">Members</TableCell>
            <TableCell>{row.members}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllError) {
    return <div>Error: {getAllError.message}</div>;
  }

  return (
    <motion.div
      className="min-h-screen bg-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeaderDash />
      <main className="container mx-auto py-6">
        <Card>
          <CardHeader>
            <CardTitle>Plan Orders</CardTitle>
            <div className="flex items-center justify-between">
              <Input
                placeholder="Search all columns..."
                value={globalFilter ?? ''}
                onChange={e => setGlobalFilter(String(e.target.value))}
                className="max-w-sm"
              />
              <Select
                value={table.getState().pagination.pageSize}
                onValueChange={(value) => {
                  table.setPageSize(Number(value));
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select rows per page" />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CardHeader>
          <CardContent>
            {Array.isArray(planOrders) && planOrders.length > 0 ? (
              <>
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map(headerGroup => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <TableHead key={header.id}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableRow>
                          {row.getVisibleCells().map(cell => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        {expandedRows[row.original.id] && (
                          <TableRow>
                            <TableCell colSpan={columns.length} className="p-0">
                              <div className="py-4 px-2">
                                {renderExpandedRow(row.original)}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
                <div className="flex items-center justify-between space-x-2 py-4">
                  <div>
                    Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, table.getFilteredRowModel().rows.length)} of {table.getFilteredRowModel().rows.length} results
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div>No plan orders found.</div>
            )}
          </CardContent>
        </Card>
      </main>
    </motion.div>
  );
};

export default AdminPlan;