import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, User, ChevronRight, Leaf, Building2, UserCircle2 } from 'lucide-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Button } from "./ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Container } from "./ui/layout";
import { useGetAllBlogsQuery,useGetAllCategoriesQuery } from '../app/features/blog/blogApi';

const BlogContainer = ({ children }) => (
  <Container className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
    {children}
  </Container>
);

const Title = ({ children }) => (
  <h1 className="text-4xl font-extrabold text-center text-gray-900 sm:text-5xl md:text-6xl mb-8">
    {children}
  </h1>
);

const CategoryFilter = ({ categories, activeCategory, setActiveCategory }) => (
  <div className="flex justify-center flex-wrap gap-2 mb-8">
    {categories.map((category) => (
      <Button
        key={category}
        variant={activeCategory === category ? "default" : "outline"}
        onClick={() => setActiveCategory(category)}
      >
        {category}
      </Button>
    ))}
  </div>
);

const FeaturedPost = ({ post }) => (
  <Card className="mb-8 overflow-hidden">
    <div className="md:flex">
      <div className="md:flex-shrink-0 md:w-1/2">
        <LazyLoadImage
          className="h-full w-full object-cover md:h-full md:w-full"
          src={post.featured_image}
          alt={post.title}
          effect="blur"
        />
      </div>
      <div className="p-8 md:w-1/2">
        <CardHeader>
          <CardTitle className="text-3xl font-bold">{post.title}</CardTitle>
          <CardDescription>{post.excerpt}</CardDescription>
        </CardHeader>
        <CardFooter>
          <Button asChild>
            <Link to={`/blog/${createSlug(post.title)}`}>
              Read More <ChevronRight className="ml-2 h-4 w-4" />
            </Link>
          </Button>
        </CardFooter>
      </div>
    </div>
  </Card>
);

const BlogGrid = ({ posts, visiblePosts }) => (
  <motion.div layout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    <AnimatePresence>
      {posts.slice(1, visiblePosts).map((post) => (
        <motion.div
          key={post.id}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
          layout
        >
          <BlogCard post={post} />
        </motion.div>
      ))}
    </AnimatePresence>
  </motion.div>
);

const createSlug = (title) => {
  return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
};

const BlogCard = ({ post }) => (
  <Card className="h-full flex flex-col">
    <LazyLoadImage
      className="h-48 w-full object-cover"
      src={post.featured_image}
      alt={post.title}
      effect="blur"
    />
    <CardHeader>
      <CardTitle>{post.title}</CardTitle>
      <div className="flex justify-between text-sm text-gray-500">
        <span className="flex items-center">
          <Calendar className="mr-1 h-4 w-4" />  {new Date(post.created_at).toLocaleString()} 
        </span>
        <span className="flex items-center">
          <User className="mr-1 h-4 w-4" /> {post.author_name}
        </span>
      </div>
    </CardHeader>
    <CardContent>
      <p className="text-gray-600">{post.excerpt}</p>
    </CardContent>
    <CardFooter className="mt-auto">
      <Button asChild variant="link">
        <Link to={`/blog/${post.slug}`}>
          Read More <ChevronRight className="ml-2 h-4 w-4" />
        </Link>
      </Button>
    </CardFooter>
  </Card>
);

const LoadMoreButton = ({ onClick }) => (
  <div className="text-center mt-8">
    <Button onClick={onClick}>Load More</Button>
  </div>
);

const Blog = () => {
  const { data: blogs, blogsError, blogsLoading } = useGetAllBlogsQuery(); // Use the query to fetch blogs
  const { data: categoriesData, error: categoriesError, isLoading: categoriesLoading } = useGetAllCategoriesQuery(); // Fetch categories

  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [activeCategory, setActiveCategory] = useState('All');
 

  useEffect(() => {
    setPosts(blogs);
  }, [blogs]);

  if (blogsLoading || categoriesLoading) return <div>Loading...</div>; // Handle loading state
  if (blogsError) return <div>Error fetching blogs: {blogsError.message}</div>; // Handle blogs error
  if (categoriesError) return <div>Error fetching categories: {categoriesError.message}</div>; // Handle categories error
  const categories = ['All', ...categoriesData.map(category => category.category_name)]; // Use fetched category names
  const filteredPosts = activeCategory === 'All'
    ? posts
    : posts.filter((post) => post.category_name === activeCategory);

  const loadMore = () => {
    setVisiblePosts((prevVisible) => prevVisible + 3);
  };

  return (
    <BlogContainer>
      <Helmet>
        <title>Bayti Blog: Sustainability in the UAE and Beyond</title>
        <meta
          name="description"
          content="Explore sustainability insights for the UAE, businesses, and individuals. Learn about eco-friendly practices, green innovations, and how to reduce your carbon footprint."
        />
        <meta
          name="keywords"
          content="sustainability, UAE, eco-friendly, green business, carbon footprint, sustainable living"
        />
        <link rel="canonical" href="https://www.bayti.eco/blog" />
      </Helmet>

      <Title>Bayti Blog: Sustainability Insights</Title>

      <CategoryFilter
        categories={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />

      {activeCategory === 'All' && filteredPosts.length > 0 && (
        <FeaturedPost post={filteredPosts[0]} />
      )}

      <BlogGrid posts={filteredPosts} visiblePosts={visiblePosts} />

      {visiblePosts < filteredPosts.length && (
        <LoadMoreButton onClick={loadMore} />
      )}
    </BlogContainer>
  );
};

export { Blog };