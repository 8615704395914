import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Trees, Droplet, Globe, CloudSun } from 'lucide-react';
import { useAddPlanOrderMutation } from '../app/features/plan/planApi';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";

const PayNow = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('data');
  const plans = useSelector((state) => state.plan.allplans);
  const profile = useSelector(state => state.profile.profile);
  const user = useSelector(state => state.auth.user);
  const [addPlanOrder, { isLoading: createLoading, error: createError }] = useAddPlanOrderMutation();
  const navigate = useNavigate();
  const [planDetails, setPlanDetails] = useState({});
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (plans && id) {
      const foundPlan = plans.find((plan) => plan.id === parseInt(id));
      setPlanDetails(foundPlan || {});
    }
  }, [plans, id]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handlePaySubmit = async (data) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      formData.append('plan_id', planDetails.id);
      formData.append('active_plan', planDetails.id);
      formData.append('plan_name', planDetails.name);
      if (user != null || profile != null) {
        formData.append('user_id', user.id);
        formData.append('full_name', user.user_metadata.full_name);
        formData.append('profile_id', profile.id);
        formData.append('profile_name', profile.profile_name);
      }
      formData.append('tree', planDetails.tree);
      formData.append('plastic', planDetails.plastic);
      formData.append('carbon', planDetails.carbon);
      formData.append('co2', planDetails.co2);

      await addPlanOrder(formData).unwrap();

      toast.success("Subscription plan successfully added");
      navigate('/the-bayti');
      closeModal();
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Purchase Plan</h2>
  
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Plan ID: {planDetails?.id}</CardTitle>
          <CardDescription>Plan Name: {planDetails?.name}</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="mb-4">
            You are on the £{planDetails?.price} /{planDetails?.duration_type} {planDetails?.name} plan
            Countering {planDetails?.members} of {planDetails?.total_members} team members
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="flex items-center">
              <Trees className="mr-2 text-green-500" /> Trees: {planDetails?.tree}
            </div>
            <div className="flex items-center">
              <Droplet className="mr-2 text-blue-500" /> Bottles: {planDetails?.plastic}
            </div>
            <div className="flex items-center">
              <Globe className="mr-2 text-purple-500" /> Carbon: {planDetails?.carbon}
            </div>
            <div className="flex items-center">
              <CloudSun className="mr-2 text-yellow-500" /> CO2: {planDetails?.co2}
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button onClick={openModal}>Pay Now</Button>
        </CardFooter>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Payment</DialogTitle>
            <DialogDescription>
              Please review and confirm your payment details.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(handlePaySubmit)}>
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="price">Price</Label>
                <Input
                  id="price"
                  type="text"
                  {...register('price', { required: "Price is Required" })}
                  defaultValue={planDetails?.price}
                  readOnly
                />
              </div>
              {/* You can add more form fields here if needed */}
            </div>
            <DialogFooter className="mt-6">
              <Button type="submit" disabled={createLoading}>
                {createLoading ? "Processing..." : "Confirm Payment"}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PayNow;