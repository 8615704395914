import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Globe, Megaphone, ArrowRight, X } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "./ui/dialog";
import { Link } from 'react-router-dom';

const SneakPeek = () => {
  const [expandedBox, setExpandedBox] = useState(null);

  const boxVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hover: { scale: 1.05 },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const boxContent = [
    {
      icon: <Search className="w-12 h-12 text-green-500" />,
      title: 'Identify',
      description: "We'll help you understand your emissions from your business.",
      link: 'Carbon Calculator',
      expandedContent: 'Get a detailed breakdown of your carbon footprint across various aspects of your business. Our advanced algorithms provide accurate estimations and actionable insights.',
    },
    {
      icon: <Globe className="w-12 h-12 text-blue-500" />,
      title: 'Offset',
      description: 'Counter your emissions by supporting global humanitarian carbon projects, tree planting, and ocean plastic removal.',
      link: 'Our Projects',
      expandedContent: 'Explore a curated selection of verified carbon offset projects. From reforestation to renewable energy, make a real impact while supporting local communities.',
    },
    {
      icon: <Megaphone className="w-12 h-12 text-purple-500" />,
      title: 'Showcase',
      description: 'Share your impact from your Impact Profile to the world',
      link: 'Impact Profile',
      expandedContent: 'Create a stunning visual representation of your sustainability journey. Use our customizable templates to highlight your achievements and inspire others.',
    },
  ];

  return (
    <section className="py-24 bg-gradient-to-br from-gray-50 to-gray-100">
      <motion.div 
        className="container mx-auto px-4"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {boxContent.map((box, index) => (
            <motion.div key={index} variants={boxVariants} whileHover="hover">
              <Card className="h-full flex flex-col">
                <CardHeader>
                  <div className="mb-4">{box.icon}</div>
                  <CardTitle>{box.title}</CardTitle>
                  <CardDescription>{box.description}</CardDescription>
                </CardHeader>
                <CardContent className="flex-grow">
                  {/* Remove this paragraph to avoid repetition */}
                </CardContent>
                <CardFooter className="flex justify-between items-center">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="ghost" className="p-0 hover:bg-transparent">
                        Learn More <ArrowRight className="ml-2 h-4 w-4" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>{box.title}</DialogTitle>
                        <DialogDescription>{box.expandedContent}</DialogDescription>
                      </DialogHeader>
                      <div className="mt-4">
                        <Link to="/auth-page">
                          <Button className="w-full">Get Started</Button>
                        </Link>
                      </div>
                    </DialogContent>
                  </Dialog>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default SneakPeek;