import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
    static create(value) {
        const node = super.create();
        node.setAttribute('src', value);
        node.setAttribute('style', 'max-width: 100%; height: auto; position: relative;'); // Set default styles

        // Create a resize handle
        const resizeHandle = document.createElement('div');
        resizeHandle.className = 'resize-handle';
        resizeHandle.style.width = '10px';
        resizeHandle.style.height = '10px';
        resizeHandle.style.background = 'red';
        resizeHandle.style.position = 'absolute';
        resizeHandle.style.bottom = '0';
        resizeHandle.style.right = '0';
        resizeHandle.style.cursor = 'nwse-resize';

        node.appendChild(resizeHandle);

        // Add event listeners for resizing
        resizeHandle.addEventListener('mousedown', (e) => {
            e.preventDefault();
            const img = node;
            const startX = e.clientX;
            const startY = e.clientY;
            const startWidth = img.offsetWidth;
            const startHeight = img.offsetHeight;

            const doDrag = (e) => {
                img.style.width = `${startWidth + e.clientX - startX}px`;
                img.style.height = `${startHeight + e.clientY - startY}px`;
            };

            const stopDrag = () => {
                window.removeEventListener('mousemove', doDrag);
                window.removeEventListener('mouseup', stopDrag);
            };

            window.addEventListener('mousemove', doDrag);
            window.addEventListener('mouseup', stopDrag);
        });

        return node;
    }

    static formats(node) {
        return node.getAttribute('src');
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);