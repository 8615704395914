import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, CreditCard, X, Check } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Badge } from "./ui/badge";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
 
import { setDefaultMethod } from '../app/features/payment/paymentSlice';
import { useAddPaymentMethodMutation, useFetchPaymentMethodsQuery, useSetDefaultPaymentMethodMutation  } from '../app/features/payment/paymentApi';
import { useLazyGetProfileQuery } from '../app/features/profile/profileApi';
 

const stripePromise = loadStripe("pk_test_51PpvRt1JIVewXWEmAdxDryiF3gfYN23upqU1A2Ws8UChQMMjYGKgXtyM4ROTgFFP7zLKaRV7JdZ37klu6Yjdeq1U00iq4wnfuG");

const PaymentMethod = () => {
  const auth = useSelector((store) => store.auth);
  const profileId = useSelector((store) => store.profile?.profile?.id);
  const dispatch = useDispatch();
  const { data: paymentMethods = [], refetch } = useFetchPaymentMethodsQuery(profileId, { skip: !profileId });
  const [addPaymentMethod] = useAddPaymentMethodMutation();
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation();
  const defaultMethod = useSelector((state) => state.payment.defaultMethod);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const user_id = auth?.user?.id;
  useEffect(() => {
    if (!profileId) {
      dispatch(useLazyGetProfileQuery({user_id}));
    }
  }, [profileId,user_id, dispatch]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const defaultMethod = paymentMethods.find(method => method.is_default);
      if (defaultMethod) {
        dispatch(setDefaultMethod(defaultMethod.id));
      }
    }
  }, [paymentMethods, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('Stripe error:', error);
      return;
    }

    const newMethod = {
      stripe_payment_method_id: paymentMethod.id,
      card_last4: paymentMethod.card.last4,
      profile_id: profileId,
    };

    await addPaymentMethod(newMethod);
    refetch();
    if (!defaultMethod) {
      dispatch(setDefaultMethod(paymentMethod.id));
      await setDefaultPaymentMethod({ profile_id: profileId, payment_method_id: paymentMethod.id });
    }
    setIsModalOpen(false);
  };

  const setAsDefault = async (id) => {
    dispatch(setDefaultMethod(id));
    await setDefaultPaymentMethod({ profile_id: profileId, payment_method_id: id });
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <h1 className="text-4xl font-bold text-gray-800 mb-2">Settings</h1>
      <p className="text-xl text-gray-600 mb-8">Manage your account and payment methods.</p>

      <Card>
        <CardHeader>
          <CardTitle>Payment Method</CardTitle>
          <CardDescription>Update your billing details and address.</CardDescription>
        </CardHeader>
        <CardContent>
          <h3 className="text-lg font-semibold mb-2">Card details</h3>
          <p className="text-gray-600 mb-4">Select default payment method.</p>
          {paymentMethods.map((method) => (
            <div key={method.id} className="flex justify-between items-center bg-gray-100 p-4 rounded-lg mb-4">
              <div className="flex items-center space-x-4">
                <CreditCard className="text-blue-600" />
                <span>**** **** **** {method.card_last4}</span>
                {defaultMethod === method.id && (
                  <Badge variant="secondary">Default</Badge>
                )}
              </div>
              {defaultMethod !== method.id && (
                <Button variant="outline" size="sm" onClick={() => setAsDefault(method.id)}>
                  Set as default
                </Button>
              )}
            </div>
          ))}
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogTrigger asChild>
              <Button variant="outline">
                <Plus className="mr-2 h-4 w-4" /> Add new payment method
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New Payment Method</DialogTitle>
                <DialogDescription>Enter your card details to add a new payment method.</DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit} className="space-y-4">
                <CardElement />
                <Button type="submit" className="w-full">Add Payment Method</Button>
              </form>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
};

const PaymentMethodWrapper = ({ profileId }) => (
  <Elements stripe={stripePromise}>
    <PaymentMethod profileId={profileId} />
  </Elements>
);

export default PaymentMethodWrapper;