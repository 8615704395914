import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProfileQuery, useUpdateProfileMutation, useGetMemberBusinessProfileQuery } from '../app/features/profile/profileApi';
import { getProfile } from '../app/features/profile/profileSlice';
import { ExternalLink, Upload, Info, CheckCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";
import {toast} from './ui/use-toast'
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";


const ImpactProfile = () => {
  const user_id = useSelector((store) => store.auth.user.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: profileData, isLoading: isProfileLoading, refetch } = useGetProfileQuery({ user_id });
  const { data: memberBusinessProfile } = useGetMemberBusinessProfileQuery(user_id);

  useEffect(() => {
    console.log(profileData)
  }, [profileData])
  
  const profile = useSelector((state) => state.profile.profile);
  const [profileType, setProfileType] = useState('Personal');
  const [profileName, setProfileName] = useState('');
  const [webpageLink, setWebpageLink] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [location, setLocation] = useState('');
  const [aboutYou, setAboutYou] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempProfileImage, setTempProfileImage] = useState(null);
  const [tempCoverImage, setTempCoverImage] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [businessLogo, setBusinessLogo] = useState(null);
  const [tempBusinessLogo, setTempBusinessLogo] = useState(null);

  const [updateProfile, { isLoading: isUpdating, error: updateError }] = useUpdateProfileMutation();

  useEffect(() => {
    if (profile) {
      setProfileType(profile.profile_type || "Personal");
      setProfileName(profile.profile_name || "");
      setWebpageLink(profile.webpage_link || "");
      setWebsiteLink(profile.website || "");
      setLocation(profile.location || "");
      setAboutYou(profile.about || "");
      setProfileImage(profile.image || null);
      setCoverImage(profile.cover || null);
      setBusinessName(profile.business_name || "");
      setBusinessLogo(profile.business_logo || null);
    }
  }, [profile]);

  useEffect(() => {
    setHasChanges(true);
  }, [profileType, profileName, webpageLink, websiteLink, location, aboutYou, tempProfileImage, tempCoverImage, businessName, tempBusinessLogo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateSuccess(false);
    const formData = new FormData();
    formData.append('profile_type', profileType);
    formData.append('profile_name', profileName);
    formData.append('webpage_link', webpageLink);
    formData.append('website', websiteLink);
    formData.append('location', location);
    formData.append('about', aboutYou);
    formData.append('user_id', profile?.user_id);
    
    if (tempProfileImage) formData.append('image', tempProfileImage);
    if (tempCoverImage) formData.append('cover', tempCoverImage);
    if (profileType === 'Business') {
      formData.append('business_name', businessName);
      if (tempBusinessLogo) formData.append('business_logo', tempBusinessLogo);
    }

    try {
      const updatedData = await updateProfile(formData).unwrap();
      dispatch(getProfile(updatedData[0]));
      setUpdateSuccess(true);
      setHasChanges(false);
      toast({
        title: "Profile Updated",
        description: "Your impact profile has been successfully updated.",
        duration: 3000,
      });
      setTimeout(() => navigate(`/impact/${webpageLink.split('/').pop()}`), 500);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: "Update Failed",
        description: "There was an error updating your profile. Please try again.",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  const handleImageUpload = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      if (imageType === 'profile') {
        setTempProfileImage(file);
      } else if (imageType === 'cover') {
        setTempCoverImage(file);
      } else if (imageType === 'businessLogo') {
        setTempBusinessLogo(file);
      }
    }
  };

  if (isProfileLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Edit Impact Profile</CardTitle>
          <Button asChild variant="outline">
            <Link to={`/impact/${webpageLink.split('/').pop()}`}>
              View Impact Profile <ExternalLink className="ml-2 h-4 w-4" />
            </Link>
          </Button>
        </CardHeader>
        <CardContent>
          {updateSuccess && (
            <Alert className="mb-4">
              <CheckCircle className="h-4 w-4" />
              <AlertTitle>Success</AlertTitle>
              <AlertDescription>Your profile has been updated successfully.</AlertDescription>
            </Alert>
          )}
          {updateError && (
            <Alert variant="destructive" className="mb-4">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{updateError.data?.message || 'An error occurred while updating your profile.'}</AlertDescription>
            </Alert>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <Label>How is this page going to be used?</Label>
              <RadioGroup 
                value={profileType} 
                onValueChange={setProfileType}
                className="flex space-x-4"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="Business" id="business" />
                  <Label htmlFor="business">Business</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="Personal" id="personal" />
                  <Label htmlFor="personal">Personal</Label>
                </div>
              </RadioGroup>
              <p className="text-sm text-gray-500 flex items-center">
                <Info className="mr-2 h-4 w-4" />
                {profileType === 'Business'
                  ? "View your company's positive climate action and employee offset."
                  : 'Keep track of your own positive climate action.'}
              </p>
            </div>

            {profileType === 'Business' && (
              <>
                <div className="space-y-2">
                  <Label htmlFor="businessName">Business Name</Label>
                  <Input
                    id="businessName"
                    required
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    placeholder="Enter your business name"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="businessLogo">Upload Business Logo (Optional)</Label>
                  <div className="flex items-center space-x-2">
                    <Input
                      id="businessLogo"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, 'businessLogo')}
                      className="hidden"
                    />
                    <Label htmlFor="businessLogo" className="cursor-pointer inline-flex items-center px-4 py-2 bg-white text-gray-800 rounded-md border border-gray-300 hover:bg-gray-100">
                      <Upload className="mr-2 h-4 w-4" /> Upload logo
                    </Label>
                  </div>
                  {(businessLogo || tempBusinessLogo) && (
                    <img
                      src={tempBusinessLogo ? URL.createObjectURL(tempBusinessLogo) : businessLogo}
                      alt="Business Logo Preview"
                      className="mt-2 max-w-[200px] max-h-[200px] rounded-md"
                    />
                  )}
                  <p className="text-sm text-gray-500 flex items-center">
                    <Info className="mr-2 h-4 w-4" />
                    Logo should be at least 200px x 200px and no more than 2MB. Supported file types: PNG, JPG or GIF
                  </p>
                </div>
              </>
            )}

            <div className="space-y-2">
              <Label htmlFor="profileName">Name your Impact Profile</Label>
              <Input
                id="profileName"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
                placeholder="e.g. Bayti's Impact Page, Bayti's Forest, The Impact Bayti is Making"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="webpageLink">Webpage link</Label>
              <Input
                id="webpageLink"
                value={webpageLink}
                onChange={(e) => setWebpageLink(e.target.value)}
                placeholder="bayti.eco/myprofile"
              />
              <p className="text-sm text-gray-500">
                Refer to our username policy if you have any questions. Email us to host this on your own domain.
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="profileImage">Upload your profile image (Optional)</Label>
              <div className="flex items-center space-x-2">
                <Input
                  id="profileImage"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'profile')}
                  className="hidden"
                />
                <Label htmlFor="profileImage" className="cursor-pointer inline-flex items-center px-4 py-2 bg-white text-gray-800 rounded-md border border-gray-300 hover:bg-gray-100">
                  <Upload className="mr-2 h-4 w-4" /> Upload image
                </Label>
              </div>
              {(profileImage || tempProfileImage) && (
                <img
                  src={tempProfileImage ? URL.createObjectURL(tempProfileImage) : profileImage}
                  alt="Profile Preview"
                  className="mt-2 max-w-[200px] max-h-[200px] rounded-md"
                />
              )}
              <p className="text-sm text-gray-500 flex items-center">
                <Info className="mr-2 h-4 w-4" />
                Images should be at least 200px x 200px and no more than 2MB. Supported file types: PNG, JPG or GIF
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="coverImage">Upload your cover image (Optional)</Label>
              <div className="flex items-center space-x-2">
                <Input
                  id="coverImage"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'cover')}
                  className="hidden"
                />
                <Label htmlFor="coverImage" className="cursor-pointer inline-flex items-center px-4 py-2 bg-white text-gray-800 rounded-md border border-gray-300 hover:bg-gray-100">
                  <Upload className="mr-2 h-4 w-4" /> Upload image
                </Label>
              </div>
              {(coverImage || tempCoverImage) && (
                <img
                  src={tempCoverImage ? URL.createObjectURL(tempCoverImage) : coverImage}
                  alt="Cover Preview"
                  className="mt-2 max-w-full h-auto rounded-md"
                />
              )}
              <p className="text-sm text-gray-500 flex items-center">
                <Info className="mr-2 h-4 w-4" />
                Images should be at least 1500px x 200px and no more than 2MB. Supported file types: PNG, JPG or GIF
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="websiteLink">Link to your website (Optional)</Label>
              <Input
                id="websiteLink"
                value={websiteLink}
                onChange={(e) => setWebsiteLink(e.target.value)}
                placeholder="yourwebsite.com"
              />
              <p className="text-sm text-gray-500 flex items-center">
                <Info className="mr-2 h-4 w-4" />
                You can link to a landing page, home page, store, etc.
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="location">Your location (Optional)</Label>
              <Input
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Country, City or town"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="aboutYou">About you (Optional)</Label>
              <Textarea
                id="aboutYou"
                value={aboutYou}
                onChange={(e) => setAboutYou(e.target.value)}
                placeholder="Welcome to my digital forest! Here, I showcase every small action I've made with Bayti that helps create a lifetime of positive change for the climate. Join me on the race to net-zero by planting trees and countering CO2 emissions. Let's make a lasting impact on the world together!"
              />
              <p className="text-sm text-gray-500 flex items-center">
                <Info className="mr-2 h-4 w-4" />
                Do not exceed 400 characters
              </p>
            </div>

            <Button 
              type="submit" 
              disabled={isUpdating || !hasChanges}
              className={`w-full ${(!hasChanges || isUpdating) && 'opacity-50 cursor-not-allowed'}`}
            >
              {isUpdating ? 'Saving...' : hasChanges ? 'Save Changes' : 'Saved'}
            </Button>
          </form>
        </CardContent>
      </Card>
      <p className="text-center text-sm text-gray-500 mt-4">
        Any empty fields will be hidden on your live website
        <br />
        Contact deactivate@Bayti.eco to delete your Impact Page
      </p>
      {memberBusinessProfile && (
        <Card className="mt-6">
          <CardHeader>
            <CardTitle>Business Profile Management</CardTitle>
          </CardHeader>
          <CardContent>
            <p>You have access to manage a business profile.</p>
            <Link to="/manage-business-profile">
              <Button>Manage Business Profile</Button>
            </Link>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ImpactProfile;