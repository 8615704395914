import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  ArrowLeft,
  Leaf,
  Zap,
  Users,
  DollarSign,
  Globe,
  Wind,
  Sun,
  Droplet,
  Thermometer,
  Flame,
  TreeDeciduous,
  Home,
  Briefcase,
} from 'lucide-react';
import CarbonCreditsModal from './CarbonCreditsModal';
import { Container } from "./ui/layout";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, PieChart, Pie, ResponsiveContainer } from "recharts";

const ProjectDetail = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const projects = {
    1: {
      id: 1,
      title: 'Wind Power Project in Costa Rica',
      description: "This large-scale wind power project in Costa Rica generates clean electricity for the national grid, reducing the country's dependence on fossil fuels and contributing to its goal of carbon neutrality. The project not only addresses climate change but also stimulates local economic growth and improves energy security. By preventing 70,000 tons of CO2 from being emitted annually, it helps reduce air pollution and supports healthier ecosystems in the region.",
      expandedDetails: {
        environmentalImpact: "The project reduces Costa Rica's reliance on fossil fuels, preventing approximately 70,000 tons of CO2 emissions annually—equivalent to taking 15,000 cars off the road each year. This reduction helps improve air quality and preserve biodiversity.",
        communityImpact: "This project has created 150 local jobs, providing steady income for residents and fostering economic growth. Local businesses have benefited from increased demand, and infrastructure improvements have been made to support workers. Many young people in the community are choosing to stay and work locally thanks to the opportunities created by the project.",
        technologyInnovations: "The wind turbines used are equipped with advanced sensors that optimize energy generation based on real-time wind patterns. This ensures maximum efficiency, even in low-wind conditions, and provides a stable energy source for the country’s grid.",
        sdgs: [
          { goal: "SDG 7 (Affordable and Clean Energy)", description: "This project directly contributes to Costa Rica's goal of carbon neutrality by generating clean, renewable energy for the national grid." },
          { goal: "SDG 8 (Decent Work and Economic Growth)", description: "The 150 jobs created by this project provide local employment opportunities, improving the economic stability of the region." },
          { goal: "SDG 13 (Climate Action)", description: "By reducing emissions, the project contributes to global efforts to mitigate climate change and advance Costa Rica's environmental goals." },
        ],
      },
      image: 'https://source.unsplash.com/random/1000x600?wind-turbine',
      location: 'Costa Rica',
      registry: 'Gold Standard',
      vccStatus: 'Verified',
      projectType: 'Wind Energy',
      impacts: [
        { name: 'Clean Energy Generated', value: 120000, unit: 'MWh/year', icon: <Zap className="h-5 w-5" /> },
        { name: 'CO2 Emissions Reduced', value: 70000, unit: 'tCO2e/year', icon: <Globe className="h-5 w-5" /> },
        { name: 'Trees Equivalent', value: 3500000, unit: 'trees/year', icon: <TreeDeciduous className="h-5 w-5" /> },
        { name: 'Households Powered', value: 30000, unit: 'homes', icon: <Home className="h-5 w-5" /> },
        { name: 'Local Jobs Created', value: 150, unit: 'jobs', icon: <Briefcase className="h-5 w-5" /> },
      ],
      sdgs: [7, 8, 13],
    },
    2: {
      id: 2,
      title: 'Solar Water Heating in South Africa',
      description: "This project provides low-income communities in South Africa with access to clean, hot water through solar technology. By replacing electric geysers with solar water heaters, the project reduces electricity consumption and associated greenhouse gas emissions while improving the quality of life for residents and creating local employment opportunities.",
      expandedDetails: {
        environmentalImpact: "The project reduces electricity consumption by 15,000 MWh per year, lowering South Africa’s dependence on coal-generated electricity and decreasing carbon emissions by 12,000 tons of CO2 annually.",
        communityImpact: "By installing 5,000 solar water heaters in low-income households, the project has improved access to hot water, reduced utility bills, and improved living conditions for 20,000 people. Additionally, it has created 80 jobs, primarily in installation and maintenance roles.",
        technologyInnovations: "This project uses innovative solar water heaters designed to maximize efficiency in the South African climate. These heaters are easy to install and maintain, providing a sustainable and cost-effective solution for local communities.",
        sdgs: [
          { goal: "SDG 7 (Affordable and Clean Energy)", description: "The project provides clean, renewable energy to low-income communities, reducing reliance on coal-fired electricity." },
          { goal: "SDG 1 (No Poverty)", description: "By reducing energy costs, the project improves the financial stability of low-income households." },
          { goal: "SDG 11 (Sustainable Cities and Communities)", description: "The project helps create more sustainable, energy-efficient communities by reducing overall energy consumption." },
        ],
      },
      image: 'https://source.unsplash.com/random/1000x600?solar-panel',
      location: 'South Africa',
      registry: 'Gold Standard',
      vccStatus: 'Verified',
      projectType: 'Solar Energy',
      impacts: [
        { name: 'Solar Water Heaters Installed', value: 5000, unit: 'units', icon: <Sun className="h-5 w-5" /> },
        { name: 'Electricity Saved', value: 15000, unit: 'MWh/year', icon: <Zap className="h-5 w-5" /> },
        { name: 'CO2 Emissions Reduced', value: 12000, unit: 'tCO2e/year', icon: <Globe className="h-5 w-5" /> },
        { name: 'Beneficiaries', value: 20000, unit: 'people', icon: <Users className="h-5 w-5" /> },
        { name: 'Local Jobs Created', value: 80, unit: 'jobs', icon: <Briefcase className="h-5 w-5" /> },
      ],
      sdgs: [1, 3, 6, 7, 11, 13],
    },
    3: {
      id: 3,
      title: 'Cleaner Cooking in Rwanda',
      description: "This project focuses on the distribution of efficient cookstoves to households in Rwanda. These improved cookstoves reduce indoor air pollution, decrease fuel consumption, and help combat deforestation. By providing a cleaner cooking solution, the project improves health outcomes, reduces greenhouse gas emissions, and empowers local communities through job creation and improved living conditions.",
      expandedDetails: {
        environmentalImpact: "The distribution of 50,000 efficient cookstoves saves 100,000 tons of wood annually, helping combat deforestation and reducing carbon emissions by 200,000 tons of CO2 per year.",
        communityImpact: "The cookstoves have significantly reduced indoor air pollution, leading to better health outcomes for over 250,000 people. The project has also created 200 local jobs, mainly in the manufacturing and distribution of the stoves.",
        technologyInnovations: "The cookstoves are designed to be highly efficient, using less fuel and emitting fewer pollutants. This reduces the amount of time and money households spend on collecting or purchasing firewood.",
        sdgs: [
          { goal: "SDG 3 (Good Health and Well-being)", description: "By reducing indoor air pollution, the project improves respiratory health, especially for women and children." },
          { goal: "SDG 7 (Affordable and Clean Energy)", description: "The project provides access to clean, efficient energy for cooking, reducing reliance on traditional biomass fuels." },
          { goal: "SDG 15 (Life on Land)", description: "By reducing deforestation, the project helps preserve local ecosystems and biodiversity." },
        ],
      },
      image: 'https://source.unsplash.com/random/1000x600?cookstove',
      location: 'Rwanda',
      registry: 'Gold Standard',
      vccStatus: 'Verified',
      projectType: 'Energy Efficiency',
      impacts: [
        { name: 'Cookstoves Distributed', value: 50000, unit: 'stoves', icon: <Flame className="h-5 w-5" /> },
        { name: 'Wood Saved', value: 100000, unit: 'tonnes/year', icon: <Leaf className="h-5 w-5" /> },
        { name: 'CO2 Emissions Reduced', value: 200000, unit: 'tCO2e/year', icon: <Globe className="h-5 w-5" /> },
        { name: 'Lives Improved', value: 250000, unit: 'people', icon: <Users className="h-5 w-5" /> },
        { name: 'Local Jobs Created', value: 200, unit: 'jobs', icon: <Briefcase className="h-5 w-5" /> },
      ],
      sdgs: [3, 5, 7, 13, 15],
    },
    4: {
      id: 4,
      title: 'Wastewater Treatment in Thailand',
      description: "This project improves wastewater management in Thailand by implementing advanced treatment technologies. By capturing and treating methane emissions from wastewater, the project not only reduces greenhouse gas emissions but also improves local water quality, enhances sanitation conditions, and creates employment opportunities in the green technology sector.",
      expandedDetails: {
        environmentalImpact: "The project treats 5 million cubic meters of wastewater annually, capturing and treating 10,000 tons of methane. This reduces the amount of methane, a potent greenhouse gas, entering the atmosphere and significantly improves water quality in the region.",
        communityImpact: "The project has benefited 500,000 people by improving local sanitation and water quality. In addition, it has created 120 jobs in wastewater management and green technology, helping to drive sustainable development in the region.",
        technologyInnovations: "The project uses cutting-edge anaerobic digestion technology to treat wastewater. This process not only captures methane but also produces biogas that can be used for energy generation, making the treatment process more sustainable.",
        sdgs: [
          { goal: "SDG 6 (Clean Water and Sanitation)", description: "The project improves access to clean water and enhances wastewater treatment in the region." },
          { goal: "SDG 9 (Industry, Innovation, and Infrastructure)", description: "The advanced technology used in the project fosters innovation in wastewater management and supports green infrastructure development." },
          { goal: "SDG 13 (Climate Action)", description: "By capturing methane emissions, the project directly contributes to global efforts to mitigate climate change." },
        ],
      },
      image: 'https://source.unsplash.com/random/1000x600?water-treatment',
      location: 'Thailand',
      registry: 'Gold Standard',
      vccStatus: 'Verified',
      projectType: 'Water Management',
      impacts: [
        { name: 'Wastewater Treated', value: 5000000, unit: 'm³/year', icon: <Droplet className="h-5 w-5" /> },
        { name: 'Methane Captured', value: 10000, unit: 'tonnes/year', icon: <Thermometer className="h-5 w-5" /> },
        { name: 'CO2 Emissions Reduced', value: 250000, unit: 'tCO2e/year', icon: <Globe className="h-5 w-5" /> },
        { name: 'Beneficiaries', value: 500000, unit: 'people', icon: <Users className="h-5 w-5" /> },
        { name: 'Local Jobs Created', value: 120, unit: 'jobs', icon: <Briefcase className="h-5 w-5" /> },
      ],
      sdgs: [3, 6, 9, 11, 13, 14],
    },
    5: {
      id: 5,
      title: 'Biogas Digesters in Vietnam',
      description: "This project involves installing biogas digesters in rural households across Vietnam. These digesters convert animal waste into clean energy for cooking and lighting, reducing reliance on firewood and fossil fuels. The project also improves waste management, provides organic fertilizer as a by-product, and contributes to rural development through job creation and improved living conditions.",
      expandedDetails: {
        environmentalImpact: "The installation of 25,000 biogas digesters has reduced reliance on fossil fuels and firewood, preventing the emission of 150,000 tons of CO2 annually. The project also produces organic fertilizer, which helps improve agricultural practices.",
        communityImpact: "The project benefits 100,000 rural households, providing them with access to clean, renewable energy. It has also created 250 jobs, primarily in the installation and maintenance of the biogas digesters, helping to foster economic development in rural areas.",
        technologyInnovations: "The biogas digesters are designed to convert animal waste into clean energy for cooking and lighting. This not only reduces greenhouse gas emissions but also provides a sustainable solution to waste management.",
        sdgs: [
          { goal: "SDG 2 (Zero Hunger)", description: "The project provides organic fertilizer as a by-product, improving crop yields and supporting sustainable agriculture." },
          { goal: "SDG 7 (Affordable and Clean Energy)", description: "The project provides access to clean, renewable energy, reducing reliance on firewood and fossil fuels." },
          { goal: "SDG 13 (Climate Action)", description: "By reducing emissions from fossil fuels, the project contributes to global efforts to combat climate change." },
        ],
      },
      image: 'https://source.unsplash.com/random/1000x600?biogas',
      location: 'Vietnam',
      registry: 'Gold Standard',
      vccStatus: 'Verified',
      projectType: 'Biogas',
      impacts: [
        { name: 'Biogas Digesters Installed', value: 25000, unit: 'units', icon: <Leaf className="h-5 w-5" /> },
        { name: 'Fossil Fuel Replaced', value: 50000, unit: 'tonnes/year', icon: <Zap className="h-5 w-5" /> },
        { name: 'CO2 Emissions Reduced', value: 150000, unit: 'tCO2e/year', icon: <Globe className="h-5 w-5" /> },
        { name: 'Rural Households Benefited', value: 100000, unit: 'homes', icon: <Home className="h-5 w-5" /> },
        { name: 'Local Jobs Created', value: 250, unit: 'jobs', icon: <Briefcase className="h-5 w-5" /> },
      ],
      sdgs: [2, 3, 7, 9, 11, 13, 15],
    },
  };
  

  const project = projects[id];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  return (
    <Container className="py-8">
      <Button asChild variant="link" className="mb-4">
        <Link to="/carbon-marketplace">
          <ArrowLeft className="mr-2 h-4 w-4" /> All projects
        </Link>
      </Button>
      <motion.h1
        className="text-4xl font-bold mb-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {project.title}
      </motion.h1>
      <motion.img
        src={project.image}
        alt={project.title}
        className="w-full h-auto rounded-lg mb-8 shadow-lg"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.3 }}
      />
      <motion.p
        className="text-lg mb-8"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        {project.description}
      </motion.p>
      <Button onClick={openModal} size="lg" className="mb-8">
        Purchase Carbon Credits
      </Button>

      <motion.h2
        className="text-2xl font-semibold mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        Project Details
      </motion.h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, staggerChildren: 0.1 }}
      >
        <Card>
          <CardHeader>
            <CardTitle>Location</CardTitle>
          </CardHeader>
          <CardContent>{project.location}</CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Registry</CardTitle>
          </CardHeader>
          <CardContent>{project.registry}</CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>VCC Status</CardTitle>
          </CardHeader>
          <CardContent>{project.vccStatus}</CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Project Type</CardTitle>
          </CardHeader>
          <CardContent>{project.projectType}</CardContent>
        </Card>
      </motion.div>

      <motion.h2
        className="text-2xl font-semibold mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        Project Impacts
      </motion.h2>
      

      <motion.div
        className="mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8 }}
      >
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={project.impacts}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8">
              {project.impacts.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.ul
        className="space-y-2 mb-8"
        initial="hidden"
        animate="visible"
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
        }}
      >
        {project.impacts.map((impact, index) => (
          <motion.li
            key={index}
            className="flex items-center"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            {impact.icon}
            <span className="ml-2">
              {impact.name}: {impact.value} {impact.unit}
            </span>
          </motion.li>
        ))}
      </motion.ul>

      <motion.h2
        className="text-2xl font-semibold mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.0 }}
      >
        Sustainable Development Goals
      </motion.h2>
      <motion.div
        className="flex flex-wrap gap-4 mb-8"
        initial="hidden"
        animate="visible"
        variants={{
          visible: { transition: { staggerChildren: 0.05 } },
        }}
      >
        {project.sdgs.map((sdg) => (
          <motion.img
            key={sdg}
            src={`https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-${sdg.toString().padStart(2, '0')}.jpg`}
            alt={`SDG ${sdg}`}
            className="w-16 h-16"
            variants={{
              hidden: { opacity: 0, scale: 0.8 },
              visible: { opacity: 1, scale: 1 },
            }}
            whileHover={{ scale: 1.1 }}
          />
        ))}
      </motion.div>

      <CarbonCreditsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        projectId={id}
      />
    </Container>
  );
};

export default ProjectDetail;