import React, { useState, useEffect } from 'react';
import { Leaf, Recycle, Droplet, Sprout, Sun, Moon } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Progress } from "./ui/progress";
import { Button } from "./ui/button";
import { Switch } from "./ui/switch";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const ecoTips = [
    'A single tree can absorb up to 48 pounds of CO2 per year.',
    'Unplug electronics when not in use to save energy.',
    'Recycling one aluminum can saves enough energy to run a TV for 3 hours.',
    'Use a reusable water bottle to reduce plastic waste.',
    'Choose local and seasonal produce to reduce transportation emissions.',
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) return 0;
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const tipInterval = setInterval(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex + 1) % ecoTips.length);
    }, 5000);

    return () => clearInterval(tipInterval);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`flex justify-center items-center min-h-screen p-4 ${isDarkMode ? 'dark bg-gray-900' : 'bg-green-50'}`}>
      <Card className="w-full max-w-md">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="text-2xl font-bold">Eco Loading</CardTitle>
            <Switch
              checked={isDarkMode}
              onCheckedChange={toggleTheme}
              className="ml-4"
            />
          </div>
          <CardDescription>Saving the planet, one load at a time</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center mb-6">
            <Leaf className="w-16 h-16 text-green-500 animate-spin-slow" />
          </div>
          
          <Progress value={progress} className="mb-4" />
          
          <p className="text-center mb-4">{Math.round(progress)}% Complete</p>
          
          <Card className="bg-green-100 dark:bg-green-900 mb-6">
            <CardContent className="p-4">
              <p className="text-sm italic text-green-800 dark:text-green-200">&quot;{ecoTips[currentTipIndex]}&quot;</p>
            </CardContent>
          </Card>
          
          <div className="flex justify-around mb-6">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="icon" className="rounded-full">
                    <Recycle className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Recycle</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="icon" className="rounded-full">
                    <Droplet className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Save Water</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="icon" className="rounded-full">
                    <Sprout className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Plant Trees</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          
          <div className="flex justify-center items-center space-x-2">
            {isDarkMode ? <Moon className="text-yellow-400" /> : <Sun className="text-yellow-500" />}
            <span className="text-sm">{isDarkMode ? 'Dark Mode' : 'Light Mode'}</span>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Loading;