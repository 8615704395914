import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    allpackages:[],
    allorders:[],
    ordersbyuser:[],
    businessOrders:[],
 };

const packageSlice = createSlice({
    name: "package",
    initialState,
    reducers: {
        getAllPackages:(state,action)=>{
            state.allpackages = action.payload
        },
        getAllOrders:(state,action)=>{
            state.allorders = action.payload
        },
        getAllOrdersByUser:(state,action)=>{
            state.ordersbyuser = action.payload
        },
        getAllOrdersByBusiness: (state, action) => {
            state.businessOrders = action.payload;
        },
    },
});

export const {
    getAllPackages,
    getAllOrders,
    getAllOrdersByUser,
    getAllOrdersByBusiness
} = packageSlice.actions;
export default packageSlice.reducer;