import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { ScrollArea } from "./ui/scroll-area";
import Header from './Header';
import HomeFooter from './HomeFooter';

const TermsOfService = () => {
  const sections = [
    {
      title: '1. Acceptance of Terms',
      content: "Welcome to Bayti Eco. By accessing or using our website, mobile application, or any of our services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.",
    },
    {
      title: '2. Description of Service',
      content: "Bayti Eco provides a platform for individuals and businesses to offset their carbon footprint through various environmental initiatives, including tree planting, plastic waste removal, and support for climate projects.",
    },
    {
      title: '3. User Accounts',
      content: "To access certain features of our service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
    },
    {
      title: '4. User Conduct',
      content: (
        <>
          <p>Users agree to not engage in the following activities:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Violating any applicable laws or regulations</li>
            <li>Impersonating any person or entity</li>
            <li>Interfering with or disrupting our services</li>
            <li>Uploading or transmitting viruses or any other malicious code</li>
          </ul>
        </>
      ),
    },
    {
      title: '5. Intellectual Property',
      content: "All content, features, and functionality of our services are owned by Bayti Eco and are protected by international copyright, trademark, and other intellectual property laws.",
    },
    {
      title: '6. Payments and Subscriptions',
      content: "For paid services, you agree to pay all fees associated with your chosen plan. Subscriptions will automatically renew unless cancelled before the renewal date.",
    },
    {
      title: '7. Limitation of Liability',
      content: "Bayti Eco shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.",
    },
    {
      title: '8. Changes to Terms',
      content: "We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes via email or through our platform.",
    },
    {
      title: '9. Governing Law',
      content: "These Terms of Service shall be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to its conflict of law provisions.",
    },
    {
      title: '10. Contact Us',
      content: (
        <>
          If you have any questions about these Terms of Service, please contact us at:{' '}
          <Button variant="link" className="p-0 h-auto" asChild>
            <a href="mailto:legal@bayti.eco">
              legal@bayti.eco <ChevronRight className="h-4 w-4 ml-1" />
            </a>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <main className="bg-gray-50 min-h-screen">
        <div className="container mx-auto px-4 py-16 max-w-3xl">
          <motion.h1 
            className="text-4xl font-bold text-center mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Terms of Service
          </motion.h1>
          <p className="text-center text-gray-500 mb-8">Last Updated: August 27, 2024</p>

          <Card className="mb-8">
            <CardHeader>
              <CardTitle>Table of Contents</CardTitle>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                <ul className="space-y-2">
                  {sections.map((section, index) => (
                    <li key={index}>
                      <Button variant="link" asChild className="p-0 h-auto">
                        <a href={`#section-${index}`}>
                          {section.title} <ChevronRight className="h-4 w-4 ml-1" />
                        </a>
                      </Button>
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </CardContent>
          </Card>

          <Accordion type="single" collapsible className="w-full">
            {sections.map((section, index) => (
              <AccordionItem key={index} value={`item-${index}`} id={`section-${index}`}>
                <AccordionTrigger>{section.title}</AccordionTrigger>
                <AccordionContent>
                  <div className="prose max-w-none">
                    {typeof section.content === 'string' ? (
                      <p>{section.content}</p>
                    ) : (
                      section.content
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>

          <Card className="mt-8">
            <CardContent className="pt-6">
              <p className="text-center italic text-gray-600">
                By using Bayti Eco&apos;s services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
              </p>
            </CardContent>
          </Card>
        </div>
      </main>
      <HomeFooter />
    </>
  );
};

export default TermsOfService;