import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Leaf, Recycle, Flame, Wind, Sun, Waves } from 'lucide-react';
import Partners from './Partners';
import { Container } from "./ui/layout";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Badge } from "./ui/badge";

const ProjectList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projectTypeFilter, setProjectTypeFilter] = useState([]);
  const [projects] = useState([
    {
      id: 1,
      title: 'Wind Power Project in Costa Rica',
      description: 'Large-scale wind power project generating clean electricity for the Costa Rican grid.',
      image: 'https://source.unsplash.com/random/800x600?wind-turbine',
      tags: ['Costa Rica', 'Renewable Energy', 'Gold Standard', 'Large-scale'],
      verified: true,
      type: 'Wind Energy',
    },
    {
      id: 2,
      title: 'Solar Water Heating in South Africa',
      description: 'Providing low-income communities with access to clean, hot water through solar technology.',
      image: 'https://source.unsplash.com/random/800x600?solar-panel',
      tags: ['South Africa', 'Solar Energy', 'Gold Standard', 'Community'],
      verified: true,
      type: 'Solar Energy',
    },
    {
      id: 3,
      title: 'Cleaner Cooking in Rwanda',
      description: 'Distribution of efficient cookstoves to reduce indoor air pollution and deforestation.',
      image: 'https://source.unsplash.com/random/800x600?cookstove',
      tags: ['Rwanda', 'Energy Efficiency', 'Gold Standard', 'Health'],
      verified: true,
      type: 'Energy Efficiency',
    },
    {
      id: 4,
      title: 'Wastewater Treatment in Thailand',
      description: 'Improving wastewater management to reduce methane emissions and improve local water quality.',
      image: 'https://source.unsplash.com/random/800x600?water-treatment',
      tags: ['Thailand', 'Water', 'Gold Standard', 'Sanitation'],
      verified: true,
      type: 'Water Management',
    },
    {
      id: 5,
      title: 'Biogas Digesters in Vietnam',
      description: 'Installing biogas digesters to convert animal waste into clean energy for rural households.',
      image: 'https://source.unsplash.com/random/800x600?biogas',
      tags: ['Vietnam', 'Biogas', 'Gold Standard', 'Rural Development'],
      verified: true,
      type: 'Biogas',
    },
  ]);

  const handleSearch = (e) => {
    e.preventDefault();
    // Implement search functionality here
  };

  const handleFilterChange = (type) => {
    setProjectTypeFilter((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const filteredProjects = useMemo(() => {
    return projects.filter(
      (project) =>
        (projectTypeFilter.length === 0 ||
          projectTypeFilter.includes(project.type)) &&
        (project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          project.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [projects, projectTypeFilter, searchTerm]);

  const projectTypes = [...new Set(projects.map((project) => project.type))];

  const ProjectTypeIcon = ({ type }) => {
    switch (type) {
      case 'Wind Energy':
        return <Wind className="h-4 w-4" />;
      case 'Solar Energy':
        return <Sun className="h-4 w-4" />;
      case 'Energy Efficiency':
        return <Flame className="h-4 w-4" />;
      case 'Water Management':
        return <Waves className="h-4 w-4" />;
      case 'Biogas':
        return <Leaf className="h-4 w-4" />;
      default:
        return <Leaf className="h-4 w-4" />;
    }
  };

  return (
    <Container className="py-8">
      <motion.h1
        className="text-4xl font-bold text-center mb-4"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Climate Projects
      </motion.h1>
      <motion.p
        className="text-lg text-gray-600 text-center mb-8 max-w-2xl mx-auto"
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Explore our catalogue of global humanitarian projects we have helped fund and support.
      </motion.p>

      <div className="flex mb-8">
        <Input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="rounded-r-none"
        />
        <Button onClick={handleSearch} className="rounded-l-none">
          <Search className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex flex-wrap justify-between mb-8">
        <div>
          <p className="mb-2">
            Showing {filteredProjects.length} of {projects.length} projects
          </p>
          <Button variant="destructive" onClick={() => setProjectTypeFilter([])}>
            Reset filters
          </Button>
        </div>
        <div>
          <p className="mb-2">Filter by project type:</p>
          {projectTypes.map((type) => (
            <div key={type} className="flex items-center mb-2">
              <Checkbox
                id={type}
                checked={projectTypeFilter.includes(type)}
                onCheckedChange={() => handleFilterChange(type)}
              />
              <label htmlFor={type} className="ml-2 flex items-center">
                <ProjectTypeIcon type={type} />
                <span className="ml-1">{type}</span>
              </label>
            </div>
          ))}
        </div>
      </div>

      <motion.div layout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <AnimatePresence>
          {filteredProjects.map((project) => (
            <motion.div
              key={project.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              layout
            >
              <Card className="h-full flex flex-col">
                <img src={project.image} alt={project.title} className="h-48 w-full object-cover" />
                <CardHeader>
                  {project.verified && (
                    <Badge variant="secondary" className="mb-2">Verified Project</Badge>
                  )}
                  <CardTitle>{project.title}</CardTitle>
                  <CardDescription>{project.description}</CardDescription>
                </CardHeader>
                <CardContent className="mt-auto">
                  <div className="flex flex-wrap gap-2">
                    {project.tags.map((tag, index) => (
                      <Badge key={index} variant="outline">{tag}</Badge>
                    ))}
                  </div>
                </CardContent>
                <Button asChild className="mt-4 mx-4 mb-4">
                  <Link to={`/projects/${project.id}`}>Learn More</Link>
                </Button>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
      <Partners />
    </Container>
  );
};

export default ProjectList;