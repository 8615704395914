import { createSlice } from '@reduxjs/toolkit';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    defaultMethod: null,
  },
  reducers: {
    setDefaultMethod: (state, action) => {
      state.defaultMethod = action.payload;
    },
  },
});

export const { setDefaultMethod } = paymentSlice.actions;
export default paymentSlice.reducer;