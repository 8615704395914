import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, Handshake, Lightbulb, Globe } from 'lucide-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Container, Section } from "./ui/layout";

const AboutUsPage = () => {
  return (
    <Container className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <Section className="text-center mb-16">
        <video autoPlay loop muted className="absolute top-0 left-0 w-full h-full object-cover -z-10">
          <source src="/path-to-video.mp4" type="video/mp4" />
        </video>
        <motion.h1 
          className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Pioneering Carbon Awareness in the UAE and Middle East
        </motion.h1>
        <motion.p
          className="text-xl text-gray-600 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          At Bayti, we are on a mission to revolutionize how businesses and
          consumers in the UAE and Middle East understand and reduce their
          carbon footprint.
        </motion.p>
      </Section>

      <Section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-16 bg-blue-600 p-8 rounded-xl text-white">
        {[
          { number: '4000+', label: 'Trees Planted' },
          { number: '45+', label: 'Tons of CO2 Offset' },
          { number: '29+', label: 'Thousand bottles removed' },
          { number: '5+', label: 'Humantarian Projects' },
        ].map((stat, index) => (
          <motion.div
            key={index}
            className="text-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
          >
            <div className="text-3xl font-bold mb-2">{stat.number}</div>
            <div className="text-sm">{stat.label}</div>
          </motion.div>
        ))}
      </Section>

      <Section className="flex flex-col lg:flex-row gap-16 mb-16 items-center">
        <div className="lg:w-1/2">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-4">
            Bayti Eco is at the forefront of the sustainability movement in the
            UAE and Middle East. We&apos;re dedicated to educating and
            empowering businesses and individuals to understand their
            environmental impact and take meaningful action to reduce their
            carbon footprint from today.
          </p>
          <p className="text-gray-600">
            Through innovative technology and partnerships, we&apos;re making
            carbon footprint tracking and reduction accessible to everyone in
            the region. Our goal is to create a culture of environmental
            responsibility that spreads across the Middle East and beyond.
          </p>
        </div>
        <motion.img
          src="./images/Teampic.jpg"
          alt="BaytiEco team in action"
          className="lg:w-1/2 rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
          transition={{ type: 'spring', stiffness: 300 }}
        />
      </Section>

      <Section className="mb-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">Our Journey</h2>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#4a4af4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #4a4af4' }}
            date="2020 - present"
            iconStyle={{ background: '#4a4af4', color: '#fff' }}
            icon={<Leaf />}
          >
            <h3 className="vertical-timeline-element-title font-bold">
              Founded Bayti Eco
            </h3>
            <p>
              Launched our mission to push for carbon awareness in the UAE and
              Middle East
            </p>
          </VerticalTimelineElement>
          {/* Add more timeline elements as needed */}
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#4a4af4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #4a4af4' }}
            date="2024 - present"
            iconStyle={{ background: '#4a4af4', color: '#fff' }}
            icon={<Leaf />}
          >
            <h3 className="vertical-timeline-element-title font-bold">
              Partnering with trusted organizations
            </h3>
            <p>
              Partnering with trusted organizations to ensure our solutions are
              reliable and effective.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#4a4af4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #4a4af4' }}
            date="2024 - present"
            iconStyle={{ background: '#4a4af4', color: '#fff' }}
            icon={<Leaf />}
          > 
            <h3 className="vertical-timeline-element-title font-bold">
              First Business Partner
            </h3>
            <p>
              Signed our first business partner.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#4a4af4', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #4a4af4' }}
            date="2024 - present"
            iconStyle={{ background: '#4a4af4', color: '#fff' }}
            icon={<Leaf />}
          >
            <h3 className="vertical-timeline-element-title font-bold">
              First 100 Individual Bayti Eco Users
            </h3>
            <p>
              Reached 100 individual users of Bayti Eco.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Section>

      <Section className="mb-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">Our Core Values</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { icon: <Leaf />, title: 'Environmental Stewardship', description: 'We lead by example in sustainable practices, inspiring others to join the green revolution.' },
            { icon: <Handshake />, title: 'Collaboration', description: 'We believe in the power of partnerships to create lasting environmental change in our region.' },
            { icon: <Lightbulb />, title: 'Innovation', description: 'We constantly push the boundaries of technology to make sustainability accessible and engaging.' },
            { icon: <Globe />, title: 'Regional Impact', description: 'We are committed to addressing the unique environmental challenges of the UAE and Middle East.' },
          ].map((value, index) => (
            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
              <CardHeader>
                <div className="text-4xl text-blue-600 mb-4">{value.icon}</div>
                <CardTitle>{value.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600">{value.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </Section>

      <Section className="mb-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">What Our Partners Say</h2>
        <Card className="hover:shadow-lg transition-shadow duration-300">
          <CardContent className="text-center py-8">
            <p className="text-gray-600 mb-4">
              &quot;Bayti Eco has transformed how we approach sustainability in our
              business.&quot;
            </p>
            <h4 className="font-bold">Khalid AlMansoori - Founder of FoodieME</h4>
          </CardContent>
        </Card>
        {/* Add more testimonials as needed */}
      </Section>

      <Section>
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">Meet Our Team</h2>
        <div className="flex justify-center">
          <div className="text-center">
            <Avatar className="w-24 h-24 mx-auto mb-4">
              <AvatarImage src="/path-to-bakri-albrek.jpg" alt="CEO & Founder" />
              <AvatarFallback>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-full text-gray-300">
                  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                </svg>
              </AvatarFallback>
            </Avatar>
            <h3 className="font-bold text-xl">Bakri Albrek</h3>
            <p className="text-gray-600">Founder & CEO</p>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default AboutUsPage;
