import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Heart, Building, Trees, Droplet, Globe, ChevronLeft } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Container } from "./ui/layout";
import { Separator } from "./ui/separator";
import { useAddPlanOrderMutation, useGetPlansByProfileQuery } from '../app/features/plan/planApi';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { supabase } from '../app/supabase';
import { toast } from "./ui/use-toast";
import { Label } from "./ui/label";
import { loadStripe } from '@stripe/stripe-js';
import { updateProfile } from '../app/features/profile/profileSlice';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ clientSecret, handleCheckout }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    await handleCheckout(stripe, elements);
    setIsLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <PaymentElement />
      <div className="mt-4">
        <Button 
          type="submit" 
          disabled={!stripe || isLoading} 
          className="w-full"
        >
          {isLoading ? "Processing..." : "Confirm Payment"}
        </Button>
      </div>
    </form>
  );
};

const CheckoutPage = () => {
  const { planType, planName } = useParams();
  const [plan, setPlan] = useState(null);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [addPlanOrder] = useAddPlanOrderMutation();
  const [clientSecret, setClientSecret] = useState('');
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);
  const user = useSelector((state) => state.auth.user);

  const { refetch: refetchProfilePlans } = useGetPlansByProfileQuery({ profile_id: profile?.id }, {
    skip: !profile?.id,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    const fetchPlan = async () => {
      const mockPlans = {
        personal: {
          guardian: { id: 1, title: 'Guardian', price: 25, trees: 2, bottles: 20, co2: 100 },
          protector: { id: 2, title: 'Protector', price: 49, trees: 4, bottles: 50, co2: 200 },
          warrior: { id: 3, title: 'Warrior', price: 95, trees: 6, bottles: 30, co2: 750 },
          hero: { id: 4, title: 'Hero', price: 160, trees: 12, bottles: 40, co2: 1515 },
          legend: { id: 5, title: 'Legend', price: 449, trees: 100, bottles: 1000, co2: 2000 },
        },
        business: {
          guardian: { id: 1, title: 'Guardian', price: 25, trees: 2, bottles: 20, co2: 100 },
          protector: { id: 2, title: 'Protector', price: 49, trees: 4, bottles: 50, co2: 200 },
          warrior: { id: 3, title: 'Warrior', price: 95, trees: 6, bottles: 30, co2: 750 },
          hero: { id: 4, title: 'Hero', price: 160, trees: 12, bottles: 40, co2: 1515 },
          legend: { id: 5, title: 'Legend', price: 449, trees: 100, bottles: 1000, co2: 2000 },
        },
      };
      const selectedPlan = mockPlans[planType][planName];
      setPlan(selectedPlan);
      console.log('Selected plan:', selectedPlan);
    };

    fetchPlan();
  }, [planType, planName]);

  useEffect(() => {
    if (!user) {
      navigate('/auth-page', {
        state: { returnUrl: `/checkout/${planType}/${planName}` },
      });
    }
  }, [navigate, user, planType, planName]);

  useEffect(() => {
    if (plan && profile && user) {
      const fetchClientSecret = async () => {
        try {
          const payload = {
            amount: plan.price * 100,
            metadata: { profile_id: profile.id, is_update: 'false' }
          };

          if (profile.stripe_customer_id) {
            payload.customer_id = profile.stripe_customer_id;
          } else {
            payload.email = user.email;
          }

          console.log('Payload for create-payment-intent:', payload);

          const { data, error } = await supabase.functions.invoke('create-payment-intent', {
            body: payload,
          });

          if (error) throw error;
          setClientSecret(data.clientSecret);
        } catch (err) {
          console.error('Error fetching client secret:', err);
          setError('Failed to initialize payment. Please try again.');
        }
      };

      fetchClientSecret();
    }
  }, [plan, profile, user]);

  if (!plan) {
    return <div>Loading...</div>;
  }

  const handleCheckout = async (stripe, elements) => {
    setError('');
    setIsProcessing(true);

    if (!stripe || !elements) {
      setError('Payment is not ready. Please try again.');
      setIsProcessing(false);
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        throw new Error(error.message);
      }

      if (paymentIntent.status === 'succeeded') {
        const formData = {
          profile_id: profile.id,
          profile_name: profile.full_name || '',
          plan_id: plan.id,
          plan_name: plan.title || '',
          price: plan.price,
          full_name: profile.full_name || '',
          tree: plan.trees || 0,
          bottles: plan.bottles || 0,
          co2: plan.co2 || 0,
          active_plan: plan.id,
          auto_renew: true,
          email: user.email,
          paymentIntentId: paymentIntent.id,
        };

        const response = await addPlanOrder(formData).unwrap();

        if (response.error) {
          throw new Error(response.error.message);
        }
        // Update the profile in Redux store
        dispatch(updateProfile({ ...profile, hasplan: true }));

        // Refetch the profile plans
        await refetchProfilePlans();
        toast({
          title: "Purchase Successful",
          description: "Your plan has been activated successfully.",
          variant: "success",
        });
        navigate('/the-bayti');
      } else {
        throw new Error('Payment was not successful. Please try again.');
      }
    } catch (err) {
      console.error('Failed to place order:', err);
      setError(err.message || 'Failed to place order. Please try again.');
      toast({
        title: "Checkout Error",
        description: err.message || "There was a problem processing your payment. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container className="py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <img src="/images/BaytiEcoLogo.png" alt="BaytiEco Logo" className="h-10 mb-6" />
          <h1 className="text-3xl font-bold mb-6">Your order</h1>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                {planType === 'personal' ? <Heart className="mr-2" /> : <Building className="mr-2" />}
                {plan.title} Plan
              </CardTitle>
              <CardDescription>AED {plan.price.toFixed(2)} / month</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="flex items-center">
                  <Trees className="mr-2 text-green-500" /> {plan.trees} mangrove trees funded
                </div>
                <div className="flex items-center">
                  <Droplet className="mr-2 text-blue-500" /> {plan.bottles} ocean-bound plastic bottles removed
                </div>
                <div className="flex items-center">
                  <Globe className="mr-2 text-brown-500" /> -{plan.co2} kg CO2 via humanitarian projects
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div>
          <h2 className="text-2xl font-bold mb-4">Your summary</h2>
          <Card>
            <CardContent>
              <div className="flex justify-between mb-2">
                <span>{plan.title} Plan</span>
                <span>AED {plan.price.toFixed(2)}</span>
              </div>
              <Separator className="my-4" />
        
              <div className="mt-4">
                <Label htmlFor="payment-element">Payment Method</Label>
                <div className="border rounded p-3">
                  {clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <PaymentForm 
                        clientSecret={clientSecret}
                        handleCheckout={handleCheckout} 
                      />
                    </Elements>
                  )}
                </div>
              </div>
              <Separator className="my-4" />
              <div className="flex justify-between font-bold">
                <span>Total</span>
                <span>AED {plan.price.toFixed(2)} /month</span>
              </div>
            </CardContent>
            <CardFooter className="flex-col space-y-4">
              {error && (
                <Alert variant="destructive">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
            </CardFooter>
          </Card>

          <Button variant="link" asChild className="mt-4">
            <Link to={planType === 'personal' ? '/my-bayti' : '/see-plans'}>
              <ChevronLeft className="mr-2" />
              Back to {planType === 'personal' ? 'personal' : 'business'} plans
            </Link>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutPage;