import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { Plus, Edit, Trash2, Upload } from 'lucide-react';
import HeaderDash from '../components/HeaderDash';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { useGetAllPackagesQuery, useCreatePackageMutation, useUpdatePackageMutation, useDeletePackageMutation } from '../app/features/package/packageApi';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import { Badge } from "../components/ui/badge";

const AdminPackage = () => {
  const packages = useSelector((state) => state.package.allpackages || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [tempPackageImage, setTempPackageImage] = useState(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const { refetch } = useGetAllPackagesQuery();
  const [createPackage] = useCreatePackageMutation();
  const [updatePackage] = useUpdatePackageMutation();
  const [deletePackage] = useDeletePackageMutation();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTempPackageImage(file);
    }
  };

  const handleCreateOrUpdate = async (data) => {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      if (tempPackageImage) {
        formData.append('image', tempPackageImage);
      }
  
      if (updateId) {
        formData.append('id', updateId);
        await updatePackage(formData).unwrap();
        toast.success("Package updated successfully");
      } else {
        await createPackage(formData).unwrap();
        toast.success("Package created successfully");
      }
      
      refetch();
      setTempPackageImage(null);
      setIsModalOpen(false);
      reset();
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  const prepareUpdate = (id) => {
    setUpdateId(id);
    const packageToUpdate = packages.find(pkg => pkg.id === id);
    reset(packageToUpdate);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await deletePackage(id).unwrap();
      toast.success("Package deleted successfully");
      refetch();
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <HeaderDash />
      <main className="container mx-auto py-6">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle>Package Lists</CardTitle>
            <Button onClick={() => setIsModalOpen(true)}>
              <Plus className="mr-2 h-4 w-4" /> Add Package
            </Button>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Image</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Trees</TableHead>
                  <TableHead>Plastic</TableHead>
                  <TableHead>Carbon</TableHead>
                  <TableHead>CO2</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {packages.map((pkg) => (
                  <TableRow key={pkg.id}>
                    <TableCell>{pkg.id}</TableCell>
                    <TableCell>{pkg.name}</TableCell>
                    <TableCell>{pkg.price}</TableCell>
                    <TableCell>
                      <img src={pkg.image} alt={pkg.name} className="w-20 h-20 object-cover rounded" />
                    </TableCell>
                    <TableCell>{pkg.description}</TableCell>
                    <TableCell>{pkg.trees}</TableCell>
                    <TableCell>{pkg.plastic}</TableCell>
                    <TableCell>{pkg.carbon}</TableCell>
                    <TableCell>{pkg.co2}</TableCell>
                    <TableCell>
                      <Badge variant={pkg.status === 'Active' ? 'default' : 'destructive'}>
                        {pkg.status}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Button variant="ghost" size="sm" onClick={() => prepareUpdate(pkg.id)}>
                        <Edit className="h-4 w-4" />
                      </Button>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button variant="ghost" size="sm">
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                              This action cannot be undone. This will permanently delete the package.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => handleDelete(pkg.id)}>
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </main>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{updateId ? 'Update Package' : 'Create Package'}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(handleCreateOrUpdate)} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input id="name" {...register("name", { required: "Name is required" })} />
                {errors.name && <p className="text-sm text-red-500">{errors.name.message}</p>}
              </div>
              <div className="space-y-2">
                <Label htmlFor="price">Price</Label>
                <Input id="price" type="number" {...register("price", { required: "Price is required" })} />
                {errors.price && <p className="text-sm text-red-500">{errors.price.message}</p>}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="image">Image</Label>
              <Input id="image" type="file" onChange={handleImageUpload} />
              {tempPackageImage && (
                <img src={URL.createObjectURL(tempPackageImage)} alt="Preview" className="mt-2 w-32 h-32 object-cover rounded" />
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Textarea id="description" {...register("description", { required: "Description is required" })} />
              {errors.description && <p className="text-sm text-red-500">{errors.description.message}</p>}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="trees">Trees</Label>
                <Input id="trees" {...register("trees")} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="plastic">Plastic</Label>
                <Input id="plastic" {...register("plastic")} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="carbon">Carbon</Label>
                <Input id="carbon" {...register("carbon")} />
              </div>
              <div className="space-y-2">
                <Label htmlFor="co2">CO2</Label>
                <Input id="co2" {...register("co2")} />
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="status">Status</Label>
              <Select onValueChange={(value) => register("status").onChange({ target: { value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Active">Active</SelectItem>
                  <SelectItem value="Inactive">Inactive</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <DialogFooter>
              <Button type="submit">{updateId ? 'Update' : 'Create'}</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminPackage;