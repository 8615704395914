import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Clock } from "lucide-react";

const SuggestedReading = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const articles = [
    {
      title: 'Exploring Carbon Reduction Methods and How to Buy Carbon Credits',
      description: 'Discover projects like tree planting, ocean plastic removal, and humanitarian initiatives.',
      image: '/images/carbon-reduction.jpg',
      readTime: '5 mins read',
    },
    {
      title: "5 Trends Reshaping Hospitality's Approach to Sustainability",
      description: 'With customer awareness and expectations at an all-time high, the sector is rapidly adapting to new eco-friendly trends.',
      image: '/images/hospitality-sustainability.jpg',
      readTime: '5 mins read',
    },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {articles.map((article, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: index * 0.1 }}
          >
            <Card className="h-full flex flex-col">
              <img src={article.image} alt={article.title} className="w-full h-48 object-cover" />
              <CardHeader>
                <CardTitle>{article.title}</CardTitle>
                <CardDescription>{article.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <Badge variant="secondary" className="flex items-center w-fit">
                  <Clock className="mr-1 h-3 w-3" />
                  {article.readTime}
                </Badge>
              </CardContent>
              <CardFooter className="mt-auto">
                <Button asChild>
                  <Link to="/blog">Read more</Link>
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export { SuggestedReading };