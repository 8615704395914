import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Section } from "./ui/layout";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Link } from 'react-router-dom';

function GetStarted() {
  return (
    <Section className="py-16">
      <Card className="bg-blue-600 text-white">
        <CardHeader>
          <CardTitle className="text-3xl font-bold">Ready to make a difference?</CardTitle>
          <CardDescription className="text-blue-100">
            Start reducing your company&apos;s carbon footprint today.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <p className="mb-6">
            Join the businesses already making a positive impact on the environment.
          </p>
          <Button asChild size="lg" variant="secondary">
            <a
              href="https://tidycal.com/bakri/bayti-eco-intro-call"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              Schedule a Call <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </Button>
        </CardContent>
      </Card>
    </Section>
  );
}

export default GetStarted;
