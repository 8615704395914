import { createSlice } from "@reduxjs/toolkit";
import { supabase } from "../../supabase";

const initialState = {
    accessToken:undefined,
    profile:undefined,
    business:undefined,
    allContacts:[],
    allProfiles:[],
    memberBusinessProfile: null,
 };

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        updateProfile: (state, action) => {
            state.profile = { ...state.profile, ...action.payload };
        },
        getProfile:(state,action)=>{
            state.profile = action.payload;
        },
        getPublicBusinessProfile:(state,action)=>{
            state.business = action.payload;
        },
        getAllContacts:(state,action)=>{
            state.allContacts = action.payload;
        },
        getAllProfiles:(state,action)=>{
            state.allProfiles = action.payload;
        },
        getMemberBusinessProfile: (state, action) => {
            state.memberBusinessProfile = action.payload;
        },
        // userSignUp:(state,action)=>{
        //     sessionStorage.setItem(
        //         'auth',
        //         JSON.stringify({
        //           accessToken: action.user.access_token,
        //           user: action.user,
        //         })
        //       );
        //       state.accessToken = action.payload.accessToken;
        //       state.user = action.payload.user;

        // },
        // userLoggedIn:(state,action) =>{
        //     state.accessToken = action.payload.accessToken;
        //     state.user = action.payload.user;
        //     state.provider_token = action.payload.providerToken;
        // },
        // userLoggedOut:(state,action) =>{
        //     state.accessToken = undefined;
        //     state.user = undefined;
        // },
        // userProfileCreate: async (state,action)   =>{
        //     const profileData = {
        //         user_id: action.payload.user.id,
        //       };
        //     await supabase
        //       .from('profiles')
        //       .insert(profileData)
        //       .select();
        // }
    },
});

export const { 
  getProfile,
  setProfile,
  updateProfile,
  getAllContacts, 
  getPublicBusinessProfile, 
  getAllProfiles, 
  getMemberBusinessProfile 
} = profileSlice.actions;
export default profileSlice.reducer;