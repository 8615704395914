import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { getAllOrders, getAllPackages,getAllOrdersByUser,getAllOrdersByBusiness } from "./packageSlice";

export const packageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPackages: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('packages')
            .select("*");

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
            const { data } = await queryFulfilled;
            dispatch(getAllPackages(data));
        } catch (error) {
            console.error('Failed to fetch packages:', error);
        }
    }
    }),
    getAllOrders: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('packageorders')
            .select(`
              *,
              order_items (*),
              profiles:profile_id (id, profile_name)
          `);

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }

          // Transform the data to ensure business_name is always present
          const transformedData = data.map(order => ({
            ...order,
            business_name: order.business_name || 'Bayti Eco'
          }));

          return { data: transformedData };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          try {
              const { data } = await queryFulfilled;
              dispatch(getAllOrders(data));
          } catch (error) {
              console.error('Failed to fetch orders:', error);
          }
      }
    }),
    getOrdersByProfileId: builder.query({
      async queryFn(profile_id) {
        try {
          const { data, error } = await supabase
            .from('packageorders')
            .select(`
              id,
              total_price,
              total_items,
              total_trees_planted,
              total_co2_offset,
              profile_id,
              created_at,
              founded_projects,
              business_profile_id,
              business_name,
              status,
              is_business_package,
              payment_intent,
              order_items (
                id,
                order_id,
                item_id,
                title,
                price,
                quantity,
                total,
                trees_planted,
                co2_offset
              )
            `)
            .eq('profile_id', profile_id);

          if (error) {
            return {
              error: {
                status: 'CUSTOM_ERROR',
                message: error.message || 'An error occurred',
              },
            };
          }
          return { data };
        } catch (err) {
          return {
            error: {
              status: 'FETCH_ERROR',
              message: err.message || 'An error occurred',
            },
          };
        }
      },
      async onQueryStarted(profile_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(getAllOrdersByUser(data));
          }
        } catch (error) {
          console.error('Failed to fetch orders by profile:', error);
        }
      }
    }),
    getOrdersByBusinessId: builder.query({
      async queryFn(business_profile_id) {
        try {
          const { data, error } = await supabase
            .from('packageorders')
            .select(`
              id,
              total_price,
              total_items,
              total_trees_planted,
              total_co2_offset,
              profile_id,
              created_at,
              founded_projects,
              business_profile_id,
              business_name,
              status,
              is_business_package,
              payment_intent,
              order_items (
                id,
                order_id,
                item_id,
                title,
                price,
                quantity,
                total,
                trees_planted,
                co2_offset
              ),
              profiles:profile_id (id, profile_name)
            `)
            .eq('business_profile_id', business_profile_id);

          if (error) {
            throw error;
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(business_profile_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(getAllOrdersByBusiness(data));
          }
        } catch (error) {
          console.error('Failed to fetch orders by business:', error);
        }
      }
    }),
    createOrder: builder.mutation({
      queryFn: async (orderData) => {
        try {
          const { cartItems, totalItems, totalPrice, totalImpact, profile_id, paymentIntent, business_name, business_profile_id } = orderData;
          
          console.log('Received order data:', orderData);

          // Prepare the order data
          const orderInsertData = {
            total_price: totalPrice,
            total_items: totalItems,
            total_trees_planted: totalImpact.treesPlanted,
            total_co2_offset: totalImpact.combinedEmissionsCountered,
            founded_projects: totalImpact.totalFundedProjects,
            profile_id,
            payment_intent: paymentIntent, // Ensure payment intent is included
            is_business_package: false,
          };

          // Add business-related fields if they are provided
          if (business_name && business_profile_id) {
            orderInsertData.business_name = business_name;
            orderInsertData.business_profile_id = business_profile_id;
            orderInsertData.is_business_package = true;
          }

          console.log('Order insert data:', orderInsertData);

          // Create the main order
          const { data: order, error: orderError } = await supabase
            .from('packageorders')
            .insert([orderInsertData])
            .select();

          if (orderError) throw orderError;
          
          console.log('Created main order:', order);

          const orderId = order[0].id;

          // Prepare order items data
          const orderItemsData = cartItems.map(item => {
            const price = item.product?.price || item.price;
            const quantity = item.quantity;
            const total = price * quantity;

            console.log(`Item ${item.id}: price = ${price}, quantity = ${quantity}, total = ${total}`);

            return {
              order_id: orderId,
              item_id: item.id,
              title: item.title,
              quantity: quantity,
              price: price,
              total: total,
              trees_planted: item.impact?.treesPlanted * quantity || 0,
              co2_offset: item.impact?.combinedEmissionsCountered * quantity || 0,
            };
          });

          // Insert order items
          const { data: orderItems, error: orderItemsError } = await supabase
            .from('order_items')
            .insert(orderItemsData);

          if (orderItemsError) {
            console.error('Error inserting order items:', orderItemsError);
            throw orderItemsError;
          }

          // Update the profiles table to increment the tree, plastic, and carbon values
          let bottles = 0;
          let carbon = 0;
          const { data: profileData, error: profileError } = await supabase
            .rpc('increment_fields', {
              profile_id,
              tree_increment: parseInt(totalImpact.treesPlanted, 10),
              plastic_increment: parseInt(bottles, 10),
              carbon_increment: parseInt(carbon, 10),
              co2_increment: parseInt(totalImpact.combinedEmissionsCountered, 10),
              pid: null
            });
  
          if (profileError) {
            console.log(profileError.message);
            return { error: { status: 'UPDATE_ERROR', message: profileError.message || 'An error occurred' } };
          }
          return { data: { order, orderItems, profileData } };
        } catch (error) {
          console.error('Error creating order:', error);
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      },
      async onQueryStarted(orderData, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(packageApi.endpoints.getOrdersByProfileId.initiate(orderData.profile_id));
          }
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),

    createPackage: builder.mutation({
      queryFn: async (formData) => {
        try {
          
          const packageData = {};
          formData.forEach((value, key) => {
            packageData[key] = value;
           });
          console.log(packageData);
          if (formData.has('image')) {
            const imageFile = formData.get('image');
            const imageFileName = `${Math.random()}-${imageFile.name}`.replace(/[\s/]/g, '');
            const imageFilePath = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile/${imageFileName}`;
            console.log(imageFilePath);
            const { data: imageUploadData, error: imageUploadError } = await supabase
              .storage
              .from('profile')
              .upload(imageFileName, imageFile);

            if (imageUploadError) {
              return { error: imageUploadError };
            }
            console.log("Image uploaded")
            packageData.image = imageFilePath;
          }

          const { data, error } = await supabase
            .from('packages')
            .insert(packageData)
            .select("*");
      
          if (error) {
            return { error: { status: 'CREATE_ERROR', message: error.message || 'An error occurred' } };
          }
      
          return { data };

        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled; 
          dispatch(packageApi.endpoints.getAllPackages.initiate());
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    updateOrderStatus: builder.mutation({
      queryFn: async (statusData) => {
        try {
          const { data, error } = await supabase
            .from('packageorders')
            .update({ status: statusData.newStatus })
            .eq('id', statusData.orderId)
            .select();  // Add this to return the updated data

          if (error) {
            throw error;  // Throw the error instead of returning it
          }
  
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(statusData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Invalidate and refetch the specific business orders instead of all packages
          dispatch(packageApi.util.invalidateTags(['BusinessOrders']));
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    updatePackage: builder.mutation({
      queryFn: async (formData) => {
        try {
          const { data, error } = await supabase
            .from('packages')
            .update(formData)
            .eq('id', formData.id)
            .select("*");
      
          if (error) {
            return { error: { status: 'UPDATE_ERROR', message: error.message || 'An error occurred' } };
          }
      
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(packageApi.endpoints.getAllPackages.initiate()); 
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    deletePackage: builder.mutation({
      queryFn: async (id) => {
        try {
          const { data, error } = await supabase
            .from('packages')
            .delete()
            .eq('id', id)
            .select("*");
    
          if (error) {
            return { error: { status: 'DELETE_ERROR', message: error.message || 'An error occurred' } };
          }
    
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(packageApi.endpoints.getAllPackages.initiate());
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
  }),
});

export const {
  useGetAllPackagesQuery,
  useCreatePackageMutation,
  useUpdatePackageMutation,
  useDeletePackageMutation,
  useCreateOrderMutation,
  useGetAllOrdersQuery,
  useUpdateOrderStatusMutation,
  useGetOrdersByProfileIdQuery,
  useGetOrdersByBusinessIdQuery,
} = packageApi;
