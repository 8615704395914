import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Trees, Droplet, Globe, ArrowRight, Check, Zap } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "./ui/dialog";
import { ScrollArea } from "./ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Badge } from "./ui/badge";

const ActionCards = () => {
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const cards = [
    {
      title: 'Plant Certified Trees',
      description: 'Instantly plant mangrove trees in one of our dedicated planting locations.',
      image: '/images/planting trees.jpg',
      icon: Trees,
      color: 'bg-green-500',
      impact: {
        biodiversity: 'High',
        community: 'Significant',
      },
      details: `Our tree planting initiative focuses on mangrove trees, which are crucial for coastal ecosystems. Mangroves are excellent carbon sinks, absorbing up to four times more carbon than rainforests. They also provide habitat for diverse marine life and protect coastlines from erosion and storm surges.`,
      benefits: [
        'Absorbs kg of CO2 per year',
        'Protects coastlines from erosion',
        'Supports local communities',
      ],
      locations: ['Indonesia', 'Philippines', 'Madagascar'],
    },
    {
      title: 'Remove Ocean Plastic',
      description: 'Protect oceans, shorelines, and vulnerable waterways from plastic waste.',
      image: '/images/bottle recycle.jpg',
      icon: Droplet,
      color: 'bg-blue-500',
      impact: {
        marine: 'Significant',
        economy: 'Local boost',
      },
      details: `Our ocean plastic removal program tackles one of the most pressing environmental issues of our time. Every year, millions of tons of plastic waste enter our oceans, harming marine life and ecosystems.`,
      benefits: [
        'Removes plastic from oceans',
        'Protects marine biodiversity',
        'Reduces microplastic pollution',
        'Creates jobs in coastal communities',
      ],
      locations: ['Southeast Asia', 'Caribbean', 'Mediterranean'],
    },
    {
      title: 'Fund Climate Projects',
      description: 'Support wind and solar farms, protect communities and ecosystems.',
      image: '/images/human project.jpg',
      icon: Globe,
      color: 'bg-purple-500',
      impact: {
        communities: 'Wide-reaching',
        ecosystems: 'Protected',
      },
      details: `By funding climate projects, you're investing in a sustainable future for our planet. Our carefully selected projects focus on renewable energy development, ecosystem protection, and community resilience.`,
      benefits: [
        'Reduces reliance on fossil fuels',
        'Protects critical ecosystems',
        'Enhances community resilience',
      ],
      locations: ['Global', 'Developing nations focus'],
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: index * 0.1 }}
          >
            <Card className="h-full flex flex-col overflow-hidden group hover:shadow-xl transition-shadow duration-300">
              <div className={`h-2 ${card.color}`}></div>
              <div className="relative">
                <img src={card.image} alt={card.title} className="w-full h-48 object-cover" />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <Button variant="secondary" onClick={() => {
                    setSelectedCard(card);
                    setOpen(true);
                  }}>
                    Learn More
                  </Button>
                </div>
              </div>
              <CardHeader>
                <CardTitle className="flex items-center text-2xl">
                  <card.icon className="mr-3 h-6 w-6" />
                  {card.title}
                </CardTitle>
                <CardDescription className="text-base">{card.description}</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <h4 className="font-semibold mb-2">Key Impacts:</h4>
                <ul className="space-y-1">
                  {Object.entries(card.impact).map(([key, value], i) => (
                    <li key={i} className="flex items-center">
                      <Check className="mr-2 h-4 w-4 text-green-500" />
                      <span className="capitalize">{key}: </span>
                      <span className="ml-1 font-semibold">{value}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="flex justify-end items-center">
                <Button variant="outline" onClick={() => {
                  setSelectedCard(card);
                  setOpen(true);
                }}>
                  Details <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle className="flex items-center text-2xl">
              {selectedCard?.icon && <selectedCard.icon className="mr-3 h-6 w-6" />}
              {selectedCard?.title}
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="mt-4 max-h-[60vh] overflow-y-auto pr-4">
            <Tabs defaultValue="overview">
              <TabsList className="w-full">
                <TabsTrigger value="overview">Overview</TabsTrigger>
                <TabsTrigger value="benefits">Benefits</TabsTrigger>
                <TabsTrigger value="locations">Locations</TabsTrigger>
              </TabsList>
              <TabsContent value="overview">
                <DialogDescription className="text-base">{selectedCard?.details}</DialogDescription>
                <div className="mt-4">
                  <h4 className="font-semibold mb-2">Key Impacts:</h4>
                  <ul className="space-y-2">
                    {selectedCard && Object.entries(selectedCard.impact).map(([key, value], i) => (
                      <li key={i} className="flex items-center">
                        <Badge variant="outline" className={`mr-2 ${selectedCard.color} text-white`}>
                          {key}
                        </Badge>
                        <span className="font-semibold">{value}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </TabsContent>
              <TabsContent value="benefits">
                <h4 className="font-semibold mb-2">Project Benefits:</h4>
                <ul className="space-y-2">
                  {selectedCard?.benefits.map((benefit, index) => (
                    <li key={index} className="flex items-start">
                      <Zap className="mr-2 h-5 w-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                      <span>{benefit}</span>
                    </li>
                  ))}
                </ul>
              </TabsContent>
              <TabsContent value="locations">
                <h4 className="font-semibold mb-2">Project Locations:</h4>
                <ul className="space-y-2">
                  {selectedCard?.locations.map((location, index) => (
                    <li key={index} className="flex items-center">
                      <Globe className="mr-2 h-5 w-5 text-blue-500" />
                      <span>{location}</span>
                    </li>
                  ))}
                </ul>
              </TabsContent>
            </Tabs>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { ActionCards };
