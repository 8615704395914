import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allBlogs: [],
    allCategories: [],
    allAuthors: [],
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        setAllBlogs: (state, action) => { // Action to set blogs
            state.allBlogs = action.payload;
        },
        setAllCategories: (state, action) => { // Action to set categories
            state.allCategories = action.payload;
        },
        setAllAuthors: (state, action) => { // Action to set authors
            state.allAuthors = action.payload;
        },
    },
});

export const { setAllBlogs, setAllCategories, setAllAuthors } = blogSlice.actions; // Updated export
export default blogSlice.reducer;