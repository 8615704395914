import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userLoggedIn } from "../features/auth/authSlice";
import { useLazyGetProfileQuery } from "../features/profile/profileApi"; // Import the lazy query hook

export default function useAuthCheck() {
  const dispatch = useDispatch();
  const [authCheck, setAuthCheck] = useState(false);
  const [triggerGetProfile] = useLazyGetProfileQuery(); // Use the lazy query hook

  useEffect(() => {
    const sessionAuth = sessionStorage?.getItem('auth');
    if (sessionAuth) {
      const auth = JSON.parse(sessionAuth);
      if (auth?.accessToken && auth?.user) {
        dispatch(userLoggedIn({
          accessToken: auth.accessToken,
          user: auth.user,
        }));

        // Trigger the getProfile query
        triggerGetProfile({ user_id: auth.user.id }).unwrap()
          .then(profile => {
            // Handle the profile data as needed
            console.log(profile);
          })
          .catch(error => {
            console.error("Failed to fetch profile:", error);
          });
      }
    }
    setAuthCheck(true);
  }, [dispatch, triggerGetProfile]);

  return authCheck;
}
