import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { setAllBlogs, setAllCategories, setAllAuthors } from "./blogSlice"; // Import the actions

export const blogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('blogs')
            .select(`
                *,
                categories (category_name),
                authors (author_name)
            `);

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }

          // Map the data to include category_name and author_name directly in the blog object
          const formattedData = data.map(blog => ({
            ...blog,
            category_name: blog.categories?.category_name,
            author_name: blog.authors?.author_name,
          }));

          return { data: formattedData };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      providesTags: ['Blogs'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setAllBlogs(data)); // Dispatch the action to update the Redux state
          }
        } catch (err) {
          console.error('Error fetching blogs:', err);
        }
      },
    }),
    getAllCategories: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('categories')
            .select("*");

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      providesTags: ['Categories'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setAllCategories(data)); // Dispatch the action to update the Redux state
          }
        } catch (err) {
          console.error('Error fetching categories:', err);
        }
      },
    }),
    getAllAuthors: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('authors')
            .select("*");

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      providesTags: ['Authors'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setAllAuthors(data)); // Dispatch the action to update the Redux state
          }
        } catch (err) {
          console.error('Error fetching authors:', err);
        }
      },
    }),
    createBlog: builder.mutation({
      async queryFn(newBlog) {
        try {
          const { data, error } = await supabase
            .from('blogs')
            .insert(newBlog);

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      invalidatesTags: ['Blogs'],
    }),
    updateBlog: builder.mutation({
        async queryFn(updatedBlog) {
          try {
            const { data, error } = await supabase.from('blogs').update(updatedBlog).eq('id', updatedBlog.id);
            if (error) {
              return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
            }
            return { data };
          } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
          }
        },
        invalidatesTags: ['Blogs'],
    }),
    deleteBlog: builder.mutation({
        async queryFn(id) {
            try {
            const { data, error } = await supabase.from('blogs').delete().eq('id', id);
            if (error) {
                return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
            }
            return { data };
            } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
            }
    },
    invalidatesTags: ['Blogs'],
    }),
    createCategory: builder.mutation({
      async queryFn(newCategory) {
        try {
          const { data, error } = await supabase
            .from('categories')
            .insert(newCategory);

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      invalidatesTags: ['Categories'],
    }),
    createAuthor: builder.mutation({
      async queryFn(newAuthor) {
        try {
          const { data, error } = await supabase
            .from('authors')
            .insert(newAuthor);

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      invalidatesTags: ['Authors'],
    }),
    getBlogById: builder.query({
        async queryFn(slug) {
        try {
            const { data, error } = await supabase.from('blogs').select('*').eq('slug', slug).single();
            if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
            }
            return { data };
        } catch (err) {
            return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
        },
        providesTags: ['Blogs'],
    }),

}),
});

export const {
  
  useGetAllBlogsQuery,
  useGetAllCategoriesQuery,
  useGetAllAuthorsQuery,
  useCreateBlogMutation,
  useCreateCategoryMutation,
  useCreateAuthorMutation,
  useGetBlogByIdQuery,
  useUpdateBlogMutation,
  useDeleteBlogMutation,

   
} = blogApi;