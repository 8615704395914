import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { setDefaultMethod } from "./paymentSlice";

export const paymentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addPaymentMethod: builder.mutation({
          queryFn: async (paymentMethod) => {
            try {
              const { stripe_payment_method_id, card_last4, profile_id } = paymentMethod;
    
              // Insert payment method
              const { data, error } = await supabase
                .from('payment_methods')
                .insert([{ stripe_payment_method_id, card_last4, profile_id }])
                .select();
    
              if (error) throw error;
    
              return { data };
            } catch (error) {
              console.error('Error adding payment method:', error);
              return { error: { status: 'CUSTOM_ERROR', error: error.message } };
            }
          },
        }),
        fetchPaymentMethods: builder.query({
          queryFn: async (profile_id) => {
            try {
              // Fetch payment methods for a specific profile
              const { data, error } = await supabase
                .from('payment_methods')
                .select('*')
                .eq('profile_id', profile_id);
    
              if (error) throw error;
    
              return { data };
            } catch (error) {
              console.error('Error fetching payment methods:', error);
              return { error: { status: 'CUSTOM_ERROR', error: error.message } };
            }
          },
        }),
        setDefaultPaymentMethod: builder.mutation({
          queryFn: async ({ profile_id, payment_method_id }) => {
            try {
              // Update the default payment method for the profile
              const { data, error } = await supabase
                .from('profiles')
                .update({ default_payment_method_id: payment_method_id })
                .eq('id', profile_id);
    
              if (error) throw error;
    
              return { data };
            } catch (error) {
              console.error('Error setting default payment method:', error);
              return { error: { status: 'CUSTOM_ERROR', error: error.message } };
            }
          },
        }),
        fetchDefaultPaymentMethod: builder.query({
          queryFn: async (profile_id) => {
            try {
              const { data, error } = await supabase
                .from('profiles')
                .select('default_payment_method_id')
                .eq('id', profile_id)
                .single();

              if (error) throw error;

              return { data: data.default_payment_method_id };
            } catch (error) {
              console.error('Error fetching default payment method:', error);
              return { error: { status: 'CUSTOM_ERROR', error: error.message } };
            }
          },
          onQueryStarted: async (profile_id, { dispatch, queryFulfilled }) => {
            try {
              const { data } = await queryFulfilled;
              dispatch(setDefaultMethod(data));
            } catch (error) {
              // Handle error if needed
            }
          },
        }),
      }),
    });
    
    export const { useAddPaymentMethodMutation, useFetchPaymentMethodsQuery, useSetDefaultPaymentMethodMutation, useFetchDefaultPaymentMethodQuery } = paymentApi;