import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { ScrollArea } from "./ui/scroll-area";
import Header from './Header';
import HomeFooter from './HomeFooter';

const PrivacyPolicy = () => {
  const sections = [
    {
      title: '1. Introduction',
      content: "At Bayti Eco, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website, mobile application, or services.",
    },
    {
      title: '2. Information We Collect',
      content: (
        <>
          <p>We may collect the following types of information:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Personal information (e.g., name, email address, phone number)</li>
            <li>Usage data (e.g., how you interact with our services)</li>
            <li>Device information (e.g., IP address, browser type)</li>
            <li>Location data (with your consent)</li>
            <li>Payment information (for subscriptions and donations)</li>
          </ul>
        </>
      ),
    },
    {
      title: '3. How We Use Your Information',
      content: (
        <>
          <p>We use your information to:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Provide and improve our services</li>
            <li>Process transactions and manage your account</li>
            <li>Communicate with you about our services and updates</li>
            <li>Analyze usage patterns and optimize user experience</li>
            <li>Comply with legal obligations</li>
          </ul>
        </>
      ),
    },
    {
      title: '4. Data Sharing and Disclosure',
      content: "We may share your information with third-party service providers, partners, and as required by law. We do not sell your personal information to third parties.",
    },
    {
      title: '5. Data Security',
      content: "We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.",
    },
    {
      title: '6. Your Rights and Choices',
      content: (
        <>
          <p>You have the right to:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Access and update your personal information</li>
            <li>Opt-out of marketing communications</li>
            <li>Request deletion of your data (subject to legal requirements)</li>
            <li>Object to the processing of your information</li>
          </ul>
        </>
      ),
    },
    {
      title: "7. Children's Privacy",
      content: "Our services are not intended for children under 13. We do not knowingly collect personal information from children under 13.",
    },
    {
      title: '8. Changes to This Policy',
      content: "We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Policy on this page and updating the \"Last Updated\" date.",
    },
    {
      title: '9. Contact Us',
      content: (
        <>
          If you have any questions about this Privacy Policy, please contact us at:{' '}
          <Button variant="link" className="p-0 h-auto" asChild>
            <a href="mailto:privacy@bayti.eco">
              privacy@bayti.eco <ChevronRight className="h-4 w-4 ml-1" />
            </a>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <main className="bg-gray-50 min-h-screen">
        <div className="container mx-auto px-4 py-16 max-w-3xl">
          <motion.h1 
            className="text-4xl font-bold text-center mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Privacy Policy
          </motion.h1>
          <p className="text-center text-gray-500 mb-8">Last Updated: August 27, 2024</p>

          <Card className="mb-8">
            <CardHeader>
              <CardTitle>Table of Contents</CardTitle>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                <ul className="space-y-2">
                  {sections.map((section, index) => (
                    <li key={index}>
                      <Button variant="link" asChild className="p-0 h-auto">
                        <a href={`#section-${index}`}>
                          {section.title} <ChevronRight className="h-4 w-4 ml-1" />
                        </a>
                      </Button>
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </CardContent>
          </Card>

          <Accordion type="single" collapsible className="w-full">
            {sections.map((section, index) => (
              <AccordionItem key={index} value={`item-${index}`} id={`section-${index}`}>
                <AccordionTrigger>{section.title}</AccordionTrigger>
                <AccordionContent>
                  <div className="prose max-w-none">
                    {typeof section.content === 'string' ? (
                      <p>{section.content}</p>
                    ) : (
                      section.content
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>

          <Card className="mt-8">
            <CardContent className="pt-6">
              <p className="text-center italic text-gray-600">
                By using Bayti Eco&apos;s services, you agree to the collection and use of information in accordance with this Privacy Policy.
              </p>
            </CardContent>
          </Card>
        </div>
      </main>
      <HomeFooter />
    </>
  );
};

export default PrivacyPolicy;