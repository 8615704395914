import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import '../lib/utils/ImageBlot'; // Ensure this is imported before Quill is initialized
import { supabase } from '../app/supabase';

const TextEditor = forwardRef(
    ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
        const containerRef = useRef(null);
        const defaultValueRef = useRef(defaultValue);
        const onTextChangeRef = useRef(onTextChange);
        const onSelectionChangeRef = useRef(onSelectionChange);
        const quillRef = useRef(null); // Store the Quill instance

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
            onSelectionChangeRef.current = onSelectionChange;
        });

        useEffect(() => {
            if (quillRef.current) {
                quillRef.current.enable(!readOnly);
            }
        }, [readOnly]);

        useEffect(() => {
            const container = containerRef.current;
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{ 'header': [1, 2, false] }], // Header options
                            [{ 'font': ['serif', 'sans-serif', 'monospace'] }], // Font family options
                            [{ 'size': ['small', false, 'large', 'huge'] }], // Font size options
                            ['bold', 'italic', 'underline', 'strike'], // Text formatting options
                            [{ 'color': [] }, { 'background': [] }], // Text color and background color
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // List options
                            [{ 'align': [] }], // Text alignment options
                            ['link', 'image', 'video'], // Media insertion options
                            ['clean'], // Remove formatting button
                            ['custom-size'] // Custom font size input
                        ],
                        handlers: {
                            'custom-size': function() {
                                const size = prompt('Enter font size (e.g., 16px, 1.5em):');
                                if (size) {
                                    const range = quill.getSelection();
                                    if (range) {
                                        quill.formatText(range.index, range.length, { size: size });
                                    }
                                }
                            }
                        }
                    }
                },
            });

            quillRef.current = quill; // Store the Quill instance

            if (defaultValueRef.current) {
                quill.setContents(defaultValueRef.current);
            }

            quill.on('text-change', (...args) => {
                const content = quill.root.innerHTML; // Get the current content
                onTextChangeRef.current?.(content); // Call the onTextChange prop
             });

            quill.on('selection-change', (...args) => {
                onSelectionChangeRef.current?.(...args);
            });

            // Custom image handler to upload to Supabase
            quill.getModule('toolbar').addHandler('image', async () => {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.click();

                input.onchange = async () => {
                    const file = input.files[0];
                    if (file) {
                        const filePath = `blogs/media/${file.name}`; // Define the path for the file
                            // If the file does not exist, upload it
                            const { data, error } = await supabase.storage
                                .from('images') // Replace with your bucket name
                                .upload(filePath, file);
                            let publicURL;
                            if (error) {
                                publicURL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/images/${filePath}`;
                                console.log('Image URL (uploaded):', publicURL);
                                console.error('Error uploading file:', error);
                            }else{
                                // Construct the public URL using the correct path
                                publicURL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/images/${data.path}`; 
                                console.log('Image URL (uploaded):', publicURL);
                            }

                            

                            const range = quill.getSelection();
                            if (range) {
                                quill.insertEmbed(range.index, 'image', publicURL);
                                quill.setSelection(range.index + 1); // Move the cursor after the image
                            }
                      
                    }
                };
            });

            return () => {
                quillRef.current = null; // Clear the reference
                container.innerHTML = ''; // Clean up the container
            };
        }, []);

        return (
            <div 
                ref={containerRef} 
                style={{ 
                    height: '250px', 
                    overflowY: 'auto', 
                    backgroundColor: 'white' // Set the background color to white
                }}
            ></div>
        );
    },
);

TextEditor.displayName = 'TextEditor';

export default TextEditor;