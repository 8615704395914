import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, Section } from "./ui/layout";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "./ui/card";
import { Button } from "./ui/button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";
import { Badge } from "./ui/badge";
import { Trees, Recycle, Globe, UserCircle, GaugeCircle, ShoppingCart, Check, Zap, Shield, Award } from 'lucide-react';
import Partners from './Partners';
import GetStarted from './GetStarted';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const SeePlans = () => {
  const [activeToggle, setActiveToggle] = useState('talk');

  const plans = [
    { title: 'Guardian', price: '25', trees: 2, bottles: 30, co2: 100 },
    { title: 'Protector', price: '49', trees: 4, bottles: 50, co2: 200 },
    { title: 'Warrior', price: '89', trees: 6, bottles: 30, co2: 750, popular: true },
    { title: 'Hero', price: '149', trees: 12, bottles: 40, co2: 1125 },
  ];

  const legendPlan = {
    title: 'Legend',
    price: '449',
    trees: 100,
    bottles: 1000,
    co2: 2000,
  };

  const features = [
    {
      icon: <UserCircle className="w-12 h-12 text-pink-500" />,
      title: 'Impact Profile',
      items: [
        'Real-time totals',
        'View your total impact',
        'Share all you have done',
        'Public profile to showcase',
        'Access your dashboard',
      ],
    },
    {
      icon: <GaugeCircle className="w-12 h-12 text-blue-500" />,
      title: 'Bayti Dashboard',
      items: [
        'Real-time certificates',
        'Top-up in the Impact Shop',
        'Embed tree count widget for websites',
        'QR Code for your business impact shop',
        'Invite collaborators',
      ],
    },
    {
      icon: <ShoppingCart className="w-12 h-12 text-yellow-500" />,
      title: 'Access Impact Shop',
      items: [
        'Counter your activities',
        'Top-up your trees anytime',
        'Remove extra ocean plastic',
        'Reduce your travel emissions',
        'Carbon neutral commutes',
      ],
    },
  ];

  const handleTabChange = (value) => {
    setActiveToggle(value);
    if (value === 'talk') {
      document.getElementById('get-started-section').scrollIntoView({ behavior: 'smooth' });
    } else if (value === 'included') {
      document.getElementById('whats-included-section').scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container className="py-12 bg-gradient-to-br from-gray-50 to-white">
      <Section className="text-center mb-16">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <Badge className="mb-4 px-3 py-1 text-sm font-semibold bg-blue-100 text-blue-800">For Teams</Badge>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-blue-900">Choose Your Impact Plan</h1>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Start countering your team&apos;s carbon footprint today and make a lasting impact on the environment.
          </p>
        </motion.div>
        <Tabs value={activeToggle} onValueChange={handleTabChange} className="w-full max-w-md mx-auto">
          <TabsList className="grid w-full grid-cols-2 p-1 bg-blue-100 rounded-lg">
            <TabsTrigger 
              value="talk" 
              className={`${activeToggle === 'talk' ? 'bg-white text-blue-600 shadow-md' : 'text-blue-600'} rounded-md transition-all duration-200`}
            >
              Talk with Us
            </TabsTrigger>
            <TabsTrigger 
              value="included" 
              className={`${activeToggle === 'included' ? 'bg-white text-blue-600 shadow-md' : 'text-blue-600'} rounded-md transition-all duration-200`}
            >
              What&apos;s Included
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </Section>

      <Section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
        <AnimatePresence>
          {plans.map((plan, index) => (
            <motion.div
              key={plan.title}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ delay: index * 0.1 }}
            >
              <Card className={`relative overflow-hidden ${plan.popular ? 'border-blue-500 shadow-xl' : 'shadow-lg'} transition-all duration-300 hover:shadow-2xl hover:-translate-y-1`}>
                {plan.popular && (
                  <Badge className="absolute top-4 right-4 bg-blue-500 text-white px-2 py-1">Most Popular</Badge>
                )}
                <CardHeader className="bg-gradient-to-br from-blue-50 to-indigo-50 pb-10">
                  <CardTitle className="text-2xl font-bold text-blue-900">{plan.title}</CardTitle>
                  <CardDescription className="text-3xl font-bold text-blue-600 mt-2">
                    {plan.price}<span className="text-lg font-normal">Dhs</span>
                    <span className="text-base font-normal text-gray-600">/month</span>
                  </CardDescription>
                </CardHeader>
                <CardContent className="pt-8">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Trees className="w-6 h-6 mr-3 text-green-500" />
                      <span className="text-lg"><strong>{plan.trees}</strong> Trees Planted</span>
                    </li>
                    <li className="flex items-center">
                      <Recycle className="w-6 h-6 mr-3 text-blue-500" />
                      <span className="text-lg"><strong>{plan.bottles}</strong> ocean-bound plastic bottles removed</span>
                    </li>
                    <li className="flex items-center">
                      <Globe className="w-6 h-6 mr-3 text-yellow-500" />
                      <span className="text-lg"><strong>{plan.co2}</strong> kg CO2 via humanitarian projects</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button asChild className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 text-lg font-semibold transition-all duration-200">
                    <Link to={`/checkout/business/${plan.title.toLowerCase()}`}>
                      Get Started
                    </Link>
                  </Button>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </Section>

      <Section className="mb-16 flex justify-center">
        <motion.div
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          className="w-full max-w-2xl"
        >
          <Card className="shadow-2xl overflow-hidden">
            <div className="bg-gradient-to-r from-yellow-400 to-orange-500 p-1">
              <CardHeader className="bg-white">
                <div className="flex items-center justify-between">
                  <CardTitle className="text-3xl font-bold text-blue-900">{legendPlan.title}</CardTitle>
                  <Award className="w-12 h-12 text-yellow-500" />
                </div>
                <CardDescription className="text-4xl font-bold text-blue-600 mt-2">
                  {legendPlan.price}<span className="text-xl font-normal">Dhs</span>
                  <span className="text-lg font-normal text-gray-600">/month</span>
                </CardDescription>
              </CardHeader>
            </div>
            <CardContent className="pt-8">
              <ul className="space-y-6">
                <li className="flex items-center">
                  <Trees className="w-8 h-8 mr-4 text-green-500" />
                  <span className="text-xl"><strong>{legendPlan.trees}</strong> Trees Planted</span>
                </li>
                <li className="flex items-center">
                  <Recycle className="w-8 h-8 mr-4 text-blue-500" />
                  <span className="text-xl"><strong>{legendPlan.bottles}</strong> Ocean-bound plastic bottles removed</span>
                </li>
                <li className="flex items-center">
                  <Globe className="w-8 h-8 mr-4 text-yellow-500" />
                  <span className="text-xl"><strong>{legendPlan.co2}</strong> kg CO2 via humanitarian projects</span>
                </li>
              </ul>
            </CardContent>
            <CardFooter>
              <Button asChild className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-yellow-500 hover:to-orange-600 text-white py-6 text-xl font-bold transition-all duration-200">
                <Link to={`/checkout/business/legend`}>
                  Become a Legend
                </Link>
              </Button>
            </CardFooter>
          </Card>
        </motion.div>
      </Section>

      <Partners />

      <Section id="whats-included-section" className="mb-16">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">What&apos;s Included in Every Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ delay: index * 0.2 }}
            >
              <Card className="h-full hover:shadow-xl transition-all duration-300">
                <CardHeader>
                  <div className="mb-4 bg-blue-100 p-4 rounded-full inline-block">{feature.icon}</div>
                  <CardTitle className="text-2xl font-bold text-blue-900">{feature.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-3">
                    {feature.items.map((item, itemIndex) => (
                      <li key={itemIndex} className="flex items-center">
                        <Check className="w-5 h-5 mr-3 text-green-500 flex-shrink-0" />
                        <span className="text-gray-700">{item}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </Section>

      <Section id="get-started-section">
        <GetStarted />
      </Section>
    </Container>
  );
};

export default SeePlans;