import React,{useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ShoppingCart, ChevronDown, Plus, Minus, X, 
  Tree, Droplet, Globe, CloudSun, Facebook, Linkedin, Twitter, Download, MapPin
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import Particles from 'react-tsparticles';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetClose } from "./ui/sheet";
import { Badge } from "./ui/badge";
import { ScrollArea } from "./ui/scroll-area";
import { 
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { 
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { useSelector, useDispatch } from 'react-redux';
import { addToCart,removeFromCart,selectCartItems } from '../app/features/cart/cartSlice';

// Utility functions
function calculateTreeCost(treesPlanted) {
  let totalCost = 0;
  for (let i = 1; i <= treesPlanted; i++) {
    if (i === 1) totalCost += 10;
    else if (i === 2) totalCost += 8;
    else if (i === 3) totalCost += 7;
    else totalCost += 6;
  }
  return totalCost;
}

function calculateProjectCost(co2Offset) {
  return Math.ceil(co2Offset / 50) * 5;
}

function calculateTotalPrice(treesPlanted, co2Offset) {
  const treeCost = calculateTreeCost(treesPlanted);
  const projectCost = calculateProjectCost(co2Offset);
  return treeCost + projectCost;
}

// Packages data

  const packages = [
    {
      id: 1,
      title: 'Plant a certified tree',
      description: 'Plant a tree to sequester carbon emissions',
      price: calculateTotalPrice(1, 0), // 10 AED for 1 tree
      image: '🌳',
      impact: {
        treesPlanted: 1,
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 0,
        combinedEmissionsCountered: 5.9, // 1 * 5.9
      },
      calculation: {
        treesPlanted: 1,
        co2PerTree: 5.9,
        treeCost: 10,
        totalEmissions: 5.9,
      },
      detailedDescription:
        'This climate package plants one certified tree to sequester carbon emissions.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 2,
      title: 'Recover 20 plastic bottles',
      description:
        'Save vulnerable waterways and coastlines from plastic pollution.',
      price: 18, // 10 AED for project (69.5 kg CO₂)
      image: '🍶',
      impact: {
        treesPlanted: 0,
        co2PerTree: 0,
        totalFundedProjects: 0,
        combinedEmissionsCountered: 0, // Based on bottles recovered
      },
      calculation: {
        bottlesRecovered: 20,
        // No change
      },
      detailedDescription:
        'This climate package recovers 20 plastic bottles, preventing them from polluting our environment.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 3,
      title: 'Domestic flight',
      description: 'Counter your return domestic flight',
      price: calculateTotalPrice(5, 160), // 37 AED (trees) + 20 AED (project) = 57 AED
      image: '🛩️',
      impact: {
        treesPlanted: 5, // Offsetting 29.5 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 160, // Funding offsets 160 kg CO₂
        combinedEmissionsCountered: 189.5, // 5 * 5.9 + 160 kg CO₂
      },
      calculation: {
        flightDistance: 500,
        co2PerKm: 0.189,
        returnTrip: true,
        totalFlightEmissions: 189.5, // 500 km * 0.189 kg/km * 2
        treesCost: calculateTreeCost(5), // 37 AED
        projectCost: calculateProjectCost(160), // 20 AED
      },
      detailedDescription:
        'This climate package counters the emissions of a return domestic flight averaging 500km each way.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 4,
      title: 'Conference attendee',
      description: 'Make your conference attendance carbon-neutral',
      price: calculateTotalPrice(6, 210), // 39 AED (trees) + 21 AED (project) = 60 AED
      image: '🎪',
      impact: {
        treesPlanted: 6, // Offsetting 35.4 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 210, // Funding offsets 210 kg CO₂
        combinedEmissionsCountered: 245.4, // 6 * 5.9 + 210 kg CO₂
      },
      calculation: {
        eventDurationDays: 3,
        co2PerDay: 81.8,
        totalEventEmissions: 245.4, // 81.8 kg/day * 3 days
        treesCost: calculateTreeCost(6), // 39 AED
        projectCost: calculateProjectCost(210), // 21 AED
      },
      detailedDescription:
        'This climate package offsets the carbon emissions generated during a 3-day conference attendance.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 5,
      title: 'Year of train commutes',
      description: 'Offset a year of daily train commuting',
      price: calculateTotalPrice(5, 150), // 36 AED (trees) + 18 AED (project) = 54 AED
      image: '🚆',
      impact: {
        treesPlanted: 5, // Offsetting 29.5 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 150, // Funding offsets 150 kg CO₂
        combinedEmissionsCountered: 179.5, // 5 * 5.9 + 150 kg CO₂
      },
      calculation: {
        dailyCommuteDistanceKm: 20,
        daysPerYear: 365,
        co2PerKm: 0.025,
        totalCommuteEmissions: 179.5, // 20 km * 0.025 kg/km * 365 days
        treesCost: calculateTreeCost(5), // 36 AED
        projectCost: calculateProjectCost(150), // 18 AED
      },
      detailedDescription:
        'This climate package offsets the emissions from a year of daily 20km train commutes.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 6,
      title: 'Short-haul flight',
      description: 'Counter your return short-haul flight',
      price: calculateTotalPrice(10, 330), // 55 AED (trees) + 33 AED (project) = 88 AED
      image: '✈️',
      impact: {
        treesPlanted: 10, // Offsetting 59 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 330, // Funding offsets 330 kg CO₂
        combinedEmissionsCountered: 389, // 10 * 5.9 + 330 kg CO₂
      },
      calculation: {
        flightDistanceKm: 1000,
        co2PerKm: 0.19,
        returnTrip: true,
        totalFlightEmissions: 389, // 1000 km * 0.19 kg/km * 2
        treesCost: calculateTreeCost(10), // 55 AED
        projectCost: calculateProjectCost(330), // 33 AED
      },
      detailedDescription:
        'This climate package offsets the emissions of a return short-haul flight covering 1000km each way.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 7,
      title: 'Long-haul flight',
      description: 'Counter your return long-haul flight',
      price: calculateTotalPrice(15, 580), // 80 AED (trees) + 45 AED (project) = 125 AED
      image: '🌎',
      impact: {
        treesPlanted: 15, // Offsetting 88.5 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 580, // Funding offsets 580 kg CO₂
        combinedEmissionsCountered: 668.5, // 15 * 5.9 + 580 kg CO₂
      },
      calculation: {
        flightDistanceKm: 4000,
        co2PerKm: 0.084,
        returnTrip: true,
        totalFlightEmissions: 668.5, // 4000 km * 0.084 kg/km * 2
        treesCost: calculateTreeCost(15), // 80 AED
        projectCost: calculateProjectCost(580), // 45 AED
      },
      detailedDescription:
        'This climate package offsets the emissions of a return long-haul flight covering 4000km each way.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 8,
      title: 'Hotel stay',
      description: 'Carbon-neutral hotel stay for one night',
      price: calculateTotalPrice(2, 110), // 18 AED (trees) + 10 AED (project) = 28 AED
      image: '🏨',
      impact: {
        treesPlanted: 2, // Offsetting 11.8 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 110, // Funding offsets 110 kg CO₂
        combinedEmissionsCountered: 121.8, // 2 * 5.9 + 110 kg CO₂
      },
      calculation: {
        nightsStayed: 1,
        co2PerNight: 110,
        totalStayEmissions: 121.8, // 110 kg/night + tree offsets
        treesCost: calculateTreeCost(2), // 18 AED
        projectCost: calculateProjectCost(110), // 10 AED
      },
      detailedDescription:
        'This climate package offsets the emissions from a one-night hotel stay.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 9,
      title: 'Year of public transport commutes',
      description: 'Offset a year of daily public transport commuting',
      price: calculateTotalPrice(3, 70), // 25 AED (trees) + 8 AED (project) = 33 AED
      image: '🚌',
      impact: {
        treesPlanted: 3, // Offsetting 17.7 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 70, // Funding offsets 70 kg CO₂
        combinedEmissionsCountered: 87.7, // 3 * 5.9 + 70 kg CO₂
      },
      calculation: {
        dailyCommuteDistanceKm: 12,
        daysPerYear: 365,
        co2PerKm: 0.02,
        totalCommuteEmissions: 87.7, // 12 km * 0.02 kg/km * 365 days
        treesCost: calculateTreeCost(3), // 25 AED
        projectCost: calculateProjectCost(70), // 8 AED
      },
      detailedDescription:
        'This climate package offsets the emissions from a year of daily 12km public transport commutes.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 10,
      title: 'Year of car commutes',
      description: 'Offset a year of daily car commuting',
      price: calculateTotalPrice(4, 155), // 29 AED (trees) + 15 AED (project) = 44 AED
      image: '🚗',
      impact: {
        treesPlanted: 4, // Offsetting 23.6 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 155, // Funding offsets 155 kg CO₂
        combinedEmissionsCountered: 178.6, // 4 * 5.9 + 155 kg CO₂
      },
      calculation: {
        dailyCommuteDistanceKm: 20,
        daysPerYear: 365,
        co2PerKm: 0.05,
        totalCommuteEmissions: 365, // 20 km * 0.05 kg/km * 365 days
        treesCost: calculateTreeCost(4), // 29 AED
        projectCost: calculateProjectCost(155), // 15 AED
      },
      detailedDescription:
        'This climate package offsets the emissions from a year of daily 20km car commutes.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  
    {
      id: 11,
      title: 'Dual screen usage',
      description: 'Offset lifetime emissions from using dual screens',
      price: calculateTotalPrice(10, 245), // 55 AED (trees) + 28 AED (project) = 83 AED
      image: '🖥️',
      impact: {
        treesPlanted: 10, // Offsetting 59 kg CO₂ with trees
        co2PerTree: 5.9, // Each tree offsets 5.9 kg CO₂
        totalFundedProjects: 245, // Funding offsets 245 kg CO₂
        combinedEmissionsCountered: 304, // 10 * 5.9 + 245 kg CO₂
      },
      calculation: {
        screensUsed: 2,
        co2PerScreen: 133.85,
        totalScreenEmissions: 267.7, // 133.85 kg/screen * 2 screens
        treesCost: calculateTreeCost(10), // 55 AED
        projectCost: calculateProjectCost(245), // 28 AED
      },
      detailedDescription:
        'This climate package offsets the lifetime emissions from using two computer screens.',
      referenceLink:
        'Government conversion factors for company reporting of greenhouse gas emissions',
    },
  ];

const ImpactShop = () => {
  
  const dispatch = useDispatch();
  const cart = useSelector(selectCartItems);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleAddToCart = (pkg) => {
    dispatch(addToCart({ ...pkg, isBusinessItem: false }));
  };

  const handleRemoveFromCart = (pkgId) => {
    dispatch(removeFromCart(pkgId));
  };

  const getTotalImpact = () => {
    return Object.entries(cart).reduce(
      (total, [pkgId, item]) => {
        return {
          treesPlanted: total.treesPlanted + item.impact.treesPlanted * item.quantity,
          co2Offset: total.co2Offset + item.impact.combinedEmissionsCountered * item.quantity,
        };
      },
      { treesPlanted: 0, co2Offset: 0 }
    );
  };

  const getTotalItems = () => {
    return Object.values(cart).reduce((sum, item) => sum + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return Object.values(cart).reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold mb-4">Purchase offset packages</h1>
        <p className="text-xl text-gray-600 mb-6">
          Reduce your carbon footprint and support climate action projects with our carbon offset packages. 
          Choose from tree planting, plastic recycling, and various environmental initiatives to make a positive impact.
        </p>
        <Sheet open={isCartOpen} onOpenChange={setIsCartOpen}>
          <SheetTrigger asChild>
            <Button variant="outline" className="flex items-center">
              <ShoppingCart className="mr-2 h-4 w-4" /> Your items {getTotalItems()}
            </Button>
          </SheetTrigger>
          <SheetContent>
            <CartComponent 
              cart={cart} 
              packages={packages} 
              removeFromCart={handleRemoveFromCart} 
              addToCart={handleAddToCart}
              getTotalPrice={getTotalPrice}
              getTotalImpact={getTotalImpact}
            />
          </SheetContent>
        </Sheet>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {packages.map((pkg) => (
          <PackageCard key={pkg.id} pkg={pkg} addToCart={handleAddToCart} />
        ))}
      </div>
    </div>
  );
};

const PackageCard = ({ pkg, addToCart }) => {
  return (
    <Card className="relative overflow-hidden">
      <CardHeader>
        <div className="text-4xl mb-2">{pkg.image}</div>
        <CardTitle>{pkg.title}</CardTitle>
        <CardDescription>{pkg.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="text-2xl font-bold text-blue-600 mb-2">AED{pkg.price.toFixed(2)}</p>
        <p className="text-sm text-gray-600">
          🌳 {pkg.impact.treesPlanted} trees | 🌍 {pkg.impact.combinedEmissionsCountered.toFixed(1)} kg CO₂
        </p>
      </CardContent>
      <CardFooter className="flex justify-between items-center">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline">Details</Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <PackageDetailsModal pkg={pkg} />
          </DialogContent>
        </Dialog>
        <Button onClick={() => addToCart(pkg)}>
          <Plus className="mr-2 h-4 w-4" /> Add to cart
        </Button>
      </CardFooter>
    </Card>
  );
};

const PackageDetailsModal = ({ pkg }) => {
  return (
    <div>
      <DialogHeader>
        <DialogTitle>{pkg.title}</DialogTitle>
        <DialogDescription>{pkg.detailedDescription}</DialogDescription>
      </DialogHeader>
      <div className="py-4">
        <h4 className="text-sm font-medium mb-2">Impact:</h4>
        <ul className="space-y-1">
          <li>🌳 Trees Planted: {pkg.impact.treesPlanted}</li>
          <li>🌍 CO₂ Offset: {pkg.impact.combinedEmissionsCountered.toFixed(1)} kg</li>
        </ul>
      </div>
      <Accordion type="single" collapsible>
        <AccordionItem value="calculation">
          <AccordionTrigger>How this is calculated</AccordionTrigger>
          <AccordionContent>
            <div className="space-y-2">
              {Object.entries(pkg.calculation).map(([key, value]) => (
                <div key={key} className="flex justify-between">
                  <span>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span>
                  <span>{typeof value === 'number' ? value.toFixed(2) : value}</span>
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

const CartComponent = ({ cart, packages, removeFromCart, addToCart, getTotalPrice, getTotalImpact }) => {
  const totalImpact = getTotalImpact();
  const navigate = useNavigate()
  return (
    <div className="flex flex-col h-full">
      <SheetHeader>
        <SheetTitle>Your Cart</SheetTitle>
        <SheetDescription>
          Review your selected offset packages
        </SheetDescription>
      </SheetHeader>
      <ScrollArea className="flex-grow">
        {Object.values(cart).map((item) => (
          <div key={item.id} className="flex justify-between items-center py-4 border-b">
            <div>
              <h3 className="font-medium">{item.title}</h3>
              <p className="text-sm text-gray-500">AED {item.price.toFixed(2)}</p>
            </div>
            <div className="flex items-center">
              <Button variant="outline" size="icon" onClick={() => removeFromCart(item.id)}>
                <Minus className="h-4 w-4" />
              </Button>
              <span className="mx-2">{item.quantity}</span>
              <Button variant="outline" size="icon" onClick={() => addToCart(item)}>
                <Plus className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </ScrollArea>
      <div className="mt-auto pt-4 border-t">
        <div className="flex justify-between mb-2">
          <span>Total:</span>
          <span className="font-bold">AED {getTotalPrice().toFixed(2)}</span>
        </div>
        <div className="text-sm text-gray-600 mb-4">
          <p>Total trees planted: {totalImpact.treesPlanted}</p>
          <p>Total carbon offset: {totalImpact.co2Offset.toFixed(1)} kg CO₂</p>
        </div>
        <Button className="w-full" onClick={()=>navigate('/checkout-package')} >Proceed to Checkout</Button>
      </div>
    </div>
  );
};

export default ImpactShop;