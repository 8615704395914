import { userLoggedOut } from "../features/auth/authSlice";
import { getAllPackages,getAllOrders,getAllOrdersByUser } from "../features/package/packageSlice";
import { getAllPlans,getSubscribedPlans } from "../features/plan/planSlice";
import { getProfile,getAllContacts } from "../features/profile/profileSlice";
 

const clearOnLogout = (store) => (next) => (action) => {
  if (action.type === userLoggedOut.type) {
    store.dispatch(getAllPlans([]));
    store.dispatch(getSubscribedPlans([]));
    store.dispatch(getAllPackages([]));
    store.dispatch(getAllOrders([]));
    store.dispatch(getAllOrdersByUser([]));
    store.dispatch(getProfile([]));
    store.dispatch(getAllContacts([]));
  }
  return next(action);
};

export default clearOnLogout;
