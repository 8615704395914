import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { mailApi } from "../mail/mailApi";
import { getProfile } from "../profile/profileSlice";
import { userLoggedIn } from "./authSlice";

 export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      queryFn: async ({ name, email, password }, { dispatch }) => {
        try {
          const { data, error } = await supabase.auth.signUp({
            email,
            password,
            options: {
              emailRedirectTo: `${window.location.origin}/auth-page`,
              data: { full_name: name },
            },
          });
           

          if (error) {
            return { error };
          }
          // Send email with login credentials
          await dispatch(mailApi.endpoints.sendEmail.initiate({
            to: email,
            subject: 'Welcome to Bayti Eco Platform',
            text: `Your account has been created. Your email is: ${email}. You can now log in with the password you provided during sign-up. Please verify your email to activate your account.`,
            html: `
              <p>Your account has been created. Your email is: <strong>${email}</strong>.</p>
              <p>You can now log in with the password you provided during sign-up.</p>
              <p>Please verify your email to activate your account.</p>
            `
          }));

          return { data };
        } catch (err) {
          return { error: err.message || 'An error occurred' };
        }
      },
    }),
    signIn: builder.mutation({
      queryFn: async ({ email, password }, { dispatch }) => {
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password
          });

          if (error) {
            return { error };
          }

          sessionStorage.setItem(
            'auth',
            JSON.stringify({
              accessToken: data.session.access_token,
              user: data.user,
            })
          );
          dispatch(
            userLoggedIn({
              accessToken: data.session.access_token,
              user: data.user,
            })
          );

          // Fetch and dispatch profile
          const profileResponse = await supabase
            .from('profiles')
            .select('*')
            .eq('user_id', data.user.id)
            .single();

          if (profileResponse.error) {
            return { error: profileResponse.error };
          }

          dispatch(getProfile(profileResponse.data));
           
          return { data };
        } catch (err) {
          return { error: err.message || 'An error occurred' };
        }
      }
    }),
    
    forgetPassword: builder.mutation({
      queryFn: async ({ email }, { dispatch }) => {
        try {
          const { data, error } = await supabase.auth
          .resetPasswordForEmail(email)

          if (error) {
            return { error };
          }
          return { data };
        } catch (err) {
          return { error: err.message || 'An error occurred' };
        }
      }
    }),
    signInWithGoogle: builder.mutation({
      queryFn: async ({ dispatch }) => {
        try {
          const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
              redirectTo: `${window.location.origin}/auth/callback`,
              queryParams: {
                access_type: 'offline',
                prompt: 'consent',
              },
            },
          });

          if (error) {
            throw new Error('Error during Google sign-in: ' + error.message);
          }

          // Fetch and dispatch profile
          const profileResponse = await supabase
            .from('profiles')
            .select('*')
            .eq('user_id', data.user.id)
            .single();

          if (profileResponse.error) {
            return { error: profileResponse.error };
          }

          dispatch(getProfile(profileResponse.data));

          return { data };
        } catch (error) {
          throw new Error('Error during Google sign-in: ' + error.message);
        }
      }
    }),
  })
});

export const {
   useSignInMutation, 
   useSignUpMutation, 
   useSignInWithGoogleMutation,
   useForgetPasswordMutation } 
   = authApi;
