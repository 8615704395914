import { apiSlice } from "../../apiSlice";
import { supabase } from "../../supabase";
import { mailApi } from "../mail/mailApi";
import { getProfile, getPublicBusinessProfile, getMemberBusinessProfile, getAllContacts, getAllProfiles } from "./profileSlice";
import axios from 'axios';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
        queryFn: async ({ user_id }) => {
            try {
                let { data, error } = await supabase
                    .from('profiles')
                    .select(`*`)
                    .eq('user_id', user_id);
    
                if (error) {
                    return { error };
                }
    
                return { data };
            } catch (err) {
                return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
            }
        },
        onQueryStarted: async ({ user_id }, { dispatch, queryFulfilled }) => {
            try {
                const { data } = await queryFulfilled;
                if (data && data.length > 0) {
                    dispatch(getProfile(data[0]));
                }
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        }
    }),
    getBusinessPublic: builder.query({
      queryFn: async ({ business_name }) => {
          try {
              const { data, error } = await supabase
                  .from('profiles')
                  .select("*")
                  .eq('business_name', business_name);
  
              if (error) {
                  return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
              }
              return { data };
          } catch (err) {
              return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
          }
      },
      onQueryStarted:async ({ business_name }, { dispatch, queryFulfilled }) =>  {
          try {
              const { data } = await queryFulfilled;
              if (data && data.length > 0) {
                dispatch(getPublicBusinessProfile(data[0]));
              }
          } catch (error) {
              console.error('Failed to fetch profile:', error);
          }
      }
  }),
    getProfilePublic: builder.query({
        queryFn: async ({ webpage_link }) => {
            try {
                const { data, error } = await supabase
                    .from('profiles')
                    .select("*")
                    .eq('webpage_link', webpage_link);
    
                if (error) {
                    return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
                }
                return { data };
            } catch (err) {
                return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
            }
        },
        onQueryStarted:async ({ webpage_link }, { dispatch, queryFulfilled }) =>  {
            try {
                const { data } = await queryFulfilled;
                if (data && data.length > 0) {
                  dispatch(getProfile(data[0]));
                }
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        }
    }),
    createProfile: builder.mutation({
      queryFn: async (formData, { dispatch }) => {
        try {
          const { data, error } = await supabase
          .from('profiles')
          .insert(formData)
          .select();
          dispatch(getProfile({
            profile:data,
           }))
          return { data };

        } catch (err) {
          return { error: err.message || 'An error occurred' };
        }
        

      }
    }),
    updateProfile: builder.mutation({
      queryFn: async (formData) => {
        try {
          const profileData = {};
          formData.forEach((value, key) => {
            profileData[key] = value;
          });
          if (formData.has('image')) {
            const imageFile = formData.get('image');
            const imageFileName = `${Math.random()}-${imageFile.name}`.replace(/[\s/]/g, '');
            const imageFilePath = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile/${imageFileName}`;
            const { data: imageUploadData, error: imageUploadError } = await supabase
              .storage
              .from('profile')
              .upload(imageFileName, imageFile);

            if (imageUploadError) {
              return { error: imageUploadError };
            }
            profileData.image = imageFilePath;
          }
           // Add this block for cover image
        if (formData.has('cover')) {
          const coverFile = formData.get('cover');
          const coverFileName = `${Math.random()}-${coverFile.name}`.replace(/[\s/]/g, '');
          const coverFilePath = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile/${coverFileName}`;
          const { data: coverUploadData, error: coverUploadError } = await supabase
            .storage
            .from('profile')
            .upload(coverFileName, coverFile);

          if (coverUploadError) {
            return { error: coverUploadError };
          }
          profileData.cover = coverFilePath;
        }

          if (formData.has('business_logo')) {
            const logoFile = formData.get('business_logo');
            const logoFileName = `${Math.random()}-${logoFile.name}`.replace(/[\s/]/g, '');
            const logoFilePath = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile/${logoFileName}`;
            const { data: logoUploadData, error: logoUploadError } = await supabase
              .storage
              .from('profile')
              .upload(logoFileName, logoFile);

            if (logoUploadError) {
              return { error: logoUploadError };
            }
            profileData.business_logo = logoFilePath;
          }

          // update database 
          const { data, error } = await supabase
            .from('profiles')
            .update(profileData)
            .eq('user_id', profileData.user_id)
            .select();

          if (error) {
            return { error };
          }
          return { data };

        } catch (err) {
          return { error: err.message || 'An error occurred' };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
            const { data } = await queryFulfilled;
            if (data && data.length > 0) {
              console.log("dispatch calling");
              console.log(data)
                dispatch(getProfile(data[0]));
            }
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
      }
    }),
    createContact: builder.mutation({
      queryFn: async (formData) => {
        try {
          const { data, error } = await supabase
            .from('contacts')
            .insert(formData)
            .select("*");
    
          if (error) {
            return { error: { status: 'CREATE_ERROR', message: error.message || 'An error occurred' } };
          }
    
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled; 
          dispatch(profileApi.endpoints.getAllContacts.initiate());
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    getAllContacts: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('contacts')
            .select("*");

          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAllContacts(data));
        } catch (error) {
          console.error('Failed to fetch contacts:', error);
        }
      }
    }),
    deleteContact: builder.mutation({
      queryFn: async (id) => {
        try {
          const { data, error } = await supabase
            .from('contacts')
            .delete()
            .eq('id', id)
            .select("*");
    
          if (error) {
            return { error: { status: 'DELETE_ERROR', message: error.message || 'An error occurred' } };
          }
    
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(profileApi.endpoints.getAllContacts.initiate());
        } catch (err) {
          console.error('Error handling query result:', err);
        }
      },
    }),
    getAllProfiles: builder.query({
      async queryFn() {
        try {
          const { data, error } = await supabase
            .from('profiles')
            .select("*");
    
          if (error) {
            return { error: { status: 'CUSTOM_ERROR', message: error.message || 'An error occurred' } };
          }
          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAllProfiles(data));
        } catch (error) {
          console.error('Failed to fetch profiles:', error);
        }
      }
    }),
    createMember: builder.mutation({
      async queryFn({ email, role, permissions }, { getState,dispatch }) {
        try {
          const state = getState();
          const currentUser = state.auth.user;
          const profile = state.profile.profile;

          if (!profile || profile.profile_type !== 'Business') {
            throw new Error('Only business profiles can add members.');
          }

          const businessId = profile.id;

          // Check if the user trying to add a member is the business owner
          if (profile.user_id !== currentUser.id) {
            throw new Error('Only the business owner can add members.');
          }

          // Check if the user already exists
          const { data: existingProfile, error: profileError } = await supabase
            .from('profiles')
            .select('user_id')
            .eq('email', email)
            .maybeSingle();

          if (profileError) throw profileError;

          let userId;
          let password;
          if (!existingProfile) {
            // If user doesn't exist, create a new user
            password = Math.random().toString(36).slice(-8);
            const { data: newUser, error: signUpError } = await supabase.auth.signUp({
              email,
              password: password, // Generate a random password
            });
            console.log(password);
            if (signUpError) throw signUpError;
            userId = newUser.user.id;
            await dispatch(mailApi.endpoints.sendEmail.initiate({
              to: email,
              subject: 'Welcome to Bayti Eco Platform',
              text: `Your account has been created. Your temporary password is: ${password}. Please change it after your first login.`,
              html: `<p>Your account has been created. Your temporary password is: <strong>${password}</strong>. Please change it after your first login.</p>`
            }));
          } else {
            userId = existingProfile.user_id;
          }

          // Check if a member record already exists
          const { data: existingMember, error: memberCheckError } = await supabase
            .from('members')
            .select('id')
            .eq('user_id', userId)
            .eq('business_id', businessId)
            .maybeSingle();

          if (memberCheckError) throw memberCheckError;

          if (existingMember) {
            throw new Error('This user is already a member of this business.');
          }

          // Create the member record
          const { data: newMember, error: insertError } = await supabase
            .from('members')
            .insert({
              user_id: userId,
              business_id: businessId,
              email,
              role,
              permissions,
            })
            .select()
            .single();

          if (insertError) throw insertError;
          await dispatch(mailApi.endpoints.sendEmail.initiate({
            to: email,
            subject: 'You\'ve been added to a business',
            text: `You have been added as a member to a business on our Bayti Eco platform. Please log in to view your new role and permissions.`,
            html: `<p>You have been added as a member to a business on our Bayti Eco platform. Please log in to view your new role and permissions.</p>`
          }));
          return { data: newMember };
        } catch (err) {
          return { error: { status: 'CUSTOM_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),

    getBusinessMembers: builder.query({
      async queryFn(businessId) {
        try {
          const { data, error } = await supabase
            .from('members')
            .select('*')
            .eq('business_id', businessId);

          if (error) throw error;

          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),

    getMemberBusinessProfile: builder.query({
      async queryFn(userId) {
        try {
          const { data, error } = await supabase
            .from('members')
            .select(`
              *,
              business:profiles!business_id (*)
            `)
            .eq('user_id', userId)
            .single();

          if (error) throw error;

          return { data };
        } catch (err) {
          return { error: { status: 'FETCH_ERROR', message: err.message || 'An error occurred' } };
        }
      },
      async onQueryStarted(userId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getMemberBusinessProfile(data));
        } catch (error) {
          console.error('Failed to fetch member business profile:', error);
        }
      },
    }),

    updateMemberPermissions: builder.mutation({
      async queryFn({ memberId, permissions }) {
        try {
          const { data, error } = await supabase
            .from('members')
            .update({ permissions })
            .eq('id', memberId)
            .select();

          if (error) throw error;

          return { data };
        } catch (err) {
          return { error: { status: 'CUSTOM_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),

    deleteMember: builder.mutation({
      async queryFn(memberId) {
        try {
          const { data, error } = await supabase
            .from('members')
            .delete()
            .eq('id', memberId);

          if (error) throw error;

          return { data };
        } catch (err) {
          return { error: { status: 'CUSTOM_ERROR', message: err.message || 'An error occurred' } };
        }
      },
    }),

    generateCertificate: builder.mutation({
      async queryFn({ companyName, tonnesCO2, trees, plasticBottles, currentDate, certificateId }) {
        const apiKey = 'cefeMjA4OTA6MTgwMDM6Zlprc3p4cGtLZWQ2aWlIWA=';
        const templateId = '06b77b23b6e8c82a';

        try {
          const response = await axios.post(`https://api.apitemplate.io/v1/create?template_id=${templateId}`, {
            companyName,
            tonnesCO2,
            trees,
            plasticBottles,
            currentDate,
            certificateId
          }, {
            headers: {
              'Content-Type': 'application/json',
              'X-API-KEY': apiKey
            }
          });

          // Check if the response is successful
          if (response.data.status === 'success') {
            return { data: response.data.download_url }; // Return the download URL for the PDF
          } else {
            return { error: { status: 'ERROR', message: response.data.message || 'Failed to generate certificate.' } };
          }
        } catch (error) {
          return { error: { status: 'FETCH_ERROR', message: error.message || 'An error occurred while generating the certificate.' } };
        }
      }
    }),
  })
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useCreateProfileMutation,
  useGetProfilePublicQuery,
  useCreateContactMutation,
  useGetAllContactsQuery,
  useDeleteContactMutation,
  useLazyGetProfileQuery,
  useGetBusinessPublicQuery,
  useCreateMemberMutation,
  useGetBusinessMembersQuery,
  useGetMemberBusinessProfileQuery,
  useUpdateMemberPermissionsMutation,
  useDeleteMemberMutation,
  useGenerateCertificateMutation
} 
   = profileApi;
