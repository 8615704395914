import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Users, User } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useGetPlansByProfileQuery } from '../app/features/plan/planApi';
import BaytiPlan from './BaytiPlan';
import Loading from './Loading';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";

const TheBayti = () => {
  const profile = useSelector((state) => state.profile.profile);
  const profile_id = profile?.id;
  const userHasPlan = profile?.hasplan;

  const { 
    data: profileData, 
    error: getProfileError, 
    isLoading: isProfileLoading, 
    refetch 
  } = useGetPlansByProfileQuery({ profile_id }, {
    skip: !profile_id,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (profile_id) {
      refetch();
    }
  }, [profile_id, refetch]);

  if (isProfileLoading || !profile) {
    return <Loading />;
  }

  if (getProfileError) {
    return <div>Error: {getProfileError.message}</div>;
  }

  if (userHasPlan && profileData && profileData.length > 0) {
    return <BaytiPlan initialPlanDetails={profileData[0]} userHasPlan={userHasPlan} />;
  }



  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <h1 className="text-4xl font-bold text-gray-900 mb-2">The Bayti Subscription Plans</h1>
      <p className="text-xl text-gray-600 mb-6">View and manage your Bayti plans.</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="col-span-1 md:col-span-2"
        >
          <Card>
            <CardHeader>
              <CardTitle>Carbon Positive Living Plans</CardTitle>
              <CardDescription>
                Reduce your carbon footprint by planting certified trees, removing
                ocean-bound plastic and funding global humanitarian projects to reduce
                your negative climate impact.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <img
                src="/images/EcoFamilyLiving.jpg"
                alt="Carbon Positive Living"
                className="w-full max-w-md mx-auto rounded-lg shadow-md mb-6"
              />
            </CardContent>
            <CardFooter className="flex justify-center space-x-4">
              <Button asChild>
                <Link to="/bayti-teams">
                  <Users className="mr-2 h-4 w-4" /> For Teams
                </Link>
              </Button>
              <Button asChild variant="outline">
                <Link to="/my-bayti">
                  <User className="mr-2 h-4 w-4" /> For Individuals
                </Link>
              </Button>
            </CardFooter>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default TheBayti;
